// import faker from '@faker-js/faker'

import { faker } from '@faker-js/faker';
import FetchSales from '../../services/FetchSales';


export async function fetchDataSales(options) {
    
    
    // Simulate some network latency
    
    var data =  await  new Promise( async (resolve , reject) =>{
        
        const resp = await FetchSales()
        
        const JSON_DATA = await resp.Orders
        // const resp = await fetch(``)
        // const JSON_data = await resp.json()
        // const pagination = JSON_data.pagination
        // const CryptoData = JSON_data.data
        const reversed = JSON_DATA.reverse();

        // let CoinsArray = []

        // CryptoData.forEach((element , i) => {
            
   
        //     CoinsArray.push(NewCoin(element , i))
           
        // });
        

        resolve(reversed)
        // const len = 110


        // resolve (range(len).map((d) => {
        //     
        //   return {
        //     ...newPerson(d),
        //   }
        // }))
    })


    return {
      rows: data,
      pageCount: 1,
    }
  }