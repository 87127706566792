// import faker from '@faker-js/faker'

import { faker } from '@faker-js/faker';
import FetchBids from '../../services/FetchBids';


export async function fetchData(options) {

  // Simulate some network latency

  var data = await new Promise(async (resolve, reject) => {

    const resp = await FetchBids(options.SearchQuery)
    const JSON_DATA = await resp.Bids


    resolve(JSON_DATA)

  })



  return {
    rows: data,
    pageCount: 1,
  }
}