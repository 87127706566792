import axios from "axios";



export const API_URL = "http://localhost:8080/api/";

export const UpdatePaymentMethod = async (paymentMethod) => {
    return new Promise((resolve, reject) => {

        
        const email = paymentMethod.billing_details.email
        const FullName = paymentMethod.billing_details.name
        const PaymentMethodID = paymentMethod.id

        fetch(`${API_URL}/account/payment`, {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ email, FullName, PaymentMethodID })
        }).then(resp => resp.json()).then((response) => {
            // 
            resolve(response)
        })
    })



};

export const UpdateAccount = async (values) => {
    

};

export const UpdateAddress = async (values) => {
    return new Promise((resolve, reject) => {
        
        // for(var Key in values){
        //     // 
        //     values[Key] = values[Key].value
        // }
        // 
        // var EntryArray = Object.entries(values)
        // EntryArray.map((item , i) =>{
        //     
        //     EntryArray[i]
        // })

        fetch(`${API_URL}/account/address`, {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(values)
        }).then(resp => resp.json()).then((response) => {
            // 
            resolve(response)
        })
    })


};

export const RetrievePaymentMethod = async () =>{
    return new Promise((resolve , reject) =>{
        fetch(`${API_URL}/account/payment` , {
            credentials:"include",
        }).then(resp => resp.json()).then((Account) =>{
            // 
            resolve(Account)
        })
    })
}

export const RetrieveAccountData =  async () =>{
    return new Promise((resolve , reject) =>{
        fetch(`${API_URL}/account` , {
            credentials:"include",
        }).then(resp => resp.json()).then((Account) =>{
            // 
            resolve(Account)
        })
    })
}