
import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { RiArrowRightUpLine, RiArrowLeftDownLine } from 'react-icons/ri'
import { BsArrowDownLeft, BsTrash } from 'react-icons/bs'
// import './index.css'
import ReactLoading from 'react-loading';

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    Row,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'

import { useVirtual } from '@tanstack/react-virtual';

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { fetchDataSales } from './makedataSales'
import DeleteListingByID from '../../services/DeleteListingByID'
import { Link } from 'react-router-dom'
function SalesComponent() {




    const [CurrentSelectedID, SetCurrentSelectedID] = useState(null)

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {

            // 
            return num >= item.value;
        });

        // let PRICE = new Intl.NumberFormat('en-US', {style: 'decimal'}).format(String(parseFloat(num / item.value)).replace(rx, "$1"));
        // 
        if (item) {
            var value = parseFloat(num).toLocaleString(
                undefined,

                { minimumFractionDigits: 2 }
            );

        }



        return item ? value : String(parseFloat(num).toFixed(2));
    }

    function BigNFormatter(num, digits, Currency) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });



        return item ? `${Currency ? "€" : ''}${(num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol}` : "0";
    }

    const [sorting, setSorting] = React.useState([])

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'Cart',
                // header: 'Name',
                // cell: info =>{
                //     
                //     return `€${info.getValue()}`
                // },
                cell: ({ row }) => {

                    //  


                    return (<div className='flex items-center space-x-3'>
                        <img className=' h-[48px]' src={`http://localhost:7584/image/product/${row.original.ItemSold?.ProductSKU}`} />
                        <div className='flex flex-col'>
                            <Link to={`/product/${row.original.ItemSold?.ProductSKU}`} className='hover:underline'>
                                {row.original.ItemSold?.ProductName}
                            </Link>
                            <div className='flex gap-3 mt-2'>
                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                    EU  {row.original.ItemSold?.ProductSize}
                                </span>
                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                    {row.original.ItemSold?.ProductSKU}
                                </span>
                            </div>
                        </div>

                    </div>)
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Item</div>),
                size: 500

            },
            {
                accessorKey: 'Price',
                cell: ({ row }) => {
                    return (
                        <div className='flex flex-col'>
                            <span className={`text-sm`}>
                                {`€${nFormatter(row.original.PayoutDetails?.Payout, 3)}`}
                            </span>
                            {/* <span className=' text-xs mt-2'>
                            Je bent niet de goedkoopste
                        </span> */}
                        </div>
                    )
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Prijs</div>),
                size: 100

            },

            {
                accessorKey: 'status',
                cell: info => {
                    return (
                        <div className=''>
                            <span className='text-sm'>
                                {info.getValue()}
                            </span>
                        </div>
                    )
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Status</div>),
                size: 200
            },

            {
                header: () => (
                    <div
                        style={{

                            textAlign: "left"
                        }}
                    >Status</div>),
                size: 250,
                enableSorting: false,
                accessorKey: 'createdAt',
                header: '',
                cell: ({ row }) => {

                    let Enabled;

                    let LabelBase64String;

                    if (row.original.ShippingDetails) {
                        Enabled = true
                        LabelBase64String = row.original.ShippingDetails.Label
                    }



                    return (
                        <div className=' flex gap-3 justify-end mr-5 '>
                            <button onClick={() => {
                                const linkSource = `data:application/pdf;base64,${LabelBase64String}`;
                                const downloadLink = document.createElement("a");
                                const fileName = "Label.pdf";
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                            }} disabled={!Enabled} className={`${Enabled ? null : " opacity-40 !cursor-not-allowed"} px-3 py-2 border rounded text-sm cursor-pointer`}>
                                Download Verzendlabel
                            </button>
                            {/* <button onClick={() => {

                                SetCurrentSelectedID(row.original)
                            }} className='px-3 py-2 border rounded text-sm cursor-pointer'>
                                <BsTrash />
                            </button> */}

                        </div>
                    )
                },

            },
        ],
        []
    )

    // const [data, setData] = React.useState(async () => {
    //     await makeData(50_000).then(s => {

    //         setData(s)
    //     })
    // })

    const [{ pageIndex, pageSize }, setPagination] =
        useState({
            pageIndex: 0,
            pageSize: 30,
        })

    const fetchDataOptions = {
        pageIndex,
        pageSize,
    }




    const dataQuery = useQuery(
        ['data', fetchDataOptions],
        () => fetchDataSales(fetchDataOptions),
        { keepPreviousData: true }
    )



    const defaultData = useMemo(() => [], [])

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        // data,
        columns,
        pageCount: dataQuery.data?.pageCount ?? -1,
        data: dataQuery.data?.rows ?? defaultData,

        state: {
            sorting,
            pagination
        },
        onPaginationChange: setPagination,
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    })

    const tableContainerRef_ = React.useRef()

    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef_,
        size: rows.length,
        overscan: 10,
    })
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0

    const [SubCategory, SetSubCategory] = useState('pending_sales')



    const HandleSubCategoryChange = (e) => {
        SetSubCategory(e.target.id)
        // alert(e.target.id)
    }

    useEffect(() => {
        
    }, [SubCategory])


    const [OrdersOpenCount, SetOrdersOpenCount] = useState(0)
    const [OrderHistoryCount, SetOrderHistoryCount] = useState(0)
    const [IsLoading, SetIsLoading] = useState(true)

    useEffect(() => {
        if (dataQuery.data?.rows) {
            SetOrdersOpenCount(dataQuery.data?.rows.length)
            setTimeout(() => {
                SetIsLoading(false)
            }, 200);
        }
    }, [dataQuery])

    return (
        <div className="flex flex-col p-2 h-full">
            {
                IsLoading ?
                    <div className='flex  h-full'>
                        <ReactLoading type="spin" color='#0052ff' className=" mx-auto flex my-auto z-[1000000]" height={55} width={55} />


                    </div> : <>
                        <div className="border-b pb-2 mb-5 flex gap-10 ">
                            <div onClick={(e) => {
                                HandleSubCategoryChange(e)
                            }} id='pending_sales' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'pending_sales' ? 'opacity-60' : null}`}>


                                <span className=" pointer-events-none" >
                                    Openstaande Aankopen
                                </span>
                                <div className="rounded-md border px-3 flex py-1 ">
                                    <span className="text-black text-xs font-semibold pointer-events-none">
                                        {OrdersOpenCount}
                                    </span>
                                </div>
                            </div>
                            <div onClick={(e) => {
                                HandleSubCategoryChange(e)
                            }} id='history_sales' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'history_sales' ? 'opacity-60' : null} `}>


                                <span className=" pointer-events-none " >
                                    Historie
                                </span>
                                <div className="rounded-md border px-3 flex py-1 ">
                                    <span className="text-black text-xs font-semibold pointer-events-none">
                                        {OrderHistoryCount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <input className='p-2 text-sm rounded py-3 mb-5 border' placeholder='Zoeken in Verkopen' />

                        <div className="h-2" />
                        <div ref={tableContainerRef_} className="!h-full">
                            <table className='xl:table md:table lg:table hidden w-full'>
                                <thead className=''>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => {
                                                return (
                                                    <th
                                                        key={header.id}
                                                        colSpan={header.colSpan}
                                                        style={{ width: header.getSize() }}
                                                        className="text-xs pb-2"
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div

                                                                {...{
                                                                    className: header.column.getCanSort()
                                                                        ? 'cursor-pointer select-none flex space-x-3'
                                                                        : '',
                                                                    onClick: header.column.getToggleSortingHandler(),
                                                                }}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: <span>
                                                                        🔼
                                                                    </span>,
                                                                    desc: <span>
                                                                        🔽
                                                                    </span>,
                                                                }[header.column.getIsSorted()] ?? null}
                                                            </div>
                                                        )}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {paddingTop > 0 && (
                                        <tr>
                                            <td style={{ height: `${paddingTop}px` }} />
                                        </tr>
                                    )}
                                    {virtualRows.map(virtualRow => {
                                        const row = rows[virtualRow.index]
                                        return (
                                            <tr className='hover:bg-[#fafafa]   border-t border-b !min-h-[200px] h-[100px] cursor-pointer' onClick={() => {

                                            }} key={row.id}>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <td className='' key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                    {paddingBottom > 0 && (
                                        <tr>
                                            <td style={{ height: `${paddingBottom}px` }} />
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className=' flex-col w-full xl:hidden md:hidden lg:hidden flex gap-3 mt-1'>
                                {dataQuery.data?.rows.map((data, i) => {
                                    return (
                                        <div className='flex row border p-4 cursor-pointer'>
                                            <div className='flex items-center space-x-3'>
                                                <img className=' h-[48px]' src={`http://localhost:7584/image/product/${data.ItemSold?.ProductSKU}`} />
                                                <div className='flex flex-col'>
                                                    <Link to={`/product/${data.ItemSold?.ProductSKU}`} className='hover:underline'>
                                                        {data.ItemSold?.ProductName}
                                                    </Link>
                                                    <div className='flex gap-3 mt-2'>
                                                        <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                                            EU  {data.ItemSold?.ProductSize}
                                                        </span>
                                                        <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                                            {data.ItemSold?.ProductSKU}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <div className="h-2" />



                        </div>
                    </>}

            {/* <ListingsTable/> */}
        </div>
    )



}



export default SalesComponent