import axios from "axios"
import { useEffect, useState, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa"

import FetchSneakers from "../services/FetchSneakers"
import FetchSneakersByBrand from "../services/FetchSneakersByBrand";
import Footer from "../components/Footer";




function Products() {

    const inputEl = useRef(null);


    const navigate = useNavigate()

    const brands = [
        {
            name: 'Yeezy',
            logo: 'yeezy',

        },
        
        {
            name: 'Adidas',
            logo: 'adidas',

        },
        {
            name: 'air jordan',
            logo: 'jordan',

        },
        {
            name: 'Nike',
            logo: 'nike',

        },
        {
            name: 'Nike SB',
            logo: 'nikesb',

        },
        {
            name: 'New Balance',
            logo: 'newbalance',

        },


    ]


    const Brand = useParams().Brand.split('-').join(' ')

    // const ProductRow = ({ RowTitle }) => {


    //     
    //     const ProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl }) => {
    //         return (
    //             <div className="border flex flex-col p-3 min-w-[200px] max-w-[200px] flex-1 cursor-pointer ">

    //                 {/* Image Section */}
    //                 <div id="" className="w-[140px] h-[75px] m-auto">
    //                     <img className=" object-contain " src={ProductImage} />
    //                 </div>

    //                 {/* Text Section */}

    //                 <div className="text-left flex flex-col w-full mt-8 ">
    //                     <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
    //                         {ProductName}
    //                     </span>


    //                 </div>
    //                 <div className="mt-3">
    //                     <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
    //                         Lowest Price
    //                     </span>
    //                     <span>
    //                         {LowestPrice}
    //                     </span>
    //                 </div>



    //                 {/* <div className="w-[140px] h-[75px]">
    //                         <img  src={ProductImage}  />
    //                     </div>
    //                    <span className="text-sm">
    //                     {ProductName}
    //                    </span> */}
    //             </div>
    //         )
    //     }
    //     const Data = [
    //         {
    //             "id": "14782",
    //             "name": "Air Jordan 1 Retro High OG Yellow Toe",
    //             "brand_id": 2,
    //             "sku": "555088-711",
    //             "slug": "https://restocks.net/nl/p/air-jordan-1-retro-high-og-yellow-toe",
    //             "image": "https://media.restocks.net/products/555088-711/air-jordan-1-retro-high-og-yellow-toe-1-400.png",
    //             "storeprice": "\u20ac 976",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //         {
    //             "id": "14781",
    //             "name": "Air Jordan 1 Retro High OG Stealth",
    //             "brand_id": 2,
    //             "sku": "555088-037",
    //             "slug": "https://restocks.net/nl/p/air-jordan-1-retro-high-og-stealth",
    //             "image": "https://media.restocks.net/products/555088-037/air-jordan-1-retro-high-og-stealth-1-400.png",
    //             "storeprice": "\u20ac 781",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //         {
    //             "id": "14780",
    //             "name": "Air Jordan 1 Retro Low OG SP Travis Scott Reverse Mocha (PS)",
    //             "brand_id": 2,
    //             "sku": "DO5441-162",
    //             "slug": "https://restocks.net/nl/p/air-jordan-1-retro-low-og-sp-travis-scott-reverse-mocha-ps",
    //             "image": "https://media.restocks.net/products/DO5441-162/air-jordan-1-retro-low-og-sp-travis-scott-reverse-mocha-ps-1-400.png",
    //             "storeprice": "\u20ac 214",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //         {
    //             "id": "14779",
    //             "name": "Air Jordan 1 Retro Low OG SP Travis Scott Reverse Mocha (TD)",
    //             "brand_id": 2,
    //             "sku": "DO5442-162",
    //             "slug": "https://restocks.net/nl/p/air-jordan-1-retro-low-og-sp-travis-scott-reverse-mocha-td",
    //             "image": "https://media.restocks.net/products/DO5442-162/air-jordan-1-retro-low-og-sp-travis-scott-reverse-mocha-td-1-400.png",
    //             "storeprice": "\u20ac 190",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //         {
    //             "id": "14778",
    //             "name": "Nike Air Force 1 Mid Off-White Pine Green",
    //             "brand_id": 3,
    //             "sku": "DR0500-300",
    //             "slug": "https://restocks.net/nl/p/nike-air-force-1-mid-off-white-pine-green",
    //             "image": "https://media.restocks.net/products/DR0500-300/nike-air-force-1-mid-off-white-pine-green-1-400.png",
    //             "storeprice": "\u20ac 790",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //         {
    //             "id": "14777",
    //             "name": "Air Jordan 4 Retro Canyon Purple (W)",
    //             "brand_id": 2,
    //             "sku": "AQ9129-500",
    //             "slug": "https://restocks.net/nl/p/air-jordan-4-retro-canyon-purple-w",
    //             "image": "https://media.restocks.net/products/AQ9129-500/air-jordan-4-retro-canyon-purple-w-1-400.png",
    //             "storeprice": "\u20ac 803",
    //             "oldstoreprice": "",
    //             "sale_class": "",
    //             "deliveryText": ""
    //         },
    //     ]




    //     return (
    //         <div className="block space-y-4  mb-5">

    //             <div className="flex justify-between items-center">
    //                 <span className="font-medium text-lg">
    //                     {RowTitle}
    //                 </span>
    //                 <span className="font-medium text-sm  flex items-center gap-2 text-[#0052ff]">
    //                     Bekijk Alles
    //                     <FaArrowRight />
    //                 </span>


    //             </div>

    //             <div className=" flex gap-8 overflow-auto justify-between">
    //                 {Data.map((Sneaker, index) => <ProductItem ProductName={Sneaker.name} ProductImage={Sneaker.image} ProductUrl={Sneaker.slug} LowestPrice={Sneaker.storeprice} />)}
    //             </div>

    //         </div>
    //     )
    // }


    const [SneakersFound, SetSneakersFound] = useState([])
    const [TotalFound, SetTotalFound] = useState(0)
    const [Page, SetPage] = useState(1)
    useEffect(() => {

        // window.addEventListener('scroll', (e)=>{
        //     
        // });

        const Found = brands.some((item) => {

            return Brand == item.name.toLocaleLowerCase() || Brand == 'all'
        })

        

        if (!Found) {
            navigate('/page-not-found')
        }

        if(Brand == 'all'){
            FetchSneakersByBrand('').then(response => {
                
                SetSneakersFound(response.sneakers)
                SetTotalFound(response.total)
            })
        }
        else{
            FetchSneakersByBrand(Brand).then(response => {
                
                SetSneakersFound(response.sneakers)
                SetTotalFound(response.total)
            })
        }


    }, [Brand])


    useEffect(() => {

        if(Brand == 'all'){
            FetchSneakersByBrand('', Page).then(response => {
                
                // SetSneakersFound()
                SetSneakersFound(old => [...old, ...response.sneakers]);
    
                SetTotalFound(response.total)
            })
        }
        else{
            FetchSneakersByBrand(Brand, Page).then(response => {
                
                // SetSneakersFound()
                SetSneakersFound(old => [...old, ...response.sneakers]);
    
                SetTotalFound(response.total)
            })
        }

  
    }, [Page])

    const ProductViewer = () => {

        const [RecentlyViewed, SetRecentlyViewed] = useState([])

        // useEffect(() =>{
        //     const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewedProducts')) || [];
        //     
        //     SetRecentlyViewed(recentlyViewedProducts.reverse())
        // } , [])




        const ProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
            return (
                <Link to ={`/product/${Productsku}`} className="border rounded shadow-md hover:shadow-lg flex flex-col p-3 min-w-[200px] max-w-[200px] flex-1 cursor-pointer ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={ProductImage} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 ">
                        <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                            {ProductName}
                        </span>


                    </div>
                    <div className="mt-3">
                    <div className={`${LowestPrice != "OOS" ? null : 'line-through' } flex flex-col`}>
                          
                            <span className="text-sm">
                                {LowestPrice != "OOS" ? <>
                                
                                € {LowestPrice}
                                </>  : "Uitverkocht"}
                               
                            </span>
                        </div>
                    </div>



                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </Link>
            )
        }




        return (
            <div className="block space-y-4  mb-5">

                <div className="flex justify-between items-center">
                    <div className="flex gap-1  w-full items-center justify-between">
                        <span className="font-medium text-lg">
                            {Brand == 'all' ? 'Alle Producten' :  String(Brand).split(' ').map((word) => {
                                return word[0].toUpperCase() + word.substring(1);
                            }).join(" ") + " Sneakers"}
                        </span>
                        <span className="font-medium text-xs ">
                            {TotalFound} resultaten
                        </span>
                    </div>


                </div>

                <div onScroll={(e) => {
                    
                }} className={` grid xl:grid-cols-6 lg:grid-cols-5 sm:grid-cols-3 md:grid-cols-3 grid-cols-2   gap-3  overflow-y-scroll `}>
                    {SneakersFound?.map((Sneaker, index) => {

                        if (Sneaker.name != undefined) {
                            return <ProductItem ProductName={Sneaker.name} ProductImage={`http://localhost:7584/image/product/${Sneaker.sku}`} ProductUrl={Sneaker.slug} Productsku={Sneaker.sku} LowestPrice={Sneaker.storeprice} />
                        }
                    })}
                </div>
                <div className="w-full flex mx-auto">
                    <button onClick={() => {
                        SetPage(Page + 1)
                    }} className=" relative leading-4  w-full   font-medium  text-center m-0 rounded text-black px-3 py-2.5 border   text-xs">
                        Meer laden
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div onScroll={() => {
            
        }} className="flex flex-col w-full flex-shrink-0 mt-1 mb-5 p-4 px-10 space-y-4">
            <ProductViewer />
            <Footer/>
        </div>
    )
}


export default Products