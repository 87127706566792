
const Adidas = [
    "35 ½",
    "36",
    "36 2/3",
    "37 1/3",
    "38",
    "38 2/3",
    "39 1/3",
    "40",
    "40 2/3",
    "41 1/3",
    "42",
    "42 2/3",
    "43 1/3",
    "44",
    "44 2/3",
    "45 1/3",
    "46",
    "46 2/3",
    "47 1/3",
    "48",
    "48 2/3",
    "49 1/3",
    "50",
    "50 2/3",
    "51 1/3",
    "52 2/3"
]

const AdidasSlidesOrFoam = [
    "36",
    "37",
    "38",
    "39",
    "40.5",
    "42",
    "43",
    "44.5",
    "46",
    "47",
    "48.5",
    "50",
    "51",
    "52"
]

const AdidasInfant = [
    "19",
    "20",
    "21",
    "22",
    "23",
    "23.5",
    "24",
    "25",
    "25.5",
    "26",
    "26.5",
    "27"
]


const AdidasKids = [
    "28",
    "28.5",
    "29",
    "30",
    "30.5",
    "31",
    "31.5",
    "32",
    "33",
    "33.5",
    "34",
    "35",
    "35.5"
]

const AdidasSlidesOrFoamInfant = [
    "19",
    "20",
    "21",
    "23",
    "25"
]

const AdidasSlidesOrFoamKids = [
    "26",
    "27",
    "28",
    "29",
    "31",
    "32",
    "33",
    "34",
    "35"
]

const NikeMens = [
    "35.5",
    "36",
    "36.5",
    "37.5",
    "38",
    "38.5",
    "39",
    "40",
    "40.5",
    "41",
    "42",
    "42.5",
    "43",
    "44",
    "44.5",
    "45",
    "45.5",
    "46",
    "47",
    "47.5",
    "48",
    "48.5",
    "49.5"
]
const NikeGS = [
    "35.5",
    "36",
    "36.5",
    "37.5",
    "38",
    "38.5",
    "39",
    "40"
]
const NikeWMNS = [
    "35.5",
    "36",
    "36.5",
    "37.5",
    "38",
    "38.5",
    "39",
    "40",
    "40.5",
    "41",
    "42",
    "42.5",
    "43",
    "44",
    "44.5"
]
const NikeTD = [
    "17",
    "18.5",
    "19.5",
    "21",
    "22",
    "23.5",
    "25",
    "26",
    "27"
]
const NikePS = [
    "27.5",
    "28",
    "28.5",
    "29.5",
    "30",
    "31",
    "31.5",
    "32",
    "33",
    "33.5",
    "34",
    "35",
    "35.5"
]

const NewBalance = [
    "35",
    "35 ½",
    "36",
    "36 ½",
    "37",
    "37 ½",
    "38",
    "38 ½",
    "39",
    "39 ½",
    "40",
    "40 ½",
    "41",
    "41 ½",
    "42",
    "42 ½",
    "43",
    "44",
    "44 ½",
    "45",
    "45 ½",
    "46 ½",
    "47 ½",
    "49",
    "50",
    "51",
    "52",
    "53"
]


const BrandsWithName = {
    "2":{
       "Brand Name":"Nike Air Jordan",
       "Sizes":NikeMens
    },
    "3":{
       "Brand Name":"Nike",
       "Sizes":NikeMens
    },
    "1":{
       "Brand Name":"Adidas",
        "Sizes":Adidas
    },
    "8":{
       "Brand Name":"New balance"
    },
    "22":{
       "Brand Name":"Crocs"
    },
    "6":{
       "Brand Name":"Asics"
    },
    "25":{
       "Brand Name":"MSCHF"
    },
    "12":{
       "Brand Name":"Louis Vuitton"
    },
    "9":{
       "Brand Name":"Puma"
    },
    "4":{
       "Brand Name":"Converse"
    },
    "5":{
       "Brand Name":"Vans"
    },
    "24":{
       "Brand Name":"Bape"
    },
    "10":{
       "Brand Name":"Reebok"
    },
    "15":{
       "Brand Name":"Nike"
    },
    "17":{
       "Brand Name":"Saucony"
    },
    "13":{
       "Brand Name":"Balenciaga"
    }
}



async function FetchSizesBasedOnBrandId(ShoeData){
    

    if(ShoeData.name.includes('New Balance')){
        return NewBalance
    }


    else if(ShoeData.name.includes('(GS)')){
        return NikeGS
    }
    else if(ShoeData.name.includes('(PS)')){
        return NikePS

    }

    else if(ShoeData.name.includes('(TD)')){
        return NikeTD

    }
    else if(ShoeData.name.includes('Wmns') ||  ShoeData.name.includes('(W)') ){
        return NikeWMNS
    }

    else if(ShoeData.name.includes('Yeezy') && ShoeData.name.includes('(Kids)') && ShoeData.name.includes('350') ||  ShoeData.name.includes('450') ){
        return AdidasKids
    }
    else if(ShoeData.name.includes('Yeezy') && ShoeData.name.includes('(Infant)') && ShoeData.name.includes('350') ||  ShoeData.name.includes('450') ){
        return AdidasInfant
    }
    else if(ShoeData.name.includes('Slide')  || ShoeData.name.includes('Foam RNR')){

        if(ShoeData.name.includes('(Infant)')){
            return AdidasSlidesOrFoamInfant
    
        }
        else if(ShoeData.name.includes('(Kids)')){
            return AdidasSlidesOrFoamKids
    
        }

        
        return AdidasSlidesOrFoam
    }



    // 
    else{
        return BrandsWithName[ShoeData.brand_id].Sizes
    }


}

export default FetchSizesBasedOnBrandId