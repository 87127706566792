

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchBidByID(BidID) {
    return new Promise((resolve, reject) => {

        fetch(`${API_MAIN_URL}/bid/${BidID}`, {
            credentials: 'include'
        }).then(resp => resp.json()).then((FoundBid) => {
            // 
            resolve(FoundBid)
        })
    })

}

export default FetchBidByID