

import { GoSearch } from 'react-icons/go'
import { FaRegUser } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { useGlobalState } from '../state/state'
import { useEffect, useState } from 'react'
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai'


function Footer() {

    // const [CartCreated, SetCartCreated] = useGlobalState('CartCreated');
    // 



    return (
        <footer className='mt-1 top-0  z-[9]  bg-[#f5f5f5] border-t  w-full h-fit box-border overflow-hidden'>
            <div className="border-b border-[#ebeff1] top-0 w-full  " >
                <div className=' relative flex  flex-col px-10 py-5 gap-10'>
                    <div className=' mx-auto w-full flex md:flex-row md:gap-0 gap-10  flex-col justify-between box-content min-h-[66px] items-stretch '>


                        <div className='flex flex-col  md:w-3/5 sm:flex-row justify-between gap-10 items-stretch'>
                         <div className='flex flex-col gap-2'>
                            <span className='text-sm font-semibold'>
                                Klantenservice
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Contact
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Veelgestelde vragen
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Retouren & terugbetalingen
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Verzending & verwerking van bestelling
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Bestelling Annuleren
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Bestelling Status
                            </span>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='text-sm font-semibold'>
                                Account
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Inloggen
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Registreren
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Winkelwagen
                            </span>


                        </div>
                        <div className='flex flex-col gap-2 '>
                            <span className='text-sm font-semibold'>
                                Merken
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Nike
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Adidas
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Yeezy
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                New Balance
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Nike Dunk
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Jordan
                            </span>
                            <span className='text-xs font-light cursor-pointer'>
                                Off-white
                            </span>

                        </div>
                        </div> 
                        



                        <div className='flex flex-col gap-2  md:w-1/4'>
                            <span className='text-sm font-semibold'>
                                Meld je & ontvang 10% korting op je volgende bestelling
                            </span>
                            <div className='flex flex-col gap-3 '>
                                <input className='px-3 py-2  border text-xs' placeholder='E-mailadres' />
                                <button className='border w-40 px-4 py-2 text-xs rounded  relative leading-4 text-sm bg-[#0052ff]  font-medium w-auto m-0 rounded text-white px-3 py-2.5 border border-[#0052ff] '>
                                    Aanmelden
                                </button>
                                <div className='flex gap-2'>
                                    <AiOutlineTwitter size={20} className='cursor-pointer' />
                                    <AiOutlineInstagram size={20} className='cursor-pointer' />
                                    <AiOutlineFacebook size={20} className='cursor-pointer' />
                                </div>
                            </div>

                        </div>
                    </div> 
                    <div className='flex md:flex-row flex-col gap-3 md:justify-between md:items-end'>
                        <span className='text-xs font-extralight '>
                            @ 2022 ...Sneakers. All Rights Reserved.
                        </span>
                        <div className="hidden sm:flex gap-3">
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="https://postnl.post/img/new/logo_60.png" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UPS_Logo_Shield_2017.svg/1200px-UPS_Logo_Shield_2017.svg.png" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="//images.ctfassets.net/k4kk06v59kf0/6177FYImNGeYa2uIkWcO0q/dafb890147196202a418bf6cc5f8e3b6/maestro.svg" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="https://images.ctfassets.net/k4kk06v59kf0/6cp6aBUGIgqCsASkQeMsuc/571c8b783b3828aca365df95d2a7dbf2/klarna.svg" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="https://images.ctfassets.net/k4kk06v59kf0/4uX1chxJ6woaCYsyOoCISk/b59a2ff4a7cfb28202590baabe678770/paypalNarrow.svg" />
                            </div>


                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="//images.ctfassets.net/k4kk06v59kf0/1lHhWhbEAgY6sKa4Km8wqI/4ef2dca4c2dd6dc594b4eb8a504dab76/visaNarrow.svg" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="//images.ctfassets.net/k4kk06v59kf0/1OaMv75Tv01kqKxgCOFfUx/d3426e8cf0c8053d53207062df1cb684/iDealLogo_new_icon.svg" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="//images.ctfassets.net/k4kk06v59kf0/3a3Arh48r6W0oUYsyQE2IC/f9b579bec1306f6e533f677677778b46/bancontactNarrow.svg" />
                            </div>
                            <div className="border px-3 rounded-lg py-1">
                                <img className="h-5" src="https://images.ctfassets.net/k4kk06v59kf0/1N5GHsxl0tjzPIvfvWtepb/3dc584c599a85ad0d70d5dbb3c852fc2/Apple_Pay_logo.svg" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}


export default Footer