

const API_MAIN_URL = "http://localhost:8080/api"

async function DeleteListingByID(ID){
    return new Promise((resolve , reject) =>{

        fetch(`${API_MAIN_URL}/listing/delete` , {
            method:'POST',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
            body:JSON.stringify({
                id:ID
            })
        }).then(resp => resp.json()).then((response) =>{
            // 
            resolve(response)
        })
    })

}

export default DeleteListingByID