

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchSales(type){
    return new Promise((resolve , reject) =>{
       
        fetch(`${API_MAIN_URL}/sales?query=${type}` , {
            credentials:'include'
        }).then(resp => resp.json()).then((FoundListings) =>{
            // 
            resolve(FoundListings)
        })
    })

}

export default FetchSales