import { useEffect, useState } from "react"
import FetchSneakers from "../services/FetchSneakers"
import { BiQuestionMark } from 'react-icons/bi'
import { BsNutFill, BsQuestionCircle } from "react-icons/bs"
import { CreateListing } from "../services/CreateListing"
import { useNavigate, useLocation } from "react-router-dom"
import ProductPricing from '../services/ProductPricing'

function CreateListingPage() {


    const [SelectedShoe, SetSelectedShoe] = useState({})
    const [valid, Setvalid] = useState(false)
    const [Price, SetPrice] = useState(null)
    const [Size, SetSize] = useState(null)
    const [Quantity, SetQuantity] = useState(null)
    const [UserQuestions2, SetUserQuestions2] = useState(false)
    const [UserQuestions, SetUserQuestions] = useState(false)
    const [modal, SetModal] = useState(false)
    const [LowestPrice, SetLowestPrice] = useState(false)
    const location = useLocation();


    useEffect(() => {
        if (location.state != null) {
            SetSelectedShoe(location.state)
        }
    }, [])



    useEffect(() => {
        
        if (Size == null) return
        ProductPricing(SelectedShoe.sku).then(response => {
            const CurrentPrice = response[Size]?.Price
            if (CurrentPrice == undefined) {
                
                SetLowestPrice(true)
                //current price input is lowest price!
                return
            }

            //else calculate difference 
            const DifferenceBasedOnPrice = parseFloat(parseFloat(Price) - parseFloat(CurrentPrice)).toFixed(2)



            if (DifferenceBasedOnPrice == 0) {
                SetLowestPrice('same_price_as_lowest_listing')
                
                return
            }

            else if (DifferenceBasedOnPrice > 0) {
                
                SetLowestPrice(false)
            }

            else {
                
                SetLowestPrice(true)
            }



        })
    }, [Price, Size])


    // const SetEverythingToDefault = () =>{
    //     SetSelectedShoe({})
    //     Setvalid(false)
    //     SetPrice(null)
    //     SetSize(null)
    //     SetQuantity(null)
    //     SetUserQuestions2(false)
    //     SetUserQuestions(false)

    // }
    const navigate = useNavigate();

    const HandleSubmitLising = () => {
        const JSON_DATA = {
            ShoeData: SelectedShoe,
            valid: valid,
            Price: parseInt(Price),
            Size: Size,
            Quantity: parseInt(Quantity),

            Payout: Price - 10,
            ShipmentAgreement: UserQuestions == true && UserQuestions2 == true,

        }

        // 
        CreateListing(JSON_DATA).then(resp => {
            
            if (resp.created) {
                //redirect to other success page
                navigate(`/account/listing/created/${resp.listing}`)


            }

        })


    }


    useEffect(() => {

        let valid_check = false
        if (Price != null && Size != null && Quantity != null && UserQuestions2 != false && UserQuestions != false) {
            valid_check = true
        }
        else {
            valid_check = false
        }
        if (Price != 0 && Size != null && Quantity != 0 && UserQuestions2 != false && UserQuestions != false) {
            valid_check = true
        }
        else {
            valid_check = false
        }





        Setvalid(valid_check)
    }, [UserQuestions, UserQuestions2, Quantity, Size, Price])


    const SearchSection = () => {


        const [SearchQuery, SetSearchQuery] = useState('')
        const [FoundSneakersBasedOnQuery, SetFoundSneakersBasedOnQuery] = useState([])


        useEffect(() => {


            FetchSneakers(SearchQuery).then(FoundSneakers => {
                
                SetFoundSneakersBasedOnQuery(FoundSneakers)
            })


        }, [SearchQuery])

        const ProductItem = ({ SneakerData, ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
            return (
                <div onClick={() => {
                    SetSelectedShoe(SneakerData)
                }} className="border flex flex-col p-3  min-w-[100%]  flex-1 cursor-pointer mx-auto ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={ProductImage} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 ">
                        <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                            {ProductName}
                        </span>


                    </div>
                    <div className="mt-3 text-left flex justify-between">
                        <div className="flex flex-col">
                            <span className="text-xs overflow text-ellipsis font-light opacity-60 line-clamp-2">
                               Laagste Prijs
                            </span>
                            <span className="text-xs">
                                {LowestPrice}
                            </span>
                        </div>
                        <div className=" w-[1px] bg-slate-200 opacity-60" />
                        <div className="flex flex-col">
                            <span className="text-xs overflow text-ellipsis font-light opacity-60 line-clamp-2">
                                SKU
                            </span>
                            <span className="text-xs">
                                {Productsku}
                            </span>
                        </div>
                    </div>



                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </div>
            )
        }

        return (
            <div className="  text-center flex flex-col w-full   ">
                <div className=" flex flex-col w-full space-y-3">

                    <input onChange={(e) => SetSearchQuery(e.target.value)} className="border p-2" placeholder="Zoeken..." />
                </div>
                <div className={`w-full  ${SearchQuery.length > 0 ? 'flex' : "hidden"} p-5 border border-t-0 h-fit flex flex-col`}>
                    Zoek Resultaten voor:  {SearchQuery}

                    <div className="grid x grid-cols-2 sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-6 mt-5 justify-center gap-4 ">
                        {FoundSneakersBasedOnQuery.map((sneaker, index) => <ProductItem SneakerData={sneaker} ProductName={sneaker.name} ProductImage={`http://localhost:7584/image/product/${sneaker.sku}`} ProductUrl={sneaker.slug} Productsku={sneaker.sku} LowestPrice={sneaker.storeprice} />)}
                    </div>
                </div>
            </div>
        )
    }


    // const RulesSection = () => {
    //     return (
    //         <div className="w-[30%] text-center ">
    //             <span className="text-sm font-medium    ">
    //                 Hoe werkt...Sneakers?

    //             </span>
    //         </div>

    //     )
    // }

    const ProductItemSelected = ({ SneakerData, ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
        return (
            <div onClick={() => {
                SetSelectedShoe(SneakerData)
            }} className="border flex flex-col p-3   w-full  flex-1 cursor-pointer  ">

                {/* Image Section */}
                <div id="" className="w-[140px] h-[75px] m-auto">
                    <img className=" object-contain " src={ProductImage} />
                </div>

                {/* Text Section */}

                <div className="text-left flex flex-col w-full mt-8 ">
                    <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                        {ProductName}
                    </span>


                </div>
                <div className="mt-3 text-left flex justify-between">
                    <div className="flex flex-col">
                        <span className="text-xs overflow text-ellipsis font-light opacity-60 line-clamp-2">
                            Lowest Price
                        </span>
                        <span className="text-xs">
                            {LowestPrice}
                        </span>
                    </div>
                    <div className=" w-[1px] bg-slate-200 opacity-60" />
                    <div className="flex flex-col">
                        <span className="text-xs overflow text-ellipsis font-light opacity-60 line-clamp-2">
                            SKU
                        </span>
                        <span className="text-xs">
                            {Productsku}
                        </span>
                    </div>
                </div>



                {/* <div className="w-[140px] h-[75px]">
                        <img  src={ProductImage}  />
                    </div>
                   <span className="text-sm">
                    {ProductName}
                   </span> */}
            </div>
        )
    }

    const ProductItemSold = ({ SneakerData, ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
        return (
            <div onClick={() => {
                SetSelectedShoe(SneakerData)
            }} className="border flex flex-col p-3   w-full  flex-1 cursor-pointer  ">

                {/* Image Section */}
                <div id="" className="w-[140px] h-[75px] m-auto">
                    <img className=" object-contain " src={ProductImage} />
                </div>

                {/* Text Section */}

                <div className="text-left flex flex-col w-full mt-8 ">
                    <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                        {ProductName}
                    </span>


                </div>
                <div className="mt-3 text-left flex justify-between">
                    <div className="flex flex-col">
                        <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
                            Prijs
                        </span>
                        <span className="text-sm">
                            €{Price}
                        </span>
                    </div>
                    <div className=" w-[1px] bg-slate-200 opacity-60" />
                    <div className="flex flex-col">
                        <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
                            SKU
                        </span>
                        <span className="text-sm">
                            {Productsku}
                        </span>
                    </div>
                </div>



                {/* <div className="w-[140px] h-[75px]">
                        <img  src={ProductImage}  />
                    </div>
                   <span className="text-sm">
                    {ProductName}
                   </span> */}
            </div>
        )
    }

    const ListingDetails = () => {
        return (
            <div className="flex flex-col  mt-10 justify-between gap-2">
                <div className="mb-2 gap-3 flex w-fit">
                    <div className="flex h-[50px] rounded-lg flex-col relative justify-center ">
                        <input type={'number'} id="test" name="email"
                            onChange={(e) =>
                                SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Prijs (€)

                        </span>

                    </div>
                    <div className="flex h-[50px] min-w-[120px] rounded-lg flex-col relative justify-center mb-5">
                        <select type={'number'} id="test" name="email"
                            onChange={(e) =>{

                            }} className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required>
                            <option></option>
                        </select>
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Maat

                        </span>
                    </div>
                </div>


                <div className="flex h-[50px] rounded-lg flex-col relative justify-center">
                    <input type={'number'} id="test" name="email"
                        onChange={(e) =>{
                            
                        }} className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                        Aantal

                    </span>
                </div>
            </div>
        )
    }



    const Modal = () => {
        return (
            <div id="parent" onClick={(e) => {
                if (e.target.id == 'parent') {
                    SetModal(false)
                }
            }} className="fixed cursor-pointer flex justify-center items-center bg-[#0000004d]  h-full w-full top-0 left-0 !m-0 z-[1000]">
                <div className="z-[1100] shadow-xl  cursor-default gap-4 flex-col bg-white w-2/6  h-fit p-4 rounded-md flex m-auto my-auto !opacity-100 text-black">
                    <span>
                        Advertentie Geplaats!
                    </span>
                    <div className="w-full m-auto flex-col">
                        <ProductItemSold SneakerData={SelectedShoe} ProductName={SelectedShoe.name} ProductImage={`http://localhost:7584/image/product/${SelectedShoe.sku}`} ProductUrl={SelectedShoe.slug} Productsku={SelectedShoe.sku} LowestPrice={SelectedShoe.storeprice} />

                    </div>

                    <div className="flex gap-4 justify-between">
                        <button class=" relative leading-4 text-sm bg-[#0052ff]  font-medium w-auto m-0 rounded text-white px-3 py-2.5 border border-[#0052ff] ">
                            Plaats nog een Advertentie
                        </button>
                        <button class=" relative leading-4 text-sm bg-[#777777]  font-medium w-auto m-0 rounded text-white px-3 py-2.5 border border-[#777777] ">
                            Bekijk Advertentie
                        </button>
                    </div>

                </div>


            </div>
        )
    }





    return (
        <div className="flex  w-full flex-shrink-0 mt-1 mb-5 p-4 px-10 space-y-4 gap-5">
            <div className="flex flex-col w-full gap-2">
                <span className="text-xl font-medium">
                    Welke sneaker wil je verkopen?

                </span>

                <SearchSection />

                {SelectedShoe.name ?
                    <div className="flex xl:flex-row lg:flex-row flex-col xl:gap-20 lg:gap-20 gap-5 mt-10 w-full justify-between  h-fit ">
                        <div className="flex flex-col">
                            <ProductItemSelected SneakerData={SelectedShoe} ProductName={SelectedShoe.name} ProductImage={`http://localhost:7584/image/product/${SelectedShoe.sku}`} ProductUrl={SelectedShoe.slug} Productsku={SelectedShoe.sku} LowestPrice={SelectedShoe.storeprice} />
                            <div className="flex flex-col  mt-10 justify-between gap-2">
                                <div className="mb-2 gap-3 flex w-full">
                                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">
                                        <input type={'number'} id="test" name="email"
                                            onChange={(e) =>
                                                SetPrice(e.target.value)
                                            } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                            Prijs (€)

                                        </span>

                                    </div>
                                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center mb-5">
                                        <select type={'number'} id="test" name="email"
                                            onChange={(e) =>
                                                SetSize(e.currentTarget[e.currentTarget.selectedIndex].textContent)
                                            } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required>


                                            <option value='disabled' disabled defaultChecked defaultValue={""} >
                                                Selecteer Maat
                                            </option>
                                            <option value="54">35 ½</option><option value="1">36</option><option value="13">36 ½</option><option value="44">37 ½</option><option value="3">38</option><option value="48">38 ½</option><option value="4">39</option><option value="5">40</option><option value="22">40 ½</option><option value="6">41</option><option value="7">42</option><option value="23">42 ½</option><option value="8">43</option><option value="9">44</option><option value="24">44 ½</option><option value="10">45</option><option value="41">45 ½</option><option value="11">46</option><option value="49">47</option><option value="25">47 ½</option><option value="21">48</option><option value="26">48 ½</option><option value="42">49 ½</option><option value="28">50 ½</option><option value="29">51 ½</option><option value="53">52 ½</option>


                                        </select>
                                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                            Maat

                                        </span>
                                    </div>
                                </div>


                                <div className="flex h-[50px] rounded-lg flex-col relative justify-center">
                                    <input type={'number'} id="test" name="email"
                                        onChange={(e) =>
                                            SetQuantity(e.target.value)
                                        } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                                    <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                        Aantal

                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border opacity-30" />

                        <div className="flex w-full xl:w-4/6 lg:w-4/6 flex-col  gap-4 ">
                            <span className=" font-medium">
                                Advertentie Bevestigen
                            </span>
                            <div className="flex gap-8">
                                <div className="flex flex-col gap-2 border-b w-fit py-2">
                                    <span className="text-xs opacity-60 ">
                                        Sneaker
                                    </span>
                                    <span className="text-sm">
                                        {SelectedShoe.name}
                                    </span>

                                </div>
                                <div className="flex flex-col gap-2 border-b w-1/4 py-2">
                                    <span className="text-xs opacity-60 ">
                                        Maat
                                    </span>
                                    <span className="text-sm">
                                        {Size != null ? Size : '?'}
                                    </span>

                                </div>

                            </div>
                            <div className="flex gap-8">
                                <div className="grow-1 flex  min-h-[85px] w-1/2 flex-col gap-2">
                                    <div className="flex flex-col  gap-2 border-b w-full py-2">
                                        <span className="text-xs opacity-60 ">
                                            Prijs
                                        </span>
                                        <span className="text-sm">
                                            €{Price}
                                        </span>

                                    </div>

                                    <span className="text-xs opacity-60 flex w-fit items-center gap-1">

                                        {parseFloat(Price) > 0 && Size != null ? <>
                                            {LowestPrice == true ? 'Je bent de goedkoopste!' : LowestPrice == 'same_price_as_lowest_listing' ? ' Er is een andere verkoper met dezelfde prijs' : 'Je bent niet de goedkoopste!'}
                                            <BsQuestionCircle className="cursor-pointer !text-black" />
                                        </> : ''}

                                    </span>
                                </div>
                                <div className="grow-1 flex w-1/2 flex-col gap-2">
                                    <div className="flex flex-col  gap-2 border-b w-full py-2">
                                        <span className="text-xs opacity-60 ">
                                            Payout
                                        </span>
                                        <span className="text-sm">
                                            {Price != null && Price != 0 ? `€${Price - 10}` : '?'}
                                        </span>

                                    </div>


                                </div>






                            </div>
                            <div className="flex gap-8">
                                <div className="grow-1 flex flex-col gap-2">
                                    <div className="flex flex-col  gap-2 border-b w-full py-2">
                                        <span className="text-xs opacity-60 ">
                                            Aantal
                                        </span>
                                        <span className="text-sm ">
                                            {Quantity != null ? Quantity : '?'}
                                        </span>

                                    </div>

                                </div>







                            </div>
                            <div className="flex gap-8">
                                <div className="grow-1 flex flex-col gap-2">
                                    <div className="flex   gap-2 w-full py-2">
                                        <input onChange={(e) => {
                                            if (e.target.checked) {
                                                SetUserQuestions2(true)
                                            }
                                            else {
                                                SetUserQuestions2(false)
                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Als ik het product niet of niet juist opstuur, brengt ... Sneakers automatisch €10 in rekening
                                        </span>

                                    </div>

                                </div>







                            </div>
                            <div className="flex gap-8">
                                <div className="grow-1 flex flex-col gap-2">
                                    <div className="flex   gap-2 border-b w-full py-2">
                                        <input onChange={(e) => {
                                            
                                            if (e.target.checked) {
                                                SetUserQuestions(true)
                                            }
                                            else {
                                                SetUserQuestions(false)
                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Ik verzend binnen drie werkdagen na verkoop
                                        </span>

                                    </div>

                                </div>







                            </div>
                            <button onClick={() => {
                                HandleSubmitLising()
                            }} disabled={!valid} className={`mt-auto  relative leading-4 text-sm ${valid ? 'bg-[#0052ff] border-[#0052ff] cursor-pointer ' : 'bg-[#777777] border-[#777777] cursor-not-allowed '}   font-medium w-auto m-0 rounded text-white px-3 py-2.5 border `} >
                                Advertentie plaatsen
                            </button>
                        </div>
                    </div> : <div className="flex flex-col gap-5 mt-10">
                        {/* <span className="text-xl font-medium">
                            Hoe werkt ...Sneakers?
                        </span> */}
                        <div className="flex gap-5 mt-10">
                            <div className="flex   gap-10">
                                <div className="flex flex-col gap-3 ">
                                    <div className=" ">
                                        <img className="h-[50px] w-[60px]" src="https://i.pinimg.com/originals/bb/8b/2f/bb8b2f2a1a5a8dc1d7b35530d21dac16.png" />
                                    </div>
                                    <div className="flex flex-col gap-1 ">
                                        <span className="text-sm font-medium">
                                            Plaats een advertentie
                                        </span>
                                        <span className="text-xs opacity-80">
                                            Kies aan de hand van een SKU of productnaam je item en bepaal je verkoopprijs.

                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 ">
                                    <div >
                                        <img className="h-[50px] w-[60px]" src="https://www.pngmart.com/files/15/Packed-Cardboard-Box-PNG-Transparent-Image.png" />
                                    </div>
                                    <div className="flex flex-col gap-1 ">
                                        <span className="text-sm font-medium">
                                            Iets verkocht?

                                        </span>
                                        <span className="text-xs opacity-80">
                                            Verzend binnen 3 werkdagen. Wij authenticeren en verzenden naar de koper.


                                        </span>

                                    </div>
                                </div>

                                <div className="flex flex-col gap-3 ">
                                    <div >
                                        <img className="h-[50px] w-[60px]" src="https://creazilla-store.fra1.digitaloceanspaces.com/emojis/47137/money-with-wings-emoji-clipart-md.png" />
                                    </div>
                                    <div className="flex flex-col gap-1 ">
                                        <span className="text-sm font-medium">
                                            Betaling

                                        </span>
                                        <span className="text-xs opacity-80">
                                            Als je sneakers succesvol zijn geauthenticeerd door ...Sneakers, betalen we je uit na 20 werkdagen.



                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

            </div>

            {modal ? <Modal /> : null}

            {/* <RulesSection /> */}
        </div>

    )
}

export default CreateListingPage