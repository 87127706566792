// import faker from '@faker-js/faker'

import { faker } from '@faker-js/faker';
import FetchListings from '../../services/FetchListings';


export async function fetchData(options) {



  // Simulate some network latency
  let pageCount

  var data = await new Promise(async (resolve, reject) => {
    const resp = await FetchListings(options.pageIndex)
    const Listings = await resp.Listings
    pageCount = await resp.PageCount
    resolve(Listings)

  })


  try {
    console.log({
      rows: data.slice(
        options.pageIndex * options.pageSize,
        (options.pageIndex + 1) * options.pageSize
      ),
    })
  }
  catch (err) {
    console.log(err)
  }

  // return {
  //   rows: data[0],
  //   pageCount: Math.ceil(data[1].total / options.pageSize),
  // }

  return {
    rows: data,
    pageCount:  Math.ceil(pageCount / 15),
  }
}