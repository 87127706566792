import NavBar from "./components/navbar";
import Home from "./screens/Home";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from "./screens/Login";
import AccountPage from "./screens/Account";
import ProductPage from "./screens/productPage";
import CreateListingPage from "./screens/CreateListing";
import CreatedListingPage from "./screens/CreatedListingPage";
import CartPage from "./screens/UserCart";
import Products from "./screens/Products";
import PageNotFound from "./screens/PageNotFound";
import CheckoutPage from "./screens/CheckoutPage";
import FaqScreen from "./screens/FaqScreen";
import ProductBids from "./screens/ProductBidsPage";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import ProfleComponent from "./components/Profile/Profile";
import ProductSell from "./screens/ProductSellPage";
import CreatedBidPage from "./screens/CreatedBidPage";


//github test 2...

const stripePromise = loadStripe('pk_test_51LVi4NJsB0RaIXyoGKDANRIk5KKuy7kO3Z0aNJn7AfC9Hdl5UVXj7JvNu1RpZgxJ67pWSjLbosxV3e97RCZvDAPR008Ib5R4kF');

export default function App() {

  const options = {
    // passing the client secret obtained from the server
    // clientSecret: 'sk_test_51LVi4NJsB0RaIXyoh8l6SWbAEdJRcYM6IdqbtyjjbY724gMOoxmd0Qvbr1upAAEdA2P4OpyzV6yKfOY78FnPPsi9006xaevQkK',
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      
      <BrowserRouter>
        <div className="h-full w-full">
          <NavBar />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/products/:Brand" element={<Products />}></Route>
            <Route exact path="/product/bids/:ProductSKU" element={<ProductBids />}></Route>
            <Route exact path="/login" element={<LoginPage />}></Route>
            <Route exact path="/account" element={<AccountPage />}></Route>

            <Route exact path="/account/:subpath" element={<AccountPage />}></Route>
            {/* <Route exact path="/account/profile" element={<ProfleComponent />}></Route> */}

            <Route exact path="/account/sell" element={<CreateListingPage />}></Route>
            <Route exact path="/account/listing/created/:ListingID" element={<CreatedListingPage />}></Route>
            <Route exact path="/account/cart" element={<CartPage />}></Route>
            <Route exact path="/account/bid/:BidID" element={<CreatedBidPage />}></Route>
                
            <Route exact path="/product/:ProductSKU" element={<ProductPage />}></Route>
            <Route exact path="/sell/" element={<ProductSell />}></Route>
            <Route exact path="/sell/:ProductSKU" element={<ProductSell />}></Route>
            
            <Route path="/account/checkout" element={<CheckoutPage />}></Route>
            <Route path="/faq" element={<FaqScreen />} />
            <Route exact path="*" element={<PageNotFound />} />


          </Routes>


        </div>
      </BrowserRouter>

    </Elements>

  )
}