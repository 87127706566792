import { FcGoogle } from "react-icons/fc"
import { BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

import { handleSubmitRegister, handleSubmitLogin } from '../services/auth-service'

function LoginPage() {

    // const { user: currentUser } = useSelector((state) => state.auth);

    // const dispatch = useDispatch();

    const [ShowPassword, SetShowPassword] = useState(false)
    const [values, setValues] = useState({ email: "", password: "", firstName: "", lastName: "" });

    const [AccountState, SetAccountState] = useState('Login')
    const [cookies] = useCookies(["cookie-name"]);
    const navigate = useNavigate();
    useEffect(() => {
        if (cookies.jwt) {
            navigate("/account");
        }
    }, [cookies, navigate]);


    useEffect(() => {
        setValues({ email: "", password: "", firstName: "", lastName: "" })
    }, [AccountState])


    // const handleSubmitRegister = async (event) => {
    //     event.preventDefault();
    //     try {
    //         const { data } = await register()
    //         if (data) {
    //             if (data.errors) {
    //                 const { email, password } = data.errors;
    //                 if (email) alert(email);
    //                 else if (password) alert(password);
    //             } else {
    //                 navigate("/account");
    //             }
    //         }
    //     } catch (ex) {
    //         
    //     }
    // };




    const [loading, setLoading] = useState(false);

    // const handleLogin = (e) => {
    //     e.preventDefault();

    //     setLoading(true);

    //     // form.current.validateAll();

    //     dispatch(login(values.email , values.password))
    //         .then(() => {
    //         //   props.history.push("/profile");
    //         //   window.location.reload();
    //         })
    //         .catch(() => {
    //           setLoading(false);
    //         });

    //   };

    return (

        <div className="h-fit flex ">
            <div className="m-auto mt-[40px] min-w-[500px] min-h-[80%] h-fit  border rounded p-10  mb-10 flex flex-col">
                <div className="flex  justify-between gap-2 mb-10 ">
                    <button onClick={() => {
                        SetAccountState('Login')
                    }} className={`text-center transition-all delay-150 ease-linear text-sm w-1/2 border-b-2 ${AccountState == 'Login' ? "border-black" : "opacity-70"}  pb-3 rounded-bl-sm rounded-br-sm cursor-pointer`}>

                        Inloggen

                    </button>
                    <button onClick={() => {
                        SetAccountState('Create')
                    }} className={`text-center transition-all delay-150 ease-linear text-sm w-1/2 border-b-2  pb-3 ${AccountState == 'Create' ? "border-black" : "opacity-70"} rounded-bl-sm rounded-br-sm cursor-pointer`}>

                        Account Maken

                    </button>
                </div>

                {AccountState == 'Login' ?

                    <div className="w-full  flex-col flex mb-10">
                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-10">
                            <input id="test" name="email"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                E-mailadres

                            </span>
                        </div>
                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-2">
                            <input name="password"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } type={ShowPassword ? 'text' : 'password'} className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className="input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                Wachtwoord

                            </span>
                            <button onClick={() => {
                                SetShowPassword(!ShowPassword)
                            }} className="absolute right-0 pr-5 ">
                                <BiHide className="h-5" color="gray" size={32} />

                            </button>
                        </div>

                        <div className="flex mb-10 justify-end ">
                            <span className="text-xs transition-all delay-150 opacity-60 hover:text-[#0052ff] hover:cursor-pointer hover:opacity-100 ">
                                Wachtwoord vergeten?
                            </span>
                        </div>
                        <button onClick={(e) => {
                            handleSubmitLogin(e, values).then(resp => {
                                if (resp.status == true) {
                                    navigate('/account')
                                }
                            })
                            // handleLogin(e)
                        }} className="w-full bg-[#0052ff] p-3 rounded text-white ">
                            Inloggen
                        </button>
                    </div> :
                    <div className="w-full  flex-col flex mb-10">
                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-10">
                            <input name="firstName"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } id="test2" className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                Voornaam

                            </span>
                        </div>
                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-10">
                            <input name="lastName"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                Achternaam

                            </span>
                        </div>
                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-10">
                            <input id="test2" name="email"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                E-mailadres

                            </span>

                        </div>

                        <div className="flex h-[50px] rounded-lg flex-col relative justify-center mb-10">
                            <input name="password"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } type={ShowPassword ? 'text' : 'password'} className="w-full p-3 text-sm py-4 input-field border rounded-md" placeholder="" required />
                            <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                                Wachtwoord

                            </span>
                            <button onClick={() => {
                                SetShowPassword(!ShowPassword)
                            }} className="absolute right-0 pr-5 ">
                                <BiHide className="h-5" color="gray" size={32} />

                            </button>
                        </div>
                        <button onClick={(e) => {
                            handleSubmitRegister(e, values).then(resp => {
                                if (resp.created == true) {
                                    navigate('/account')
                                }
                            })
                        }} className="w-full bg-[#0052ff] p-3 rounded text-white ">
                            Account Maken
                        </button>
                    </div>
                }

                <div className="flex items-center gap-2 mb-10">
                    <div className="flex-grow h-[2px] opacity-40 rounded-full bg-slate-400" />
                    <span className="text-xs opacity-40 font-medium uppercase">
                        Of
                    </span>
                    <div className="flex-grow h-[2px] opacity-40 rounded-full bg-slate-400" />


                </div>

                <button id="google-btn" className=" w-full border p-4 rounded flex  justify-center">
                    <span className="flex items-center justify-center text-center gap-3">
                        <FcGoogle size={22} />
                        <span className="font-medium">
                            Inloggen met Google

                        </span>
                    </span>
                </button>
            </div>
            {/* <ToastContainer className={'fgjhfj'} /> */}
        </div>


    )
}

export default LoginPage