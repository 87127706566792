import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { RetrievePaymentMethod, UpdatePaymentMethod } from '../../../services/AccountService';
import { FaCcMastercard, FaCcVisa, FaEdit } from 'react-icons/fa'
import ReactLoading from 'react-loading'



function PaymentInfo() {
    const stripe = useStripe();
    const elements = useElements();


    const ValidateValueBasedOnName = (name, value, country) => {
        // 
        switch (name) {
            case "fullName":
                var regex = /^[a-z ,.'-]+$/i
                var Valid = regex.test(value)
                // 
                return Valid
                break
            case "email":
                var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                var Valid = regex.test(value)
                // 
                return Valid
                break

            default:
                break;
        }
    }

    const [EditPaymentMethod, SetEditPaymentMethod] = useState(false)

    const [NewCreditCardValid, SetNewCreditCardValid] = useState(false)

    const [PaymentMethodForm, SetPaymentMethodForm] = useState(() => {
        RetrievePaymentMethod().then(response => {
            // 
            // response.Account = {}
            try {
                const Card = {
                    Brand: response.Account.Payment.PaymentMethod.card.brand,
                    exp_month: response.Account.Payment.PaymentMethod.card.exp_month,
                    exp_year: response.Account.Payment.PaymentMethod.card.exp_year,
                    last4: response.Account.Payment.PaymentMethod.card.last4,
                    id: response.Account.Payment.PaymentMethod.id

                }
                SetPaymentMethodForm({ fullName: response.Account.Payment.FullName, email: response.Account.Payment.Email, Card })
            }
            catch (err) {

            }
            // response
        })
    })

    const [PaymentMethodFormValid, SetPaymentMethodFormValid] = useState(false)
    const [SavingPaymentDetails, SetSavingPaymentDetails] = useState(false)



    useEffect(() => {
        if (PaymentMethodForm == undefined) return


        const cardElement = elements.getElement("card")

        if (cardElement == null) {
            SetPaymentMethodFormValid(false)
            return
        }

        if (NewCreditCardValid == false) {
            SetPaymentMethodFormValid(false)
            return
        }
        

        const FirstFormValid = Object.entries(PaymentMethodForm).every((item) => {

            const [name, value] = item



            // 
            if (value?.valid) {
                return true
            }



            // 
            if (name == '_id' || name == "createdAt" || name == "updatedAt" || name == "Card") {
                return true
            }

            const valid = ValidateValueBasedOnName(name, value)
            
            return valid
        })



        if (FirstFormValid) {
            SetPaymentMethodFormValid(true)
            return
        }
        else {
            SetPaymentMethodFormValid(false)
        }

        // if(FirstFormValid){
        //     SetAddressInfoFormValid(FirstFormValid)

        //     return
        // }


        // const FormValid = Object.entries(AddressInfoForm).every((item) => {
        //     if (item[0] == '_id' || item[0] == "createdAt" || item[0] == "updatedAt" || item[0] == "country") {
        //         return true
        //     }

        //     

        //     return item[1].valid
        // })

        // SetAddressInfoFormValid(FormValid)


    }, [PaymentMethodForm, NewCreditCardValid])



    useEffect(() => {
        
    }, [PaymentMethodFormValid])
    const OnChangePaymentForm = async (e) => {


        const valid = ValidateValueBasedOnName(e.target.name, e.target.value)

        SetPaymentMethodForm({ ...PaymentMethodForm, [e.target.name]: e.target.value })

        const value = e.target.value
        return { valid, value }


    }


    const HandleUpdatePaymentMethod = async (e) => {

        const cardElement = elements.getElement("card")


        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: PaymentMethodForm.fullName,
                email: PaymentMethodForm.email
            },
        })


        if (error) {
            return error.code
        }

        // 


        const Updated = await UpdatePaymentMethod(paymentMethod)

        if (Updated.Success) {
            //successfully updated!

            try {
                const Card = {
                    Brand: Updated.default_payment_method.card.brand,
                    exp_month: Updated.default_payment_method.card.exp_month,
                    exp_year: Updated.default_payment_method.card.exp_year,
                    last4: Updated.default_payment_method.card.last4,

                }
                SetPaymentMethodForm({ fullName: Updated.default_payment_method.billing_details.name, email: Updated.default_payment_method.billing_details.email, Card })
                SetEditPaymentMethod(false)
            }
            catch (err) {

            }
        }
        //send this to server to save it on the user model


        // stripe.createToken(cardElement)
        // .then((payload) => 
    }


    // useEffect(() => {

    // }, [PaymentMethodForm])

    return (
        <div className=" p-0  pb-0 flex flex-col ">
            <span className='text-sm mb-6'>
                Betaalgegevens
            </span>


            <div className=" w-1/2 gap-3 flex justify-between mb-6">
                <div className="flex h-[50px] w-2/4 rounded-lg flex-col relative justify-center ">
                    <input value={PaymentMethodForm?.fullName} type={'text'} name="fullName"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangePaymentForm(e)

                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                            // SetPrice(e.target.value)
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Volledige Naam

                    </span>

                </div>
                <div className="flex h-[50px] w-2/4 rounded-lg flex-col relative justify-center ">
                    <input value={PaymentMethodForm?.email} type={'text'} name="email"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangePaymentForm(e)

                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                            // SetPrice(e.target.value)
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Email

                    </span>

                </div>


            </div>
            <div className=" gap-3  flex w-full mb-6">
                <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">

                    {PaymentMethodForm?.Card && EditPaymentMethod == false ?
                        <>
                            <div onClick={() => {
                                SetEditPaymentMethod(!EditPaymentMethod)
                            }} className="w-full cursor-pointer p-3 text-xs py-2 max-h-[60px] items-center  gap-5 input-field border rounded-md flex">
                                {(() => {
                                    switch (PaymentMethodForm?.Card.Brand) {
                                        case "visa":
                                            return (
                                                <FaCcVisa size={23} />
                                            )
                                            break;
                                        case "mastercard":
                                            return (
                                                <FaCcMastercard size={23} />
                                            )
                                            break;

                                        default:
                                            break;
                                    }

                                })()}

                                <span>
                                    •••• {PaymentMethodForm?.Card.last4}
                                </span>
                                <span>
                                    {String(PaymentMethodForm?.Card.exp_month).length == 1 ? "0" + PaymentMethodForm?.Card.exp_month : PaymentMethodForm?.Card.exp_month} / {PaymentMethodForm?.Card.exp_year}
                                </span>
                                <FaEdit size={13} className='cursor-pointer ml-auto' />
                            </div>
                        </>
                        : <CardElement options={
                            { hidePostalCode: true }
                        }
                            type={'text'} name="card"
                            onChange={(e) => {
                                if (e.complete && e.error == undefined) {
                                    SetNewCreditCardValid(true)
                                }
                                else {
                                    SetNewCreditCardValid(false)

                                    //    
                                }
                            }}
                            className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />

                    }

                </div>
            </div>


            <button disabled={!PaymentMethodFormValid} onClick={async (e) => {
                SetSavingPaymentDetails(true)

                const Updated = await HandleUpdatePaymentMethod(e)
                
                SetSavingPaymentDetails(false)
                if (Updated) {
                    //notify user 
                }
                else {
                    //pop up failed
                }

            }} className={`py-2  ${PaymentMethodFormValid == false ? 'opacity-70 cursor-not-allowed' : null} border rounded text-sm w-fit px-5 mb-0`}>
                {SavingPaymentDetails ?
                    <ReactLoading type="spin" color="#0052ff" className="mx-auto text-xl " height={16} width={16} /> : 'Opslaan'}
            </button>

        </div>
    )
}

export default PaymentInfo