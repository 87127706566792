import { useState, useEffect } from 'react'
import { DeleteItemFromCart, RetrieveCart } from '../services/CartService'
import { Link, useNavigate } from "react-router-dom";
import { CreateOrder } from '../services/CreateOrder';
const API_IMAGE_URL = 'http://localhost:7584'

function CheckoutPage() {

    const navigate = useNavigate()
    const [Cart, SetCart] = useState(null)




    const CartItem = ({ Size, ProductSKU, ProductName, id }) => {
        return (
            <div className="">
                <div class=" border-t border-b  py-5   w-full px-5 mx-auto mt-5 gap-6 flex items-center ">
                    <div class="">
                        <div class="flex items-center space-x-3">
                            <img class=" h-[48px]" src={`${API_IMAGE_URL}/image/product/${ProductSKU}`} />
                            <div class="flex flex-col">
                                <span class="">{ProductName}</span>
                                <div class="flex gap-3 mt-2">
                                    <span class="text-black rounded-sm bg-[#f4f3f1] p-1 text-xs">EU {Size}</span>
                                    <span class="text-black rounded-sm bg-[#f4f3f1] p-1 text-xs">{ProductSKU}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="">
                    <div class="flex flex-col">
                        <span class=" text-sm">€322.00</span>
                    </div>
                </div> */}

                    <div class="">
                        <div class=" flex gap-3 ">
                            <button onClick={() => {
                                HandleRemoveItem(id)
                            }} class="px-3 py-2 border rounded text-sm ">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z">
                                    </path>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const HandleRemoveItem = (ItemID) => {
        DeleteItemFromCart(ItemID).then(response => {
            
            if (response == 204) {
                RetrieveCart().then(response => {
                    
                    if (response.cart[0].items.length > 0) {

                        SetCart(response.cart[0])
                    }
                    else {
                        navigate('/')
                    }
                    //expected:
                    // {
                    //     "_id": "62ef1018aed200c4ca3ea7a7",
                    //     "userId": "62ec52c027e061ea535a36cf",
                    //     "items": [
                    //         {
                    //            s
                    //     ],
                    //     "createdAt": "2022-08-07T01:06:32.694Z",
                    //     "updatedAt": "2022-08-07T01:12:59.047Z",
                    //     "__v": 5
                    // }

                })
            }
        })
    }
    const [AddressValues, SetAddressValues] = useState({

    })

    const [PaymentValues, SetPaymentValues] = useState({

    })


    const HandlePlaceOrder = () => {

        
        
        

        PaymentValues.Total = parseFloat(parseFloat(PaymentValues.CartTotal) + 17.50 ).toFixed(2)

        const CheckoutData = {
            Cart,
            Address:AddressValues,
            Payment:PaymentValues
        }

        // 
        CreateOrder(CheckoutData).then(response =>{
            
            window.location.href= response.RedirectUrl
        })
    }

    useEffect(() => {
        RetrieveCart().then(response => {
            if (response.cart_found) {
                SetCart(response.cart[0])
                SetPaymentValues({ ...PaymentValues, ["CartTotal"]: response.cart[0].items[0].StorePrice })



            }
            else {
                navigate('/')
            }
        })
    }, [])

    const HandleAddressChange = (event) => {
        const Key = event.target.name
        const Value = event.target.value
        SetAddressValues({ ...AddressValues, [Key]: Value})
    }
    const HandlePaymentChange = (event) => {

        const Value = event.target.getAttribute('name')
        SetPaymentValues({ ...PaymentValues, ["PaymentMethod"]: Value })


    }

    const PaymentMethods= [
        {
            name:'Credit Card',
            id:0,
            PaymentMethod:'creditcard'

        },
        {
            name:'Klarna',
            id:1,
            PaymentMethod:'KlarnaAfterPay'

        },
        {
            name:'Paypal',
            id:2,
            PaymentMethod:'Paypal'

        },
        {
            name:'Apple Pay',
            id:3,
            PaymentMethod:'applepay'

        },
        {
            name:'Ideal',
            id:4,
            PaymentMethod:'ideal'

        },
        {
            name:'Bancontact',
            id:5,
            PaymentMethod:'Bancontact'

        }
    ]


    const PaymentMethodItem = ({id , name , PaymentMethodName , }) =>{

        const ImageURL = `${API_IMAGE_URL}/brands/payments/${String(name).toLowerCase()}`
        
        return(
            <div onClick={(event) => {
                HandlePaymentChange(event)
            }} name={PaymentMethodName} className={`border px-3  hover:bg-[#fafafa] ${PaymentValues?.PaymentMethod ==PaymentMethodName ? 'border-[#0052ff]' : null }   cursor-pointer w-full rounded-lg py-1 flex gap-2 items-center`}>
                <img className="h-8 pointer-events-none" src={ImageURL} />
                <span className=' pointer-events-none text-xs ml-2 mr-4'>
                    {name}
                </span>
            </div>
        )
    }

    return (
        <div className="grid  xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 w-full flex-shrink-0 mt-1 mb-5 p-4 px-10  gap-3">

            <div className=" p-3 flex-col flex ">
                <span className='border-b pb-3 mb-8'>
                    Persoonlijke Gegevens
                </span>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="fname"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Voornaam

                        </span>

                    </div>
                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="lname"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Achternaam

                        </span>

                    </div>
                </div>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-full rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="email"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            E-mail

                        </span>

                    </div>

                </div>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-full rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="phone"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Telefoon

                        </span>

                    </div>

                </div>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-full rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="address"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Adres

                        </span>

                    </div>

                </div>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-full rounded-lg flex-col relative justify-center ">
                        <select type={'text'} id="test" name="country"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required>
                            <option defaultChecked className=''>
                                Selecteer Land
                            </option>
                            <option defaultChecked value={"NL"} className=''>
                                Nederland
                            </option>
                            <option defaultChecked value={"BE"} className=''>
                                België
                            </option>
                        </select>
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Adres

                        </span>

                    </div>

                </div>
                <div className="mb-8 gap-3 flex w-full">
                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="zip"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Postcode

                        </span>

                    </div>
                    <div className="flex h-[50px] w-1/2 rounded-lg flex-col relative justify-center ">
                        <input type={'text'} id="test" name="city"
                            onChange={(e) =>
                                HandleAddressChange(e)
                                // SetPrice(e.target.value)
                            } className="w-full p-3 text-sm py-3 input-field border rounded-md" placeholder="" required />
                        <span className=" input-label absolute pl-3 transition-all delay-150   text-sm font-light pointer-events-none text-[#949494]">
                            Stad

                        </span>

                    </div>
                </div>
            </div>
            <div className=" p-3 flex-col flex ">
                <span className='border-b pb-3 mb-8'>
                    Betaalwijze
                </span>
                <div className="grid grid-cols-2 w-fit  gap-3">
                {PaymentMethods.map((PaymentItem , index) =>{
                    
                   return(
                        <PaymentMethodItem id = {PaymentItem.id} name={PaymentItem.name} PaymentMethodName ={PaymentItem.PaymentMethod} />
                   )
                })}
            


                </div>
            </div>

            <div className=" p-3 flex-col flex border rounded ">
                <span className='border-b pb-3 w-full'>
                    Bestelling Overzicht
                </span>
                {Cart?.items.map((item) => {
                    
                    return (
                        <CartItem id={item._id} Size={item.Size} ProductSKU={item.ProductSKU} ProductName={item.ProductName} />
                    )
                })}
       
                <div class=" pb-0 flex-col   py-5  w-full px-5 mx-auto mt-3 gap-6 flex items-center h-full ">

                    <span>
                        Overzicht
                    </span>
                    <div className="flex flex-col gap-4 w-full h-full border-b">
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Subtotaal
                            </span>
                            <span className="text-sm">
                                €{Cart?.items[0].StorePrice}
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Verwerkingskosten
                            </span>
                            <span className="text-sm">
                                €10
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Verzendkosten
                            </span>
                            <span className="text-sm">
                                €7.50
                            </span>
                        </div>
                        <div className="flex  border-t pt-5 justify-between w-full pb-5">
                            <span className="text-sm font-medium">
                                Totaal
                            </span>
                            <span className="text-sm">
                                €{parseFloat(parseFloat(Cart?.items[0].StorePrice) + 17.5).toFixed(2)}
                            </span>
                        </div>

                    </div>

                    <button onClick={() => {
                        HandlePlaceOrder()
                    }} className=' relative text-center leading-4 text-sm bg-[#0052ff]  font-medium w-full m-0 mt-auto rounded text-white px-3 py-2.5 border  border-[#0052ff] '>
                        Bestelling Plaatsen
                    </button>
                </div>
            </div>

        </div>
    )
}



export default CheckoutPage