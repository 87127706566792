

import axios from "axios";
const API_URL = 'http://localhost:8080/api'

export const handleSubmitRegister = async (event , values) => {
  event.preventDefault();
  try {
      const { data } = await axios.post(
          `${API_URL}/register`,
          {
              ...values,
          },
          { withCredentials: true }
      );
      if (data) {
          if (data.errors) {
              const { email, password } = data.errors;
              if (email) alert(email);
              else if (password) alert(password);
          } else {
             return data
          }
      }
  } catch (ex) {
      
  }
};

export const handleSubmitLogin = async (event , values) => {
  event.preventDefault();
  try {
      const { data } = await axios.post(
        `${API_URL}/login`,
          {
              ...values,
          },
          { withCredentials: true }
      );
      if (data) {
          if (data.errors) {
              const { email, password } = data.errors;
              if (email) alert(email);
              else if (password) alert(password);
          } else {
          
              return data
          }
      }
  } catch (ex) {
      
  }
};



// export default {handleSubmitRegister , handleSubmitLogin}

