

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchAddress(){
    return new Promise((resolve , reject) =>{
        fetch(`${API_MAIN_URL}/account/address` , {
            credentials:"include",
        }).then(resp => resp.json()).then((Account) =>{
            // 
            resolve(Account)
        })
    })

}

export default FetchAddress