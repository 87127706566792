
const API_MAIN_URL = "http://localhost:8080/api"

function FetchListingsBySKU(ProductSKU){
    return new Promise((resolve , reject) =>{
       
        fetch(`${API_MAIN_URL}/product/listings/?search=${ProductSKU}` , {
            credentials:'include'
        }).then(resp => resp.json()).then((FoundListings) =>{
            // 
            resolve(FoundListings)
        })
    })


}



export default FetchListingsBySKU