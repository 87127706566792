

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchBids(query){
    return new Promise((resolve , reject) =>{
       
        fetch(`${API_MAIN_URL}/bids?query=${query}` , {
            credentials:'include'
        }).then(resp => resp.json()).then((FoundBids) =>{
            // 
            resolve(FoundBids)
        })
    })

}

export default FetchBids