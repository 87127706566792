

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchListings(PageIndex=0){
    return new Promise((resolve , reject) =>{
        console.log(PageIndex)
        fetch(`${API_MAIN_URL}/listings?page=${PageIndex + 1}` , {
            credentials:'include'
        }).then(resp => resp.json()).then((FoundListings) =>{
            // 
            resolve(FoundListings)
        })
    })

}

export default FetchListings