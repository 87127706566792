
const API_MAIN_URL = "http://localhost:8080/api"

function FetchProductDetailsBySKU(ProductSKU){
    return new Promise((resolve , reject) =>{
       
        fetch(`${API_MAIN_URL}/product/info/${ProductSKU}` , {
            credentials:'include'
        }).then(resp => resp.json()).then((ProductInfo) =>{
            // 
            resolve(ProductInfo)
        })
    })


}



export default FetchProductDetailsBySKU