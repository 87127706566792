import axios from "axios"
import { useEffect, useState } from "react"
import { FaArrowRight } from "react-icons/fa"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import FetchPopulairSneakers from "../services/FetchPopulairSneakers"
import { IoIosArrowRoundForward } from 'react-icons/io'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import FetchSneakers from "../services/FetchSneakers"




function Home() {


    const ProductRow = ({ RowTitle }) => {


        
        const ProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl, ProductSKU }) => {
            return (
                <Link to={`/product/${ProductSKU}`} className="border rounded shadow-md hover:shadow-lg flex flex-col p-3 min-w-[200px] max-w-[200px] flex-1 cursor-pointer ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={ProductImage} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 ">
                        <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                            {ProductName}
                        </span>


                    </div>
                    <div className="mt-3">
                        <div className={`${LowestPrice != "OOS" ? null : 'line-through'} flex flex-col`}>

                            <span className="text-sm">
                                {LowestPrice != "OOS" ? <>

                                    € {LowestPrice}
                                </> : "Uitverkocht"}

                            </span>
                        </div>
                    </div>



                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </Link>
            )
        }

        const [Data, SetData] = useState([])

        useEffect(() => {
            FetchPopulairSneakers().then(resp => {


                

                SetData(resp)
            })
        }, [])


        useEffect(() => {
            
        }, [Data])

        return (
            <div className={`${Data.length > 0 ? ' block' : 'hidden'} space-y-4  mb-5`}>

                <div className="flex justify-between items-center">
                    <span className="font-medium text-lg">
                        {RowTitle}
                    </span>
                    <span className="font-medium text-sm  flex items-center gap-2 text-[#0052ff]">
                        Bekijk Alles
                        <FaArrowRight />
                    </span>


                </div>

                <div className=" flex md:gap-8 gap-2 overflow-auto">
                    {Data.map((Sneaker, index) => <ProductItem ProductName={Sneaker.ShoeData.name} ProductSKU={Sneaker.ShoeData.sku} ProductImage={Sneaker.ShoeData.image} ProductUrl={Sneaker.ShoeData.slug} LowestPrice={Sneaker.min_val} />)}
                </div>

            </div>
        )
    }


    const Brands = () => {

        const brands = [
            {
                name: 'Yeezy',
                logo: 'yeezy',

            },
            {
                name: 'Adidas',
                logo: 'adidas',

            },
            {
                name: 'Air Jordan',
                logo: 'jordan',

            },
            {
                name: 'Nike',
                logo: 'nike',

            },
            {
                name: 'Nike SB',
                logo: 'nikesb',

            },
            {
                name: 'New Balance',
                logo: 'newbalance',

            },


        ]


        const ProductItem = ({ name, logo }) => {
            return (
                <Link to={`/products/${String(name.split(' ').join('-')).toLocaleLowerCase()}`} className="border rounded shadow-md hover:shadow-lg flex flex-col p-3 min-w-[200px] max-w-[200px] flex-1 cursor-pointer ">

                    {/* Image Section */}
                    <div id="" className="w-[120px] h-[75px] m-auto">
                        <img className=" object-contain " src={logo} />
                    </div>

                    {/* Text Section */}

                    <div className="text-center flex flex-col w-full mt-16 ">
                        <span className="text-sm overflow text-ellipsis font-medium line-clamp-2">
                            {name}
                        </span>


                    </div>




                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </Link>
            )
        }




        return (
            <div className="block space-y-4  mb-5">

                <div className="flex justify-between items-center">
                    <span className="font-medium text-lg">
                        Merken
                    </span>
                    {/* <span className="font-medium text-sm  flex items-center gap-2 text-[#0052ff]">
                        Bekijk Alles
                        <FaArrowRight />
                    </span> */}


                </div>

                <div className={` flex md:gap-8 gap-2 overflow-auto 'justify-left' }`}>
                    {brands?.map((brand) => {
                        return <ProductItem name={brand.name} logo={`http://localhost:7584/brands/${brand.logo}`} />
                    })}

                </div>

            </div>
        )
    }

    const RecentlyViewed = () => {

        const [RecentlyViewed, SetRecentlyViewed] = useState([])

        useEffect(() => {
            const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewedProducts')) || [];
            
            SetRecentlyViewed(recentlyViewedProducts.reverse())
        }, [])



        const ProductItem = ({ ProductName, ProductImage, ProductSKU, LowestPrice, ProductUrl }) => {
            return (
                <Link to={`/product/${ProductSKU}`} className="border rounded shadow-md hover:shadow-lg  flex flex-col p-3 min-w-[200px] max-w-[200px] flex-1 cursor-pointer ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={ProductImage} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 ">
                        <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                            {ProductName}
                        </span>


                    </div>
                    {/* <div className="mt-3">
                        <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
                            SKU
                        </span>
                        <span className="text-xs">
                            {ProductSKU}
                        </span>
                    </div> */}
                    <div className="mt-3 text-left flex justify-between">
                        <div className={`${LowestPrice != "OOS" ? null : 'line-through'} flex flex-col`}>

                            <span className="text-sm">
                                {LowestPrice != "OOS" ? <>

                                    € {LowestPrice}
                                </> : "Uitverkocht"}

                            </span>
                        </div>
                        {/* <div className=" w-[1px] bg-slate-200 opacity-60" /> */}
                        {/* <div className={`${LowestPrice != "OOS" ? null : 'line-through' } flex flex-col`}>
                            <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
                                SKU
                            </span>
                            <span className="text-xs">
                                {ProductSKU}
                            </span>
                        </div> */}
                    </div>


                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </Link>
            )
        }




        return (
            <div className="block space-y-4  mb-5">

                <div className="flex justify-between items-center">
                    <span className="font-medium text-lg">
                        Laatst Bekeken
                    </span>
                    {/* <span className="font-medium text-sm  flex items-center gap-2 text-[#0052ff]">
                        Bekijk Alles
                        <FaArrowRight />
                    </span> */}


                </div>

                <div className={` flex md:gap-8 gap-2 overflow-auto 'justify-left' }`}>
                    {RecentlyViewed?.map((Sneaker, index) => {
                        
                        if (Sneaker.name != undefined) {
                            return <ProductItem ProductName={Sneaker.name} ProductImage={`http://localhost:7584/image/product/${Sneaker.sku}`} ProductUrl={Sneaker.slug} ProductSKU={Sneaker.sku} LowestPrice={Sneaker.storeprice} />
                        }
                    })}
                </div>

            </div>
        )
    }


    const SearchSection = () => {


        const [SearchQuery, SetSearchQuery] = useState('')
        const [FoundSneakersBasedOnQuery, SetFoundSneakersBasedOnQuery] = useState([])



        useEffect(() => {


            FetchSneakers(SearchQuery).then(FoundSneakers => {
                
                SetFoundSneakersBasedOnQuery(FoundSneakers)
            })


        }, [SearchQuery])

        const ProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
            return (
                <Link to={`product/${Productsku}`} className="border flex flex-col p-3  min-w-[100%]  flex-1 cursor-pointer mx-auto ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={ProductImage} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 ">
                        <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                            {ProductName}
                        </span>


                    </div>
                    <div className="mt-3 text-left flexn">

                        <div className=" w-[1px] bg-slate-200 opacity-60" />
                        <div className={`${LowestPrice != "OOS" ? null : 'line-through'} flex flex-col`}>

                            <span className="text-sm">
                                {LowestPrice != "OOS" ? <>

                                    € {LowestPrice}
                                </> : "Uitverkocht"}

                            </span>
                        </div>
                    </div>



                    {/* <div className="w-[140px] h-[75px]">
                            <img  src={ProductImage}  />
                        </div>
                       <span className="text-sm">
                        {ProductName}
                       </span> */}
                </Link>
            )
        }

        return (
            <div className=" py-4 text-center md:flex flex-col w-full hidden  ">
                <div className="mx-auto flex flex-col w-full md:w-2/3 space-y-3">
                    <span className="font-medium text-xl ">
                        Koop en verkoop authentieke op BackdoorsVercel!
                    </span>
                    <input onChange={(e) => SetSearchQuery(e.target.value)} className="border p-2 rounded-md text-sm" placeholder="Zoeken..." />
                </div>
                <div className={`w-full mx-auto ${SearchQuery.length > 0 ? 'flex' : "hidden"} p-5 border border-t mt-2 rounded-sm h-fit flex flex-col`}>
                    Zoek Resultaten voor:  {SearchQuery}

                    <div className="grid  grid-cols-2  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-6 mt-5 justify-center gap-4 ">
                        {FoundSneakersBasedOnQuery.map((sneaker, index) => <ProductItem ProductName={sneaker.name} ProductImage={`http://localhost:7584/image/product/${sneaker.sku}`} ProductUrl={sneaker.slug} Productsku={sneaker.sku} LowestPrice={sneaker.storeprice} />)}
                    </div>
                </div>
            </div>
        )
    }

    // const BrandsRow = ({BrandName , BrandLogo }) => {
    //     return(

    //     )
    // }

    return (
        <div className="flex flex-col w-full flex-shrink-0 mt-1 mb-5 p-4 py-4 px-10 space-y-4">
            {/* todo: make carsouel or header item (with some trending items) */}

            <SearchSection />
            <div className="md:hidden">
                <OwlCarousel className="owl-theme rounded " items={1} dots loop >
                    <div style={{ 'backgroundImage': 'url(https://cms-cdn.thesolesupplier.co.uk/2022/04/ucla1_w1160.jpg)' }} class='item  bg-red-900 h-[500px] rounded flex bg-no-repeat bg-cover bg-center top-0 relative'>
                        <div style={{ 'background': 'rgba(0, 0, 0, 0.42)' }} className=" z-[1000] w-full h-full absolute top-0 left-0 flex flex-col">
                            <div className="flex flex-col gap-5 my-auto ml-10">
                                <span className="my-auto text-3xl text-white font-bold">
                                    Nike Dunk Low UCLA
                                </span>
                                <span className="my-auto text-xl underline cursor-pointer text-white font-medium">
                                    Shop nu
                                </span>
                            </div>
                        </div>
                        {/* <img  src="https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2022%2F08%2Fadidas-yeezy-slide-flax-fz5896-release-info-2.jpg?q=75&w=800&cbr=1&fit=max"/> */}
                    </div>
                    <div style={{ 'backgroundImage': 'url(https://static.sneakerjagers.com/news/nl/2020/06/ured.uit_.jpg)' }} class='item  rounded bg-red-900 h-[500px] flex bg-no-repeat bg-cover bg-center top-0 relative'>
                        <div style={{ 'background': 'rgba(0, 0, 0, 0.42)' }} className=" z-[1000] w-full h-full absolute top-0 left-0 flex flex-col">
                            <div className="flex flex-col gap-5 my-auto ml-10">
                                <span className="my-auto text-3xl text-white font-bold">
                                    Nike Dunk Low Next nature Gym red White
                                </span>
                                <span className="my-auto text-xl underline cursor-pointer text-white font-medium">
                                    Shop nu
                                </span>
                            </div>
                        </div>
                        {/* <img  src="https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2022%2F08%2Fadidas-yeezy-slide-flax-fz5896-release-info-2.jpg?q=75&w=800&cbr=1&fit=max"/> */}
                    </div>
                    <div style={{ 'backgroundImage': 'url(https://images.squarespace-cdn.com/content/v1/55e467b1e4b0a2a709a23aa9/1627249215229-ROR7ZEA9CCOWWE5IXNCC/CNK-Nike-WMNS-Dunk-High-Panda.jpeg)' }} class='item  !rounded bg-red-900 h-[500px] flex bg-no-repeat bg-cover bg-center top-0 relative'>
                        <div style={{ 'background': 'rgba(0, 0, 0, 0.42)' }} className=" z-[1000] w-full h-full absolute top-0 left-0 flex flex-col">
                            <div className="flex flex-col gap-5 my-auto ml-10">
                                <span className="my-auto text-3xl text-white font-bold">
                                    Nike Dunk High Black White (W)
                                </span>
                                <span className="my-auto text-xl underline cursor-pointer text-white font-medium">
                                    Shop nu
                                </span>
                            </div>
                        </div>
                        {/* <img  src="https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2022%2F08%2Fadidas-yeezy-slide-flax-fz5896-release-info-2.jpg?q=75&w=800&cbr=1&fit=max"/> */}
                    </div>

                </OwlCarousel>
            </div>

            {/* <div className=" relative">
                <div  className="relative overflow-hidden">
                    <div className="">
                        <div style={{ backgroundImage: `url(https://img.hypeboost.com/assets/banners/2-nike-dunk-low-ucla.jpg)`, 'backgroundPosition': 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className=" min-h-[500px] mt-1 bg-red-50  relative">
                            <div style={{ 'background': 'rgba(0, 0, 0, 0.4)' }} className="absolute top-0 left-0 z-50 w-full h-full flex">
                                <div className="flex flex-col my-auto ml-10 gap-2">
                                    <span className="text-white text-4xl font-semibold">
                                        Nike Dunk Low Ucla
                                    </span>
                                    <div className="w-fit cursor-pointer " to={""}>
                                        <span className="flex gap-2  text-white text-xl font-light items-center">
                                            Shop nu
                                            <IoIosArrowRoundForward className="mt-1" />
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(https://img.hypeboost.com/assets/banners/2-nike-dunk-low-ucla.jpg)`, 'backgroundPosition': 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className=" min-h-[500px] mt-1 bg-red-50  relative">
                            <div style={{ 'background': 'rgba(0, 0, 0, 0.4)' }} className="absolute top-0 left-0 z-50 w-full h-full flex">
                                <div className="flex flex-col my-auto ml-10 gap-2">
                                    <span className="text-white text-4xl font-semibold">
                                        Nike Dunk Low Ucla
                                    </span>
                                    <div className="w-fit cursor-pointer " to={""}>
                                        <span className="flex gap-2  text-white text-xl font-light items-center">
                                            Shop nu
                                            <IoIosArrowRoundForward className="mt-1" />
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(https://img.hypeboost.com/assets/banners/2-nike-dunk-low-ucla.jpg)`, 'backgroundPosition': 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className=" min-h-[500px] mt-1 bg-red-50  relative">
                            <div style={{ 'background': 'rgba(0, 0, 0, 0.4)' }} className="absolute top-0 left-0 z-50 w-full h-full flex">
                                <div className="flex flex-col my-auto ml-10 gap-2">
                                    <span className="text-white text-4xl font-semibold">
                                        Nike Dunk Low Ucla
                                    </span>
                                    <div className="w-fit cursor-pointer " to={""}>
                                        <span className="flex gap-2  text-white text-xl font-light items-center">
                                            Shop nu
                                            <IoIosArrowRoundForward className="mt-1" />
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <Brands />
            <RecentlyViewed />
            <ProductRow RowTitle={"Meest Populair"} />


            <Footer />

        </div>
    )
}


export default Home