

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchSneakersByBrand(query , Page=1 ,  limit=40 ){
    return new Promise((resolve , reject) =>{
        
        fetch(`${API_MAIN_URL}/sneakers?search=${query}&page=${Page}`).then(resp => resp.json()).then((FoundSneakers) =>{
            // 
            resolve(FoundSneakers)
        })
    })

}

export default FetchSneakersByBrand