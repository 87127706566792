
import { useEffect, useState } from 'react'
import React from 'react';
import AccountInfo from './components/AccountInfo';
import AdressInfo from './components/AddressInfo';
import PaymentInfo from './components/PaymentInfo';
import SellerInfo from './components/SellerInfo';




function ProfleComponent() {


    const [SubCategory, SetSubCategory] = useState("account_info")

    const [Forms, SetForms] = useState({
        Account: {
            "Fname": "",
            "Lname": "",
            "Email": ""
        },
        Address: {
            "Fname": "",
            "Lname": "",
            "Email": "",
            "Phone": "",
            "Address": "",
            "Country": "",
            "City": "",
            "Zip": "",

        },
        PaymentMethod: {
            "FullName": "",
            "Email": ""
        },
    })


    useEffect(() => {
        
    }, [])


    const HandleSubCategoryChange = (e) =>{
        SetSubCategory(e.target.id)
    }

    return (
        <div className="flex flex-col p-2 gap-4">
            <div className="border-b pb-2 mb-5 flex gap-10 overflow-auto ">
                <div onClick={(e) => {
                    HandleSubCategoryChange(e)
                }} id='account_info' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'account_info' ? 'opacity-60' : null}`}>


                    <span className=" pointer-events-none" >
                        Accountgegevens
                    </span>

                </div>
                <div onClick={(e) => {
                    HandleSubCategoryChange(e)
                }} id='address_info' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'address_info' ? 'opacity-60' : null} `}>


                    <span className=" pointer-events-none " >
                        Adresgegevens
                    </span>

                </div>
                <div onClick={(e) => {
                    HandleSubCategoryChange(e)
                }} id='payment_info' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'payment_info' ? 'opacity-60' : null} `}>


                    <span className=" pointer-events-none " >
                        Betaalgegevens
                    </span>

                </div>
                <div onClick={(e) => {
                    HandleSubCategoryChange(e)
                }} id='seller_info' className={`flex flex-row gap-2 cursor-pointer select-none w-fit items-center ${SubCategory != 'seller_info' ? 'opacity-60' : null} `}>


                    <span className=" pointer-events-none " >
                        Verkoopgegevens
                    </span>

                </div>
            </div>
            {(() => {

                if (SubCategory == 'account_info') {
                    return (
                        <AccountInfo/>
                    )
                }
                else if (SubCategory == 'address_info') {
                    return (
                    <AdressInfo/>
                    )
                }
                else if (SubCategory == 'payment_info') {
                    return (
                        <PaymentInfo/>
                       
                    )
                }
                else if (SubCategory == 'seller_info') {
                    return (

                        <SellerInfo/>
                    )
                }
            })()}





        </div>
    )



}



export default ProfleComponent