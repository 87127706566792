import { useEffect, useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import FetchListingsBySKU from "../services/FetchListingsBySKU";
import FetchSizesBasedOnBrandId from "../services/FetchSizesBasedOnBrand";
import FetchSneakerBySKU from "../services/FetchSpecificSneaker";
import { FcShipped, FcUpLeft, FcInTransit, FcApproval, FcLeftDown } from 'react-icons/fc'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AddToCart, RetrieveCart } from "../services/CartService";
import ReactLoading from 'react-loading';

import { useGlobalState } from "../state/state";
import Footer from "../components/Footer";



const IMAGE_API_URL = 'http://localhost:7584'

function ProductPage({ route, navigation }) {

    const navigate = useNavigate();
    const { ProductSKU } = useParams()
    const [FetchingShoeData, SetFetchingShoeData] = useState(false)
    const [Loading, SetLoading] = useState(false)
    const [Relatedproducts, SetRelatedproducts] = useState([])
    const [ShoeData, SetShoeData] = useState({})
    const [SelectedSize, SetSelectedSize] = useState(null)
    const [CanOrderListing, SetCanOrderListing] = useState(false)
    const [Cart, SetCart] = useGlobalState('CartCreated');

    const Breadcrumb = () => {


        const Paths = [
            {
                name: "Home",
                path: "/"
            },
            {
                name: "Products",
                path: "/products/all"
            },
            {
                name: ProductSKU,
                path: `/product/${ProductSKU}`
            }
        ]


        const BreadcrumbItem = ({ name, path }) => {
            return (
                <Link to={path}>
                    <div className="flex gap-2 ">
                        <span className="text-xs opacity-70">
                            {name}
                        </span>
                        {name != ProductSKU ?
                            <span className="text-xs opacity-70">
                                /
                            </span>
                            : null}
                    </div>
                </Link>

            )
        }

        return (
            <div className="flex gap-3 mb-10">
                {Paths.map((PathItem) => <BreadcrumbItem name={PathItem.name} path={PathItem.path} />)}
            </div>
        )
    }

    const RelatedProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
        return (
            <Link to={`/product/${Productsku}`} className="border flex flex-col p-3  w-fit flex-1 cursor-pointer mx-auto ">

                {/* Image Section */}
                <div id="" className="w-[140px] h-[75px] m-auto">
                    <img className=" object-contain " src={`${IMAGE_API_URL}/image/product/${Productsku}`} />
                </div>

                {/* Text Section */}

                <div className="text-left flex flex-col w-full mt-8 ">
                    <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                        {ProductName}
                    </span>


                </div>
                <div className="mt-3 text-left flex justify-between">
                    <div className={`${LowestPrice != "OOS" ? null : 'line-through'} flex flex-col`}>

                        <span className="text-sm">
                            {LowestPrice != "OOS" ? <>

                                € {LowestPrice}
                            </> : "Uitverkocht"}

                        </span>
                    </div>
                    {/* <div className=" w-[1px] bg-slate-200 opacity-60" /> */}
                    {/* <div className={`${LowestPrice != "OOS" ? null : 'line-through' } flex flex-col`}>
                            <span className="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">
                                SKU
                            </span>
                            <span className="text-xs">
                                {ProductSKU}
                            </span>
                        </div> */}
                </div>



                {/* <div className="w-[140px] h-[75px]">
                        <img  src={ProductImage}  />
                    </div>
                   <span className="text-sm">
                    {ProductName}
                   </span> */}
            </Link>
        )
    }
    const ReleatedProducts = () => {
        // 
        return (
            <div className="flex-col flex gap-2 mb-10">
                <span className="font-medium text-xl">
                    Gerelateerde producten

                </span>
                <div className="flex gap-2 overflow-scroll ">
                    {Relatedproducts.map((sneaker, index) => <RelatedProductItem ProductName={sneaker.name} ProductImage={sneaker.image} ProductUrl={sneaker.slug} Productsku={sneaker.sku} LowestPrice={sneaker.storeprice} />)}
                </div>
            </div>
        )
    }

    const HandleOrderItem = () => {
        SetLoading(true)


        const SizeInfo = ShoeData.Sizes.find(function (e) {
            return e.Size == SelectedSize;
        });


        const OrderItemBody = {
            ProductID: ShoeData._id,
            ProductName: ShoeData.name,
            Productsku: ShoeData.sku,
            Size: SizeInfo
        }

        AddToCart(OrderItemBody).then(AddToCartResponse => {
            if (AddToCartResponse.items[0].ListingID == SizeInfo.Id) {
                SetCart(AddToCartResponse.items)
                navigate('/account/cart/')
            }
        })

    }

    useEffect(() => {


        SetFetchingShoeData(true)
        FetchSneakerBySKU(ProductSKU).then(result => {

            if (result.error) {
                navigate('/not-found')
            }
            if (result.relatedproducts.length > 0) {
                SetRelatedproducts(result.relatedproducts)
            }



            if (result.sneakers.length > 0) {



                // result[0].image = String(result[0].image).replace('-1-' , '1000')
                result.sneakers[0].image = String(result.sneakers[0].image).replace('400.png', '1000.png')
                FetchSizesBasedOnBrandId(result.sneakers[0]).then(Sizes => {
                    // 
                    // ShoeData.Sizes = Object.keys(Sizes)

                    FetchListingsBySKU(ProductSKU).then(Listings => {
                        // var temp_ShoeData = ShoeData

                        var SizesWithListings = Listings.Listings

                        var AllSizes = Sizes



                        let new_array = []
                        AllSizes.forEach(element => {


                            element = element.replace(".5", ' ½').replace(' 1/3', ' ⅓').replace(' 2/3', ' ⅔')
                            // 
                            // 



                            if (SizesWithListings[element] != undefined) {

                                new_array.push({
                                    Size: SizesWithListings[element].Size,
                                    Price: SizesWithListings[element].Price,
                                    Id: SizesWithListings[element]._id
                                })
                            }
                            else {

                                new_array.push({
                                    Size: element,
                                    Price: "OOS",

                                })
                            }
                        });

                        // temp_ShoeData.Sizes = new_array



                        SetShoeData({ ...result.sneakers[0], Sizes: new_array })
                        SetFetchingShoeData(false)




                    })




                })

            }


        })



    }, [ProductSKU]);


    useEffect(() => {

        const recentlyViewedProducts = localStorage.getItem('recentlyViewedProducts');
        if (recentlyViewedProducts == null || recentlyViewedProducts == undefined) {

            let arr = []
            arr.push(ShoeData)
            localStorage.setItem('recentlyViewedProducts', JSON.stringify(arr));
            return
        }


        var JSON_recentlyViewedProducts = JSON.parse(recentlyViewedProducts)
        const InArray = JSON_recentlyViewedProducts.some((Product) => {
            return Product._id == ShoeData._id
        })
        if (!InArray) {
            JSON_recentlyViewedProducts.push(ShoeData)
            localStorage.setItem('recentlyViewedProducts', JSON.stringify(JSON_recentlyViewedProducts));
            return
        }






        // if(recentlyViewedProducts.length == 0){
        //     
        // }

        // if(recentlyViewedProducts == undefined){
        //     // creating new array
        //     
        //     let temp_arr = [].push(ShoeData)
        //     
        //     localStorage.setItem('recentlyViewedProducts' , temp_arr)
        // }
        // else{
        //     // 
        //     // let temp_arr = [].push(ShoeData)
        //     // 
        //     // localStorage.setItem('recentlyViewedProducts' , temp_arr)
        // }

        // localStorage.setItem('RecentlyWatchedSneakers', data);

    }, [ShoeData])




    return (
        <div className="flex flex-col w-full flex-shrink-0 mt-1 mb-5 p-4 px-10">
            {FetchingShoeData ? <div className="w-full h-full absolute   left-0 top-0">
                <div>
                    <ReactLoading color="#0052ff" type="spin" className="top-1/2 left-1/2 -ml-[55px]  absolute" height={55} width={55} />
                </div>
            </div> : <>
                <Breadcrumb />

                <span className="text-2xl font-semibold">
                    {ShoeData.name}

                </span>
                {/* <span className="text-xs mt-1 underline underline-offset-2">
    Nike Dunk Low
</span> */}

                <div className="flex xl:flex-row  lg:flex-row  md:flex-col sm:flex-col flex-col mt-2  mb-10 gap-3">
                    <div className="flex justify-left w-full h-full   ">
                        <img className=" mx-auto    w-full rounded" src={`http://localhost:7584/image/product/${ShoeData.sku}` || ''} />


                    </div>
                    <div className="w-full flex flex-col">
                        <div className="grid  w-full xl:grid-cols-8 lg:grid-cols-6  md:grid-cols-8 sm:grid-cols-5 grid-cols-4  gap-3  mb-3">

                            {ShoeData.Sizes?.map(SizeInfo => {
                                const Size = SizeInfo.Size
                                const Price = SizeInfo.Price
                                return (
                                    <div onClick={() => {
                                        if (Price != 'OOS') {
                                            SetCanOrderListing(true)
                                        }
                                        else {
                                            SetCanOrderListing(false)
                                        }

                                        SetSelectedSize(Size)
                                    }} className={` flex h-fit w-[70px] ${Size == SelectedSize ? "bg-[#0052ff] border-[#0052ff] " : 'bg-transparent '} px-3 py-3 rounded-sm cursor-pointer flex-col text-center border`}>
                                        <span className={`font-semibold text-sm ${Size == SelectedSize ? "text-white" : 'text-black'}`}  >
                                            <span className={`${Price == "OOS" ? "line-through" : null}`} >{Size}</span>
                                        </span>
                                        <span className={`text-xs ${Size == SelectedSize ? "text-gray-200" : 'text-gray-500'}`}>
                                            {Price != 'OOS' ? <span>€</span> : null}
                                            <span>{Price}</span>
                                        </span>
                                    </div>
                                )
                            })}

                        </div>

                        <div className="flex flex-col gap-4  py-2 mb-10 mt-10">
                            <div className="flex gap-2 items-center">
                                <FcShipped size={22} />
                                <span className="text-xs">
                                    <span className="font-medium text-xs">Levertijd: </span>
                                    X werdagen
                                </span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <FcApproval size={22} />
                                <span className="text-xs">
                                    <span className="font-medium  text-xs">100% Authentiek: </span>
                                    Wij garanderen 100% Authentieke Sneakers
                                </span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <FcUpLeft size={22} />
                                <span className="text-xs">
                                    <span className="font-medium  text-xs">Gratis retourneren: </span>
                                    Binnen 30 dagen na ontvangst kan je de producten gemakklijk terugsturen
                                </span>
                            </div>
                        </div>
                        {/* {alert(CanOrderListing)} */}
                        <div className="flex gap-5 mt-auto ">
                            <button disabled={SelectedSize == null || CanOrderListing != true || Loading} onClick={() => {
                                HandleOrderItem()
                            }} className={`relative leading-4 text-sm bg-[#0052ff] border-[#0052ff] ${Loading ? 'cursor-wait' : null} ${SelectedSize == null || CanOrderListing != true ? 'bg-[#777777] border-[#777777] cursor-not-allowed ' : null}  font-medium  m-0 rounded text-white px-3 py-2.5 border   w-full`}>

                                {Loading ? <ReactLoading type="spin" className="mx-auto" height={16} width={16} /> : "Nu Bestellen"}
                            </button>
                            <button disabled={SelectedSize == null} onClick={() => {
                                navigate(`/product/bids/${ProductSKU}?size=${SelectedSize}`)
                            }} className={`${SelectedSize == null ? 'opacity-60 cursor-not-allowed' : null} relative leading-4 text-sm  font-medium  m-0 rounded text-black px-3 py-2.5 border w-full`}>
                                Bieding plaatsen
                            </button>

                        </div>
                        <div className="flex gap-5  mt-2">
                            <button onClick={() => {
                                navigate(`/sell/${ProductSKU}`, { state: ShoeData });
                            }} className=" relative leading-4 text-sm  font-medium  m-0 rounded text-black px-3 py-2.5 border w-full">
                                Verkopen
                            </button>
                        </div>
                    </div>

                </div>

                <ReleatedProducts />

                <Footer />
            </>}
        </div>
    )
}


export default ProductPage