

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchSneakerBySKU(SKU){
    return new Promise((resolve , reject) =>{
        // 
        fetch(`${API_MAIN_URL}/sneakers?search=${SKU}&limit=10&relatedproducts=1`).then(resp => resp.json()).then((FoundSneakers) =>{
            // 
            resolve(FoundSneakers)
        }).catch((err) =>[
            resolve({
                error:true
            })
        ])
    })

}

export default FetchSneakerBySKU