import axios from "axios";
const API_URL = 'http://localhost:8080/api'

export const CreateOrder = async (values) => {
    
  try {
      const { data } = await axios.post(
          `${API_URL}/order/create`,
          {
              ...values,
          },
          { withCredentials: true }
      );
      if (data) {
          if (data.errors) {
            
          } else {
            
             return data
          }
      }
  } catch (ex) {
      
  }
};