import { useEffect, useState } from "react"
import { UpdatePaymentMethod, UpdateAccount, UpdateAddress } from "../../../services/AccountService"

import FetchAddress from "../../../services/FetchAccountAddress"
import ReactLoading from 'react-loading'






function AdressInfo() {

    const [AddressInfoForm, SetAddressInfoForm] = useState(() => {
        FetchAddress().then(response => {

            SetAddressInfoForm(response.Account.Address)
        })
    })

    const [AddressInfoFormValid, SetAddressInfoFormValid] = useState(false)

    const [SavingAddressDetails, SetSavingAddressDetails] = useState(false)

    const OnChangeAddressForm = async (e) => {
        // 
        // 
        let valid;
        if (e.target.name != 'zip') {
            valid = ValidateValueBasedOnName(e.target.name, e.target.value, AddressInfoForm?.country)
        }
        else {
            valid = true
        }


        SetAddressInfoForm({ ...AddressInfoForm, [e.target.name]: e.target.value })

        const value = e.target.value

        // SetAddressInfoFormValid(valid)



        return { valid, value }


    }

    const ValidateValueBasedOnName = (name, value, country) => {
        // 
        switch (name) {
            case "fname":
                var regex = /^[a-z ,.'-]+$/i
                var Valid = regex.test(value)
                // 
                return Valid

                break;
            case "lname":
                var regex = /^[a-z ,.'-]+$/i
                var Valid = regex.test(value)
                // 
                return Valid

                break;

            case "email":
                var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                var Valid = regex.test(value)
                // 
                return Valid
                break

            case "phone":
                var regex = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d+)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
                var Valid = regex.test(value)
                // 
                return Valid
                break
            case "address":
                var regex = /^\s*\S+(?:\s+\S+){2}/
                var Valid = regex.test(value)
                // 
                return Valid
                break
            case "zip":

                const ZipCodeFormat = {
                    "AD": /^(?:AD)*(\d{3})$/,
                    "AM": /^(\d{6})$/,
                    "AR": /^([A-Z]\d{4}[A-Z]{3})$/,
                    "AT": /^(\d{4})$s/,
                    "AU": /^(\d{4})$/,
                    "AX": /^(?:FI)*(\d{5})$/,
                    "AZ": /^(?:AZ)*(\d{4})$/,
                    "BA": /^(\d{5})$/,
                    "BB": /^(?:BB)*(\d{5})$/,
                    "BD": /^(\d{4})$/,
                    "BE": /^(\d{4})$/,
                    "BG": /^(\d{4})$/,
                    "BH": /^(\d{3}\d?)$/,
                    "BM": /^([A-Z]{2}\d{2})$/,
                    "BN": /^([A-Z]{2}\d{4})$/,
                    "BR": /^(\d{8})$/,
                    "BY": /^(\d{6})$/,
                    "CA": /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
                    "CH": /^(\d{4})$/,
                    "CL": /^(\d{7})$/,
                    "CN": /^(\d{6})$/,
                    "CR": /^(\d{4})$/,
                    "CU": /^(?:CP)*(\d{5})$/,
                    "CV": /^(\d{4})$/,
                    "CX": /^(\d{4})$/,
                    "CY": /^(\d{4})$/,
                    "CZ": /^(\d{5})$/,
                    "DE": /^(\d{5})$/,
                    "DK": /^(\d{4})$/,
                    "DO": /^(\d{5})$/,
                    "DZ": /^(\d{5})$/,
                    "EC": /^([a-zA-Z]\d{4}[a-zA-Z])$/,
                    "EE": /^(\d{5})$/,
                    "EG": /^(\d{5})$/,
                    "ES": /^(\d{5})$/,
                    "ET": /^(\d{4})$/,
                    "FI": /^(?:FI)*(\d{5})$/,
                    "FM": /^(\d{5})$/,
                    "FO": /^(?:FO)*(\d{3})$/,
                    "FR": /^(\d{5})$/,
                    "GB": /^(([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA))$/,
                    "GE": /^(\d{4})$/,
                    "GF": /^((97|98)3\d{2})$/,
                    "GG": /^(([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA))$/,
                    "GL": /^(\d{4})$/,
                    "GP": /^((97|98)\d{3})$/,
                    "GR": /^(\d{5})$/,
                    "GT": /^(\d{5})$/,
                    "GU": /^(969\d{2})$/,
                    "GW": /^(\d{4})$/,
                    "HN": /^([A-Z]{2}\d{4})$/,
                    "HR": /^(?:HR)*(\d{5})$/,
                    "HT": /^(?:HT)*(\d{4})$/,
                    "HU": /^(\d{4})$/,
                    "ID": /^(\d{5})$/,
                    "IL": /^(\d{5})$/,
                    "IM": /^(([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA))$/,
                    "IN": /^(\d{6})$/,
                    "IQ": /^(\d{5})$/,
                    "IR": /^(\d{10})$/,
                    "IS": /^(\d{3})$/,
                    "IT": /^(\d{5})$/,
                    "JE": /^(([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA))$/,
                    "JO": /^(\d{5})$/,
                    "JP": /^(\d{7})$/,
                    "KE": /^(\d{5})$/,
                    "KG": /^(\d{6})$/,
                    "KH": /^(\d{5})$/,
                    "KP": /^(\d{6})$/,
                    "KR": /^(?:SEOUL)*(\d{6})$/,
                    "KW": /^(\d{5})$/,
                    "KZ": /^(\d{6})$/,
                    "LA": /^(\d{5})$/,
                    "LB": /^(\d{4}(\d{4})?)$/,
                    "LI": /^(\d{4})$/,
                    "LK": /^(\d{5})$/,
                    "LR": /^(\d{4})$/,
                    "LS": /^(\d{3})$/,
                    "LT": /^(?:LT)*(\d{5})$/,
                    "LU": /^(\d{4})$/,
                    "LV": /^(?:LV)*(\d{4})$/,
                    "MA": /^(\d{5})$/,
                    "MC": /^(\d{5})$/,
                    "MD": /^(?:MD)*(\d{4})$/,
                    "ME": /^(\d{5})$/,
                    "MG": /^(\d{3})$/,
                    "MK": /^(\d{4})$/,
                    "MM": /^(\d{5})$/,
                    "MN": /^(\d{6})$/,
                    "MQ": /^(\d{5})$/,
                    "MT": /^([A-Z]{3}\d{2}\d?)$/,
                    "MV": /^(\d{5})$/,
                    "MX": /^(\d{5})$/,
                    "MY": /^(\d{5})$/,
                    "MZ": /^(\d{4})$/,
                    "NC": /^(\d{5})$/,
                    "NE": /^(\d{4})$/,
                    "NF": /^(\d{4})$/,
                    "NG": /^(\d{6})$/,
                    "NI": /^(\d{7})$/,
                    "NL": /^(\d{4}[A-Z]{2})$/,
                    "NO": /^(\d{4})$/,
                    "NP": /^(\d{5})$/,
                    "NZ": /^(\d{4})$/,
                    "OM": /^(\d{3})$/,
                    "PF": /^((97|98)7\d{2})$/,
                    "PG": /^(\d{3})$/,
                    "PH": /^(\d{4})$/,
                    "PK": /^(\d{5})$/,
                    "PL": /^(\d{5})$/,
                    "PM": /^(97500)$/,
                    "PR": /^(\d{9})$/,
                    "PT": /^(\d{7})$/,
                    "PW": /^(96940)$/,
                    "PY": /^(\d{4})$/,
                    "RE": /^((97|98)(4|7|8)\d{2})$/,
                    "RO": /^(\d{6})$/,
                    "RS": /^(\d{6})$/,
                    "RU": /^(\d{6})$/,
                    "SA": /^(\d{5})$/,
                    "SD": /^(\d{5})$/,
                    "SE": /^(?:SE)*(\d{5})$/,
                    "SG": /^(\d{6})$/,
                    "SH": /^(STHL1ZZ)$/,
                    "SI": /^(?:SI)*(\d{4})$/,
                    "SK": /^(\d{5})$/,
                    "SM": /^(4789\d)$/,
                    "SN": /^(\d{5})$/,
                    "SO": /^([A-Z]{2}\d{5})$/,
                    "SV": /^(?:CP)*(\d{4})$/,
                    "SZ": /^([A-Z]\d{3})$/,
                    "TC": /^(TKCA 1ZZ)$/,
                    "TH": /^(\d{5})$/,
                    "TJ": /^(\d{6})$/,
                    "TM": /^(\d{6})$/,
                    "TN": /^(\d{4})$/,
                    "TR": /^(\d{5})$/,
                    "TW": /^(\d{5})$/,
                    "UA": /^(\d{5})$/,
                    "US": /^\d{5}(-\d{4})?$/,
                    "UY": /^(\d{5})$/,
                    "UZ": /^(\d{6})$/,
                    "VA": /^(\d{5})$/,
                    "VE": /^(\d{4})$/,
                    "VI": /^\d{5}(-\d{4})?$/,
                    "VN": /^(\d{6})$/,
                    "WF": /^(986\d{2})$/,
                    "YT": /^(\d{5})$/,
                    "ZA": /^(\d{4})$/,
                    "ZM": /^(\d{5})$/,
                    "CS": /^(\d{5})"$/,


                }
                // 
                // 

                // 


                var regex = ZipCodeFormat[country.value]

                // 

                var Valid = regex.test(value)

                // 
                return Valid
                break

            case "city":
                var regex = /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/
                var Valid = regex.test(value)
                // 
                return Valid
                break

            default:
                break;
        }
    }



    useEffect(() => {
        if (AddressInfoForm == undefined) return


        const FirstFormValid = Object.entries(AddressInfoForm).every((item) => {

            const [name, value] = item

            if (value?.valid) {
                return true
            }


            // 
            if (name == '_id' || name == "createdAt" || name == "updatedAt" || name == "country") {
                return true
            }

            const valid = ValidateValueBasedOnName(name, value, { value: AddressInfoForm?.country })

            return valid
        })



        if (FirstFormValid) {
            SetAddressInfoFormValid(true)
            return
        }
        else {
            SetAddressInfoFormValid(false)
        }

        // if(FirstFormValid){
        //     SetAddressInfoFormValid(FirstFormValid)

        //     return
        // }


        // const FormValid = Object.entries(AddressInfoForm).every((item) => {
        //     if (item[0] == '_id' || item[0] == "createdAt" || item[0] == "updatedAt" || item[0] == "country") {
        //         return true
        //     }

        //     

        //     return item[1].valid
        // })

        // SetAddressInfoFormValid(FormValid)


    }, [AddressInfoForm])


    return (
        <div className=" p-0  pb-0 flex flex-col ">
            <span className='text-sm mb-6'>
                Adresgegevens
            </span>

            <div className=" gap-3 flex w-full mb-6">
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    {/* {alert(typeof AddressInfoForm.fname)} */}
                    <input value={typeof AddressInfoForm?.fname == "string" ? AddressInfoForm?.fname : AddressInfoForm?.fname?.value} type={'text'} name="fname"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Voornaam

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.lname == "string" ? AddressInfoForm?.lname : AddressInfoForm?.lname?.value} type={'text'} name="lname"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Achternaam

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.email == "string" ? AddressInfoForm?.email : AddressInfoForm?.email?.value} type={'text'} name="email"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        E-mailadres

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.phone == "string" ? AddressInfoForm?.phone : AddressInfoForm?.phone?.value} type={'text'} name="phone"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Telefoon

                    </span>

                </div>
            </div>


            <div className=" gap-3 flex w-full mb-6">
                <div className="flex h-[50px] w-2/4 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.address == "string" ? AddressInfoForm?.address : AddressInfoForm?.address?.value} type={'text'} name="address"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Adres

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <select value={typeof AddressInfoForm?.country == "string" ? AddressInfoForm?.country : AddressInfoForm?.country?.value} type={'text'} name="country"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required>
                        <option defaultChecked className=''>
                            Selecteer Land
                        </option>
                        <option defaultChecked value={"NL"} className=''>
                            Nederland
                        </option>
                        <option defaultChecked value={"BE"} className=''>
                            België
                        </option>
                    </select>
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Land

                    </span>

                </div>
                <div className="flex h-[50px] w-1/5 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.zip == "string" ? AddressInfoForm?.zip : AddressInfoForm?.zip?.value} type={'text'} name="zip"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Postcode

                    </span>

                </div>
                <div className="flex h-[50px] w-1/5 rounded-lg flex-col relative justify-center ">
                    <input value={typeof AddressInfoForm?.city == "string" ? AddressInfoForm?.city : AddressInfoForm?.city?.value} type={'text'} name="city"
                        onChange={async (e) => {
                            const { valid, value } = await OnChangeAddressForm(e)
                            // alert(valid)

                            if (valid) {
                                e.target.style.border = ''

                            }
                            else if (valid == false && value != '') {
                                e.target.style.border = '1px solid red'
                            }
                            else {
                                e.target.style.border = ''
                            }
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Stad

                    </span>

                </div>
            </div>

            <button onClick={async () => {
                SetSavingAddressDetails(true)

                const Updated = await UpdateAddress(AddressInfoForm)
                
                SetSavingAddressDetails(false)
                if(Updated.Success){
                    
                }
                else{
                    //pop up failed
                }
            }} disabled={!AddressInfoFormValid} className={`py-2 text-center  ${AddressInfoFormValid ? null : "cursor-not-allowed opacity-70 "} border rounded text-sm w-fit px-5 mb-0`}>
                {SavingAddressDetails ?
                    <ReactLoading type="spin" color="#0052ff" className="mx-auto text-xl " height={16} width={16} /> : 'Opslaan'}
            </button>

        </div>
    )
}

export default AdressInfo