import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FetchSneakers from '../services/FetchSneakers'
import ReactLoading from 'react-loading';
import FetchSneakerBySKU from '../services/FetchSpecificSneaker';
import FetchSizesBasedOnBrandId from '../services/FetchSizesBasedOnBrand';
import FetchListingsBySKU from '../services/FetchListingsBySKU';
import { BsQuestionCircle } from "react-icons/bs";
import { FcShipped, FcUpLeft, FcInTransit, FcApproval, FcLeftDown } from 'react-icons/fc'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AddToCart, RetrieveCart } from "../services/CartService";

import { useGlobalState } from "../state/state";
import Footer from "../components/Footer";
import { FaEdit } from 'react-icons/fa';
import FetchProductDetailsBySKU from '../services/FetchProductDetails';
import { FetchPricing } from '../services/FetchPricing';
import { SellToBid } from '../services/SellToBid';
import { CreateListing } from '../services/CreateListing'
function ProductSell() {

    const navigate = useNavigate()

    const { ProductSKU } = useParams()
    const [FetchingData, SetFetchingData] = useState(false)

    useEffect(() => {
        if (ProductSKU == undefined) {
            SetFetchingData(false)
        }

        //fetch Data for SKU
        SetFetchingData(true)


    }, [ProductSKU])


    useEffect(() => {

    }, [FetchingData])

    const ProductItem = ({ ProductName, ProductImage, LowestPrice, ProductUrl, Productsku }) => {
        return (
            <Link to={`${Productsku}`} className="border flex flex-col p-3  min-w-[100%]  flex-1 cursor-pointer mx-auto ">

                {/* Image Section */}
                <div id="" className="w-[140px] h-[75px] m-auto">
                    <img className=" object-contain " src={ProductImage} />
                </div>

                {/* Text Section */}

                <div className="text-left flex flex-col w-full mt-8 ">
                    <span className="text-sm overflow text-ellipsis font-light line-clamp-2">
                        {ProductName}
                    </span>


                </div>
                <div className="mt-3 text-left flexn">

                    <div className=" w-[1px] bg-slate-200 opacity-60" />
                    <div className={`${LowestPrice != "OOS" ? null : 'line-through'} flex flex-col`}>

                        <span className="text-sm">
                            {LowestPrice != "OOS" ? <>

                                € {LowestPrice}
                            </> : "Uitverkocht"}

                        </span>
                    </div>
                </div>



                {/* <div className="w-[140px] h-[75px]">
                        <img  src={ProductImage}  />
                    </div>
                   <span className="text-sm">
                    {ProductName}
                   </span> */}
            </Link>
        )
    }

    const SearchSection = () => {
        const [SearchQuery, SetSearchQuery] = useState('')
        const [FoundSneakersBasedOnQuery, SetFoundSneakersBasedOnQuery] = useState([])
        useEffect(() => {
            if (SearchQuery == '') {
                SetFoundSneakersBasedOnQuery([])
                return
            }

            FetchSneakers(SearchQuery).then(FoundSneakers => {

                SetFoundSneakersBasedOnQuery(FoundSneakers)
            })


        }, [SearchQuery])

        return (
            <div className=" py-4 text-left   md:flex w-full flex-col  gap-3  ">


                <div className="mx-auto flex flex-col w-full md:w-2/3  gap-1">
                    <span className=" font-normal text-lg ">
                        Welke Sneaker wil je verkopen?
                    </span>
                    <span className=" font-light text-md ">
                        Vind de sneaker die je wilt verkopen om verder te gaan
                    </span>
                    <input type={'search'} onChange={(e) => SetSearchQuery(e.target.value)} className="border px-2 py-2 rounded-md text-xs mb-2 mt-2 " placeholder="Sneaker zoeken op Kleur, SKU, Merk, etc." />

                </div>
                {FoundSneakersBasedOnQuery.length > 0 ? <div className="w-full border">
                    <div className="grid  grid-cols-2  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-6  justify-center gap-4  px-5 py-5">
                        {FoundSneakersBasedOnQuery.map((sneaker, index) => <ProductItem ProductName={sneaker.name} ProductImage={`http://localhost:7584/image/product/${sneaker.sku}`} ProductUrl={sneaker.slug} Productsku={sneaker.sku} LowestPrice={sneaker.storeprice} />)}
                    </div>
                </div> : null}
            </div>
        )
    }

    const ProductSection = () => {
        const [ShoeData, SetShoeData] = useState({})
        const { ProductSKU } = useParams()
        const [SelectedSize, SetSelectedSize] = useState(null)
        const [FetchingData, SetFetchingData] = useState(true)
        const [SellNowState, SetSellState] = useState(true)
        const [SelectedSizeDetails, SetSelectedSizeDetails] = useState({})
        const [SellNowAvailable, SetSellNowAvailable] = useState(false)


        const OnConfirmSale = (e) => {
            const BidID = SelectedSizeDetails.SizeData.Id
            SellToBid(BidID)
        }



        const SizeSelectedState = () => {
            const [ListingPrice, SetListingPrice] = useState({

            })
            const [ListingValid, SetListingValid] = useState(false)

            const [UserQuestions2, SetUserQuestions2] = useState(false)
            const [UserQuestions, SetUserQuestions] = useState(false)


            useEffect(() => {
             
                if (ListingPrice != {} && UserQuestions && UserQuestions2) {
                    SetListingValid(true)
                }
                else {
                    SetListingValid(false)
                }


            }, [UserQuestions2, UserQuestions, ListingPrice])


            const OnConfirmListing = (e) => {


                const JSON_DATA = {
                    ShoeData: ShoeData,
                    valid: ListingValid,
                    Price: parseInt(ListingPrice.FormattedPrice) - parseInt(ListingPrice.Shipping) - parseInt(ListingPrice.ProcessingFee),
                    Size: SelectedSize,
                    Quantity: 1,

                    Payout: parseInt(parseInt(ListingPrice.FormattedPrice) - parseInt(ListingPrice.Shipping) - parseInt(ListingPrice.ProcessingFee) - parseInt(ListingPrice.Shipping) - parseInt(ListingPrice.ProcessingFee)),
                    ShipmentAgreement: UserQuestions == true && UserQuestions2 == true,

                }
       
                CreateListing(JSON_DATA).then(resp => {
                    if (resp.created) {
                        //redirect to other success page
                        navigate(`/account/listing/created/${resp.listing}`)


                    }

                })
            }

            const OnChangeListingPrice = (e) => {
                const ListingPrice = e.target.value
                FetchPricing(ListingPrice).then(resp => {
                    SetListingPrice({
                        ...resp
                    })

                    // SetListingPrice(e.target.value)
                })


            }

            useEffect(() => {
            }, [ListingPrice])
            return (
                <div className='md:w-[40%] w-full  h-full  flex '>
                    <div className='flex flex-col w-full gap-5'>
                        <div onClick={() => [
                            SetSelectedSize(null)
                        ]} className=' flex  cursor-pointer justify-between border items-center h-fit p-4  py-2 rounded w-full '>
                            <div className=' h-fit w-full'>
                                <span className='text-base font-medium'>
                                    EU {SelectedSize}
                                </span>
                            </div>
                            <FaEdit />

                        </div>
                        <div className="flex  justify-between gap-2 mb-3 w-full ">
                            {/* {alert(SellNowAvailable)} */}
                            <button onClick={() => {
                                SetSellState(true)

                            }} disabled={!SellNowAvailable} className={`text-center w-1/2  ${SellNowState ? "relative leading-4 text-sm bg-[#0052ff]  font-medium w-1/2 m-0 rounded text-white px-3 py-2.5 border border-[#0052ff]  rounded-full" : "opacity-70"} rounded-full text-sm font-medium border cursor-pointer ${SellNowAvailable ? null : 'cursor-not-allowed opacity-70 bg-white text-black border'}`}>

                                Verkoop nu

                            </button>
                            <button onClick={() => {

                                SetSellState(false)
                            }} className={`text-center w-1/2  ${!SellNowState ? "relative leading-4 text-sm bg-[#0052ff]  font-medium w-1/2 m-0 rounded text-white px-3 py-2.5 border border-[#0052ff]  rounded-full" : "opacity-70"} rounded-full text-sm font-medium border cursor-pointer`}>

                                Advertentie Plaatsen
                            </button>
                        </div>

                        {SellNowState ? <>
                            <span className='font-medium'>Verkoop Overzicht</span>
                            <div class="flex flex-col gap-4 w-full h-full border-b">
                                <span class="text-sm">Verkoopprijs</span>
                                <div type="number" className="p-2 text-sm disabled:bg-white flex items-center  w-full rounded py-3  border" placeholder="">
                                    € {parseInt(SelectedSizeDetails?.SizeData?.Price).toFixed(2)}

                                </div>
                                <div class="flex justify-between w-full"><span class="text-sm">Verwerkingskosten</span><span class="text-sm">- € {parseInt(SelectedSizeDetails?.Pricing?.ProcessingFee).toFixed(2)}</span></div>
                                <div class="flex justify-between w-full"><span class="text-sm">Verzendkosten</span><span class="text-sm">- € {parseInt(SelectedSizeDetails?.Pricing?.Shipping).toFixed(2)}</span></div>
                                <div class="flex  border-t pt-5 justify-between w-full pb-5"><span class="text-sm font-medium">Payout</span><span class="text-sm">€ {parseInt(parseInt(SelectedSizeDetails?.SizeData?.Price) - parseInt(SelectedSizeDetails?.Pricing?.Shipping) - parseInt(SelectedSizeDetails?.Pricing?.ProcessingFee)).toFixed(2)}</span></div>
                                <div className="flex flex-col gap-1 w-full py-2">
                                    <div className='flex    gap-2 w-full py-2'>
                                        <input onChange={(e) => {
                                            if (e.target.checked) {
                                            }
                                            else {
                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Ik verzend binnen drie werkdagen na verkoop
                                        </span>
                                    </div>

                                    <div className='flex    gap-2 w-full py-2'>

                                        <input onChange={(e) => {
                                            if (e.target.checked) {
                                                SetUserQuestions(true)
                                            }
                                            else {
                                                SetUserQuestions(false)

                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Als ik het product niet of niet juist opstuur, brengt ... Sneakers automatisch €10 in rekening
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <button onClick={(e) => {
                                OnConfirmSale(e)
                            }} className=' mt-auto relative leading-4 text-sm bg-[#0052ff]  font-medium  m-0 rounded text-white px-3 py-2.5 border border-[#0052ff]  w-full'>
                                Verkoop bevestigen
                            </button>
                        </> : <>
                            <span className='font-medium'>Advertentie Overzicht</span>
                            <div class="flex flex-col gap-4 w-full h-full border-b">
                                <span class="text-sm">Advertentie Prijs</span>

                                <input
                                    onChange={(e) => {
                                        OnChangeListingPrice(e)
                                    }}
                                    type="number" className="p-2 text-sm  w-full rounded py-3  border" placeholder="Advertentie Prijs  (€)" />
                                {/* <div class="flex justify-between w-full"><span class="text-sm">Advertentie Prijs</span><span class="text-sm">€ 122</span></div> */}
                                <div class="flex justify-between w-full">
                                    <span class="text-sm">Verwerkingskosten</span>
                                    <span class="text-sm">- €
                                        {isNaN(parseInt(ListingPrice.ProcessingFee).toFixed(2)) ? '0' : parseInt(ListingPrice.ProcessingFee).toFixed(2)}
                                    </span>
                                </div>
                                <div class="flex justify-between w-full">
                                    <span class="text-sm">Verzendkosten</span>
                                    <span class="text-sm">- €
                                        {isNaN(parseInt(ListingPrice.Shipping).toFixed(2)) ? '0' : parseInt(ListingPrice.Shipping).toFixed(2)}
                                    </span>
                                </div>
                                <div class="flex  border-t pt-5 justify-between w-full pb-5">
                                    <span class="text-sm font-medium">Payout</span>
                                    <span class="text-sm">€
                                        {isNaN(parseInt(ListingPrice.FormattedPrice).toFixed(2)) ? '0' : parseInt(parseInt(ListingPrice.FormattedPrice) - parseInt(ListingPrice.Shipping) - parseInt(ListingPrice.ProcessingFee) - parseInt(ListingPrice.Shipping) - parseInt(ListingPrice.ProcessingFee)).toFixed(2)}
                                    </span>
                                </div>
                                <div className="flex flex-col gap-1 w-full py-2">
                                    <div className='flex    gap-2 w-full py-2'>
                                        <input onChange={(e) => {
                                            if (e.target.checked) {
                                                SetUserQuestions2(true)
                                            }
                                            else {
                                                SetUserQuestions2(false)

                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Ik verzend binnen drie werkdagen na verkoop
                                        </span>
                                    </div>

                                    <div className='flex    gap-2 w-full py-2'>

                                        <input onChange={(e) => {
                                            if (e.target.checked) {
                                                SetUserQuestions(true)
                                            }
                                            else {
                                                SetUserQuestions(false)

                                            }
                                        }} type={'checkbox'} />
                                        <span className="text-xs">
                                            Als ik het product niet of niet juist opstuur, brengt ... Sneakers automatisch €10 in rekening
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <button onClick={(e) => {
                                OnConfirmListing(e)
                            }} disabled={!ListingValid} className={` mt-auto relative leading-4 text-sm bg-[#0052ff]  font-medium  m-0 rounded text-white px-3 py-2.5 border border-[#0052ff]  w-full ${ListingValid ? null : 'opacity-70  bg-[#777777] border-[#777777] cursor-not-allowed    '}`}>
                                Advertentie plaatsen
                            </button>
                        </>}


                    </div>
                </div>
            )
        }

        const SelectSizeState = () => {
            return (
                <div className='md:w-[40%] justify-center w-full  flex'>
                    <div className='flex flex-col'>
                        <span className="md:text-lg text-base mb-4 xl:mb-0 font-normal flex flex-col">
                            Selecteer Maat
                            <span>

                            </span>

                        </span>
                        <div className='flex h-fit mt-5'>
                            <div className="grid w-full xl:grid-cols-5 lg:grid-cols-5  md:grid-cols-4 sm:grid-cols-8 grid-cols-5  gap-3  mb-3">

                                {ShoeData.Sizes?.map(SizeInfo => {
                                    const Size = SizeInfo.Size
                                    const Price = SizeInfo.Price
                                    return (
                                        <div onClick={() => {


                                            SetSelectedSize(Size)
                                        }} className={` flex h-fit w-[70px] ${Size == SelectedSize ? "bg-[#0052ff] border-[#0052ff] " : 'bg-transparent '} px-3 py-3 rounded-sm cursor-pointer flex-col text-center border`}>
                                            <span className={`font-semibold text-sm ${Size == SelectedSize ? "text-white" : 'text-black'}`}  >
                                                <span className={`${Price == "OOS" ? "line-through" : null}`} >{Size}</span>
                                            </span>
                                            <span className={`text-xs ${Size == SelectedSize ? "text-gray-200" : 'text-gray-500'}`}>
                                                {Price != 'OOS' ? <span>€</span> : null}
                                                <span>{Price}</span>
                                            </span>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>


                </div>
            )
        }


        useEffect(() => {
            if (SellNowAvailable == true) {
                SetSellState(true)
            }
            else {
                SetSellState(false)
            }
        }, [SellNowAvailable])

        useEffect(() => {




            FetchSneakerBySKU(ProductSKU).then(result => {

                if (result.error) {
                    navigate('/not-found')
                }
                if (result.relatedproducts.length > 0) {
                    // SetRelatedproducts(result.relatedproducts)
                }



                if (result.sneakers.length > 0) {



                    // result[0].image = String(result[0].image).replace('-1-' , '1000')
                    result.sneakers[0].image = String(result.sneakers[0].image).replace('400.png', '1000.png')
                    FetchSizesBasedOnBrandId(result.sneakers[0]).then(Sizes => {
                        // 
                        // ShoeData.Sizes = Object.keys(Sizes)

                        FetchProductDetailsBySKU(ProductSKU).then(Details => {
                            // var Details = ShoeData
                            // 
                            var SizesWithBids = Details.ProductDetails.HighestBids

                            var AllSizes = Sizes



                            let new_array = []
                            AllSizes.forEach(element => {


                                element = element.replace(".5", ' ½').replace(' 1/3', ' ⅓').replace(' 2/3', ' ⅔')
                                // 
                                // 



                                if (SizesWithBids[element] != undefined) {

                                    new_array.push({
                                        Size: SizesWithBids[element].Size,
                                        Price: SizesWithBids[element].BidAmount,
                                        Id: SizesWithBids[element]._id
                                    })
                                }
                                else {

                                    new_array.push({
                                        Size: element,
                                        Price: "-",

                                    })
                                }
                            });

                            // temp_ShoeData.Sizes = new_array



                            SetShoeData({ ...result.sneakers[0], Sizes: new_array })

                            SetFetchingData(false)
                            // 

                            // SizesWithListings.forEach((Size) =>{
                            //     var FormattedSize = Size
                            //     var FormattedSize =  FormattedSize.replace(' ½' , '.5')
                            //     FormattedSize = FormattedSize.replace(' ½' , '.5')
                            //     FormattedSize = FormattedSize.replace(' ½' , '.5')


                            //     // 
                            //     // 
                            //     const IndexOfSize = temp_ShoeData.Sizes.indexOf(FormattedSize)
                            //     // 
                            //     
                            //     if(IndexOfSize != -1){
                            //         temp_ShoeData.Sizes[IndexOfSize] = {
                            //             Size:Size,
                            //             LowestPrice:result.Listings[Size].Price,
                            //             ID:result.Listings[Size]._id
                            //         }
                            //     }
                            //     else{
                            //         temp_ShoeData.Sizes[IndexOfSize] = {
                            //             Size:Size,
                            //             LowestPrice:"OOS"
                            //         }
                            //     }

                            //     // 



                            //     // temp_ShoeData.Sizes[IndexOfSize] = result.Listings[Size]

                            // })

                            // 



                        })




                    })

                }


            })



        }, [ProductSKU]);




        useEffect(() => {
            if (SelectedSize == undefined) {
                SetSellNowAvailable(false)
                return
            }


            const SizeData = ShoeData?.Sizes?.find((value, index, array) => {

                if (value.Size == SelectedSize) {
                    return value
                }
            })

            if (SizeData.Price != '-') {
                SetSellNowAvailable(true)
                FetchPricing(SizeData.Price).then(resp => {
                    delete resp.FormattedPrice
                    SetSelectedSizeDetails({ SizeData, Pricing: resp })

                })



            }
            else {
                SetSellNowAvailable(false)
            }
            // SetSelectedSizeDetails()

        }, [SelectedSize])

        return (
            <div className='flex h-full w-full'>
                {
                    FetchingData ?
                        <div className='flex my-auto w-full'>
                            <ReactLoading type="spin" color='#0052ff' className=" mx-auto flex my-auto z-[1000000]" height={55} width={55} />


                        </div>
                        :
                        <div className='flex flex-col md:flex-row justify-between w-full '>
                            <div className='md:w-[60%] w-full  flex flex-col'>
                                <span className="text-2xl font-semibold flex flex-col">
                                    {ShoeData.name}
                                    <span className='text-base font-light'>
                                        {ShoeData.sku}
                                    </span>
                                </span>
                                <img className="w-[80%] rounded" src={`http://localhost:7584/image/product/${ShoeData.sku}` || ''} />

                            </div>
                            <div className='md:h-full w-full h-[2px]  md:w-[2px] md:mt-0 md:mb-0 mt-5 mb-5 md:mr-5 md:ml-5 bg-slate-300 opacity-20' />

                            {SelectedSize == null ? <SelectSizeState /> :
                                <SizeSelectedState />
                            }

                        </div>
                    //     <div>
                    //         <span className="text-2xl font-semibold flex flex-col">
                    //             {ShoeData.name}
                    //             <span className='text-base font-light'>
                    //                 {ShoeData.sku}
                    //             </span>
                    //         </span>


                    //         <div className="flex xl:flex-row  lg:flex-row  md:flex-col sm:flex-col flex-col mt-2  mb-10 gap-3">
                    //             <div className="flex justify-left h-full   ">
                    //                 <img className=" mx-auto    w-full rounded" src={`http://localhost:7584/image/product/${ShoeData.sku}` || ''} />

                    //                 {/* <div className="flex flex-col mt-5" >

                    //     <span className="text-sm text-[#a0a1a0]">
                    //         <span className="font-medium">Style Code</span>: DC6887-200
                    //     </span>

                    //     <span className="text-sm text-[#a0a1a0]">

                    //         <span className="font-medium">Conditie</span>: Nieuw
                    //     </span>

                    //     <span className="text-sm text-[#a0a1a0]">

                    //         <span className="font-medium">Levering</span>: Standard Levering (Track & Trace)
                    //     </span>

                    // </div> */}
                    //             </div>
                    //             {/* <div className=" w-full  flex flex-col gap-4">

                    //                 {SelectedSize == null ?
                    // <div className="grid    w-full xl:grid-cols-8 lg:grid-cols-6  md:grid-cols-8 sm:grid-cols-5 grid-cols-4  gap-3  mb-3">

                    //     {ShoeData.Sizes?.map(SizeInfo => {
                    //         const Size = SizeInfo.Size
                    //         const Price = SizeInfo.Price
                    //         return (
                    //             <div onClick={() => {


                    //                 SetSelectedSize(Size)
                    //             }} className={` flex h-fit w-[70px] ${Size == SelectedSize ? "bg-[#0052ff] border-[#0052ff] " : 'bg-transparent '} px-3 py-3 rounded-sm cursor-pointer flex-col text-center border`}>
                    //                 <span className={`font-semibold text-sm ${Size == SelectedSize ? "text-white" : 'text-black'}`}  >
                    //                     <span className={`${Price == "OOS" ? "line-through" : null}`} >{Size}</span>
                    //                 </span>
                    //                 <span className={`text-xs ${Size == SelectedSize ? "text-gray-200" : 'text-gray-500'}`}>
                    //                     {Price != 'OOS' ? <span>€</span> : null}
                    //                     <span>{Price}</span>
                    //                 </span>
                    //             </div>
                    //         )
                    //     })}

                    // </div> :
                    //                     <div className="flex flex-col">
                    //                         <div className='flex gap-4'>
                    //                             <button>
                    //                                 Plaats Advertentie
                    //                             </button>


                    //                         </div>
                    //                     </div>}




                    //             </div> */}

                    //         </div>
                    //     </div>
                }
            </div>

        )
    }

    return (
        <div className="flex  flex-col w-full flex-shrink-0  mb-5 p-4 py-4 px-10 space-y-4 mt-10">
            {ProductSKU == undefined ? <SearchSection /> : <ProductSection />
            }
        </div>
    )
}


export default ProductSell