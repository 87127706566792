import { useEffect, useState } from "react"
import FetchAccount from "../../../services/FetchAccountInfo"




function AccountInfo() {

    const [AccountInfoForm, SetAccountInfoForm] = useState(() => {
        FetchAccount().then(response => {
            // 
            SetAccountInfoForm(response.Account)
        })
    })

    useEffect(() => {

        if (AccountInfoForm == undefined) return
        

    }, [AccountInfoForm])



    const OnChangeAccountForm = async (e) => {
        
        
        SetAccountInfoForm({ ...AccountInfoForm, [e.target.name]: e.target.value })

    }
    
    return (
        <div className="  p-0 pb-0 flex flex-col ">
            <span className='text-sm mb-6'>
                Accountgegevens
            </span>
            <div className="gap-3 flex w-full mb-6">
                <div className="flex h-[50px] w-fit rounded-lg flex-col relative justify-center ">
                    <input value={AccountInfoForm?.FirstName} type={'text'} name="FirstName"
                        onChange={(e) =>
                            OnChangeAccountForm(e)
                            // HandleSubCategoryChange(e)
                            // SetPrice(e.target.value)
                        } className="w-full p-3 text-xs py-3 pointer-events-none input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Voornaam

                    </span>

                </div>
                <div className="flex h-[50px] w-fit rounded-lg flex-col relative justify-center ">
                    <input value={AccountInfoForm?.LastName} type={'text'} name="LastName"
                        onChange={(e) =>
                            OnChangeAccountForm(e)
                            // HandleSubCategoryChange(e)
                            // SetPrice(e.target.value)
                        } className="w-full p-3 text-xs py-3 pointer-events-none input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Achternaam

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input value={AccountInfoForm?.email} type={'text'} name="Email"
                        onChange={(e) =>
                            OnChangeAccountForm(e)
                            // HandleSubCategoryChange(e)
                            // SetPrice(e.target.value)
                        } className="w-full p-3 text-xs py-3 pointer-events-none input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        E-mailadres

                    </span>

                </div>
            </div>
    
        </div>
    )
}

export default AccountInfo