import { useEffect, useState } from "react"
import { BsQuestionCircle } from "react-icons/bs"
import { Link, useNavigate, useParams } from "react-router-dom"
import DeleteListingByID from "../services/DeleteListingByID"
import FetchListing from "../services/FetchListingByID"
import ProductPricing from "../services/ProductPricing"

function CreatedListingPage() {

    const { ListingID } = useParams()
    const [ListingDetails, SetListingDetails] = useState(null)
    const [NewPrice, SetNewPrice] = useState(0)
    const [LowestPrice, SetLowestPrice] = useState(false)
    const [NewPricePayout, SetNewPricePayout] = useState(0)
    const navigate = useNavigate()
    useEffect(() => {

        FetchListing(ListingID).then(resp => {
            if (resp.SneakerListings.length > 0) {
                SetListingDetails(resp.SneakerListings[0])

                SetNewPrice(resp.SneakerListings[0].Price)
            }
        })
    }, [])


    useEffect(() => {
        let Price = ListingDetails?.Price
        let Size = ListingDetails?.Size

        if (NewPrice != 0) {
            Price = NewPrice
        }

        if (Size == null) return

        ProductPricing(ListingDetails?.ShoeData.sku).then(response => {
            const CurrentPrice = response[Size]?.Price
            if (CurrentPrice == undefined) {

                SetLowestPrice(true)
                //current price input is lowest price!
                return
            }

            //else calculate difference 
            const DifferenceBasedOnPrice = parseFloat(parseFloat(Price) - parseFloat(CurrentPrice)).toFixed(2)

            

            if (DifferenceBasedOnPrice == 0) {
                SetLowestPrice('same_price_as_lowest_listing')

                return
            }

            else if (DifferenceBasedOnPrice > 0) {

                SetLowestPrice(false)
            }

            else {

                SetLowestPrice(true)
            }



        })
    }, [ListingDetails, NewPrice])

    // useEffect(() =>{
    //         
    //         SetNewPricePayout(NewPrice - 10)

    // } , [NewPrice , ])


    // // useEffect(() =>{
    // //     SetNewPrice(NewPricePayout + 10)
    // // } , [NewPricePayout])

    return (

        <div className="h-fit  flex flex-col  ">
            <div className="m-auto mt-[40px] min-w-[50%] min-h-[100%] h-fit  border rounded p-10 pb-0   flex flex-col">



                <div onClick={() => {

                }} className="  ">

                    {/* Image Section */}
                    <div id="" className="w-[140px] h-[75px] m-auto">
                        <img className=" object-contain " src={`http://localhost:7584/image/product/${ListingDetails?.ShoeData.sku}`} />
                    </div>

                    {/* Text Section */}

                    <div className="text-left flex flex-col w-full mt-8 mb-5   ">
                        <span className="text-sm   overflow text-ellipsis w-full   line-clamp-2">
                            {ListingDetails?.ShoeData.name}
                        </span>
                        <span className="text-sm overflow mt-2 text-ellipsis w-full  font-light line-clamp-2">
                            <span className="font-medium">Maat:</span> {ListingDetails?.Size}
                        </span>
                        <span className="text-sm overflow mt-2 text-ellipsis w-full  font-light line-clamp-2">
                            <span className="font-medium">ID:</span> {ListingID}
                        </span>




                        <div className="flex gap-8 mt-5">
                            <div className="grow-1 flex  w-1/2 flex-col gap-2">
                                <div className="flex flex-col  gap-2 border-b w-full py-2">
                                    <span className="text-xs opacity-60 ">
                                        Prijs (€)
                                    </span>
                                    <input onChange={event => SetNewPrice(event.target.value)} value={NewPrice} placeholder={`€${ListingDetails?.Price}`} className="text-sm" />



                                </div>

                                <span className="text-xs opacity-60 flex w-fit items-center gap-1">
                                    {parseFloat(ListingDetails?.Price) > 0 && ListingDetails?.Size != null ? <>
                                        {LowestPrice == true ? 'Je bent de goedkoopste!' : LowestPrice == 'same_price_as_lowest_listing' ? ' Er is een andere verkoper met dezelfde prijs' : 'Je bent niet de goedkoopste!'}
                                        <BsQuestionCircle className="cursor-pointer !text-black" />
                                    </> : ''}


                                </span>
                            </div>
                            <div className="grow-1 flex w-1/2 flex-col gap-2">
                                <div className="flex flex-col  gap-2 border-b w-full py-2">
                                    <span className="text-xs opacity-60 ">
                                        Payout (€)
                                    </span>
                                    <input onChange={event => {
                                        SetNewPrice(parseInt(event.target.value) + 10)
                                    }} value={NewPrice - 10} placeholder={`€${ListingDetails?.Price}`} className="text-sm" />

                                </div>


                            </div>






                        </div>

                        <div className="flex gap-3  justify-between w-full mt-10">
                            <Link to={'/account/sell'} onClick={() => {

                            }} className="relative leading-4  w-1/2   font-medium  text-center m-0 rounded text-black px-3 py-2.5 border   text-xs">
                                <span>
                                    Advertentie opslaan
                                </span>
                            </Link>
                            <Link to={'/account/'} className="relative leading-4  w-1/2   font-medium  text-center m-0 rounded text-black px-3 py-2.5 border   text-xs">
                                <span class="  ">
                                    Verwijder Advertentie
                                </span>
                            </Link>


                        </div>


                    </div>




                    {/* <div className="w-[140px] h-[75px]">
                        <img  src={ProductImage}  />
                    </div>
                   <span className="text-sm">
                    {ProductName}
                   </span> */}
                </div>

            </div>

            <div className="m-auto mt-[40px] min-w-[50%] justify-between gap-3   h-fit  border rounded  px-10 py-5  flex ">

                <div className="flex gap-3 mt-auto justify-between w-full">
                    <Link to={'/account/sell'} onClick={() => {

                    }} className="relative leading-4 w-1/2   font-medium text-center  m-0 rounded text-black px-3 py-2.5 border   text-xs">
                        <span>
                            Plaats nog een Advertentie
                        </span>
                    </Link>
                    <Link to={'/account/'} className="relative leading-4 w-1/2    font-medium text-center m-0 rounded text-black px-3 py-2.5 border   text-xs">
                        <span class="  ">
                            Alle Advertenties Bekijken
                        </span>
                    </Link>


                </div>
            </div>

        </div>

    )
}

export default CreatedListingPage