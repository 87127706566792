

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchSneakers(query , limit=40){
    return new Promise((resolve , reject) =>{
        
        fetch(`${API_MAIN_URL}/sneakers?search=${query}`).then(resp => resp.json()).then((FoundSneakers) =>{
            // 
            resolve(FoundSneakers.sneakers)
        })
    })

}

export default FetchSneakers