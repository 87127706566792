import { FaQuestionCircle } from "react-icons/fa"
import { RiArrowDropDownLine } from "react-icons/ri"
import Footer from "../components/Footer"

function FaqScreen(){






    return(
        <div className="flex  flex-shrink-0 mt-1 p-4 px-10  mt-10 space-y-4 bg-[#eceef17e] w-5/6 mb-10 mx-auto flex-col gap-4 rounded py-10">
            <span className="flex items-center gap-3 font-medium">
                <FaQuestionCircle size={30}/>
                Veelgestelde Vragen
            </span>

            <div className="flex flex-col gap-2">
                <span>
                    Algemeen
                </span>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <span>
                    Verkopen
                </span>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <span>
                    Kopen
                </span>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
                <div className="bg-white w-full  relative mt-2 h-fit flex rounded border p-2 px-4 cursor-pointer hover:bg-[#fbfcfb] justify-between items-center">
                    <span className="text-xs">
                    Hoe neem ik contact op met ...Sneakers?

                    </span>
                    <RiArrowDropDownLine size={32}/>
                </div>
            </div>

            {/* <Footer/> */}

        </div>
    )
}


export default FaqScreen