

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchPopulairSneakers(query , limit=40){
    return new Promise((resolve , reject) =>{
        
        fetch(`${API_MAIN_URL}/sneakers/populair`).then(resp => resp.json()).then((FoundSneakers) =>{
            // 
            resolve(FoundSneakers)
        })
    })

}

export default FetchPopulairSneakers