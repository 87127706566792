
import axios from "axios";
const API_URL = 'http://localhost:8080/api'


export const RetrieveCart = async (event) =>{
    
    // event.preventDefault();
    try {
        const { data } = await axios.get(
            `${API_URL}/cart/retrieve`,
            { withCredentials: true }
        );
        // 
        data.cart_found = true
        return data
    }
    catch(err){
            
    }
}

export const AddToCart = async (AddToCartPayload) =>{
    
    
    try {
        const { data } = await axios.post(
            `${API_URL}/cart/add`,
          
            {...AddToCartPayload},
              
            { withCredentials: true , headers:{
                'Content-Type': 'application/json'
            }}
        );
        // 
        // data.cart_found = true
        return data
    }
    catch(err){
            
    }
}


export const DeleteItemFromCart =  async(ItemID) =>{
    try {
        const response = await axios.delete(
            `${API_URL}/cart/remove/${ItemID}`,
            
            // {...AddToCartPayload},
              
            { withCredentials: true , headers:{
                'Content-Type': 'application/json'
            }}
        );
        // 
        // data.cart_found = true
        return response.status
    }
    catch(err){
            
    }
}
