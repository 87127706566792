import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaCcMastercard, FaCcVisa, FaEdit } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { RetrieveAccountData } from "../services/AccountService";
import { countries } from 'country-data';
import FetchBidsBySKU from "../services/FetchListingsBySKU copy";
import { RiContactsBookLine, RiContrastDrop2Fill } from "react-icons/ri";
import { FetchPricing } from "../services/FetchPricing";
import FetchSneakerBySKU from "../services/FetchSpecificSneaker";
import FetchSizesBasedOnBrandId from "../services/FetchSizesBasedOnBrand";
import FetchListingsBySKU from "../services/FetchListingsBySKU";
import { CreateBid } from "../services/CreateBid";
import FetchProductDetailsBySKU from "../services/FetchProductDetails";
import ReactLoading from 'react-loading'

function ProductBids({ }) {
    const navigate = useNavigate()
    const { ProductSKU } = useParams()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search);
    const ProductSize = urlParams.get('size')
    const [Bid, SetBid] = useState(0)
    const [BidValid, SetBidValid] = useState(false)
    const [FeesAndTotalBid, SetFeesAndTotalBid] = useState({

    })
    const [ShoeData, SetShoeData] = useState({})
    const [ShoePriceData, SetShoePriceData] = useState({})
    const [PlacingBid, SetPlacingBid] = useState(false)


    // const [CurrentBids, SetCurrentBids] = useState(() => {
    //     //default value

    //     //fetch all the product data


    // })




    const [AccountData, SetAccountData] = useState(() => {
        RetrieveAccountData().then((Account) => {
            SetAccountData(Account.Account)
        })
    })




    useEffect(() => {
        FetchPricing(Bid).then(response => {
            SetFeesAndTotalBid(response)
        })

    }, [Bid])


    useEffect(() => {

        if (AccountData?.PaymentMethod?.PaymentMethodID && AccountData?.Address && Bid > 0 && Bid < ShoePriceData?.LowestBuyNowPrice) {
            SetBidValid(true)

        }
        else if (AccountData?.PaymentMethod?.PaymentMethodID && AccountData?.Address && Bid > 0 && ShoePriceData?.LowestBuyNowPrice == '-') {
            SetBidValid(true)
        }



        else if (parseInt(Bid) >= parseInt(ShoePriceData?.LowestBuyNowPrice)) {
            alert('buy now')
            SetBidValid(false)
        }
        else {

            SetBidValid(false)

        }
    }, [AccountData, Bid])


    useEffect(() => {




        FetchSneakerBySKU(ProductSKU).then(result => {
            if (result.error) {
                navigate('/not-found')
            }
            // if (result.relatedproducts.length > 0) {
            //     SetRelatedproducts(result.relatedproducts)
            // }



            if (result.sneakers.length > 0) {


                // result[0].image = String(result[0].image).replace('-1-' , '1000')
                result.sneakers[0].image = String(result.sneakers[0].image).replace('400.png', '1000.png')
                FetchSizesBasedOnBrandId(result.sneakers[0]).then(Sizes => {
                    // 
                    // ShoeData.Sizes = Object.keys(Sizes)

                    FetchListingsBySKU(ProductSKU).then(Listings => {
                        // var temp_ShoeData = ShoeData

                        var SizesWithListings = Listings.Listings

                        var AllSizes = Sizes


                        let new_array = []
                        let SizeFound = AllSizes.find((element) => {
                            element = element.replace(".5", ' ½').replace(' 1/3', ' ⅓').replace(' 2/3', ' ⅔')
                            if (element == ProductSize) {
                                return true
                            }

                        })
                        if (!SizeFound) {
                            navigate('/not-found')
                        }
                        AllSizes.forEach(element => {


                            element = element.replace(".5", ' ½').replace(' 1/3', ' ⅓').replace(' 2/3', ' ⅔')
                            // 



                            if (SizesWithListings[element] != undefined) {

                                new_array.push({
                                    Size: SizesWithListings[element].Size,
                                    Price: SizesWithListings[element].Price,
                                    Id: SizesWithListings[element]._id
                                })
                            }
                            else {

                                new_array.push({
                                    Size: element,
                                    Price: "OOS",

                                })
                            }
                        });

                        // temp_ShoeData.Sizes = new_array


                        SetShoeData({ ...result.sneakers[0], Sizes: new_array })
                        // SetFetchingData(false)
                        // 

                        // SizesWithListings.forEach((Size) =>{
                        //     var FormattedSize = Size
                        //     var FormattedSize =  FormattedSize.replace(' ½' , '.5')
                        //     FormattedSize = FormattedSize.replace(' ½' , '.5')
                        //     FormattedSize = FormattedSize.replace(' ½' , '.5')


                        //     // 
                        //     // 
                        //     const IndexOfSize = temp_ShoeData.Sizes.indexOf(FormattedSize)
                        //     // 
                        //     
                        //     if(IndexOfSize != -1){
                        //         temp_ShoeData.Sizes[IndexOfSize] = {
                        //             Size:Size,
                        //             LowestPrice:result.Listings[Size].Price,
                        //             ID:result.Listings[Size]._id
                        //         }
                        //     }
                        //     else{
                        //         temp_ShoeData.Sizes[IndexOfSize] = {
                        //             Size:Size,
                        //             LowestPrice:"OOS"
                        //         }
                        //     }

                        //     // 



                        //     // temp_ShoeData.Sizes[IndexOfSize] = result.Listings[Size]

                        // })

                        // 



                    })




                })

            }


        })



    }, [ProductSKU]);


    useEffect(() => {


        FetchProductDetailsBySKU(ProductSKU).then(response => {
            const { Success, ProductDetails } = response

            if (!Success) {
                navigate('/not-found')
                return
            }


            const HighestBids = ProductDetails.HighestBids
            const LowestBuyNowPrices = ProductDetails.LowestPrices

            const HighestBid = HighestBids[ProductSize]?.BidAmount || '-'
            const LowestBuyNowPrice = LowestBuyNowPrices[ProductSize]?.Price || '-'
            SetShoePriceData({
                HighestBid, LowestBuyNowPrice
            })

        })

    }, [ProductSKU])

    // 


    const OnBidChange = (e) => {
        const BidValue = e.target.value

        if (parseInt(BidValue)) {
            SetBid(BidValue)
        }
        else {
            SetBid(0)
        }

    }


    const AddressItem = () => {
        return (
            <Link to={'/account/profile'} className="border p-3 py-3 flex gap-3  rounded items-center justify-between">
                <div className="flex flex-col ">
                    <span className="text-sm opacity-70 ">
                        {AccountData?.Address?.fname} {AccountData?.Address?.lname}
                    </span>
                    <span className="text-sm opacity-70 ">
                        {AccountData?.Address?.address}
                    </span>
                    <span className="text-sm opacity-70 ">
                        {AccountData?.Address?.city} ,  {AccountData?.Address?.zip}
                    </span>
                    <span className="text-sm opacity-70 ">
                        {countries[AccountData?.Address?.country]?.name}
                    </span>

                </div>
                <FaEdit className="text-base" />
            </Link>
        )
    }

    const PaymentItem = () => {
        return (
            <>
                <div onClick={() => {
                }} className="w-full cursor-pointer p-3 text-xs py-2 max-h-[60px] items-center  gap-5 input-field border rounded-md flex">
                    {(() => {
                        switch (AccountData?.PaymentMethod?.PaymentMethod?.card.brand) {
                            case "visa":
                                return (
                                    <FaCcVisa size={23} />
                                )
                                break;
                            case "mastercard":
                                return (
                                    <FaCcMastercard size={23} />
                                )
                                break;

                            default:
                                break;
                        }

                    })()}

                    <span>
                        •••• {AccountData?.PaymentMethod?.PaymentMethod?.card.last4}
                    </span>
                    <span>
                        {String(AccountData?.PaymentMethod?.PaymentMethod?.card.exp_month).length == 1 ? "0" + AccountData?.PaymentMethod?.PaymentMethod?.card.exp_month : AccountData?.PaymentMethod?.PaymentMethod?.card.exp_month} / {AccountData?.PaymentMethod?.PaymentMethod?.card.exp_year}
                    </span>
                    <FaEdit size={13} className='cursor-pointer ml-auto' />
                </div>
            </>
        )
    }

    const HandleOnPlaceBid = async (e) => {
        const BidBody = {
            ...AccountData, BidAmount: Bid, OrderAmount: FeesAndTotalBid.FormattedPrice, FeesAndTotalBid, ShoeData, Size: ProductSize
        }
        const BidCreated = await CreateBid(BidBody)
        if (BidCreated.BidAmount) {
            return BidCreated
        }
        else {
            return false
        }

    }


    return (
        <div className="h-full w-full md:flex-row flex   flex-col pt-10 px-10 justify-center gap-4">
            {/* {ProductSKU} */}
            <div className="flex flex-col gap-3 border p-4 w-full md:w-1/3  ">
                <div className="">
                    <div class="border flex flex-col p-3   w-full  flex-1  ">
                        <div id="" class="w-[140px] h-[75px] m-auto">
                            <img class=" object-contain " src={`http://localhost:7584/image/product/${ProductSKU}`} />
                        </div>
                        <div class="text-left flex flex-col w-full mt-8 ">
                            <span class="text-sm overflow text-ellipsis font-light line-clamp-2">{ShoeData?.name}</span>
                        </div>
                        <div class="mt-3 text-left flex justify-between">
                            <div class="flex flex-col">
                                <span class="text-sm overflow text-ellipsis font-light opacity-60 line-clamp-2">Maat</span>
                                <span class="text-sm">EU {ProductSize}</span>
                            </div>


                        </div>
                    </div>
                </div>


                <div class="flex w-full justify-center items-center  border">
                    <div class=" w-full flex-col flex  p-3 py-2 text-center">
                        <span class="opacity-60  text-sm font-light">Hoogtse Bod</span>
                        <span class="text-[14px] font-medium">€ {
                            isNaN(parseInt(ShoePriceData?.HighestBid)) ? '-' : parseInt(ShoePriceData?.HighestBid).toFixed(2)
                        }</span>
                    </div>
                    <div class="w-[3px] opacity-40 rounded-3xl bg-gray-400 h-8" />
                    <div class=" w-full flex-col flex  p-3 py-2 text-center">
                        <span class="opacity-60  text-sm font-light">Koop nu Prijs</span>
                        <span class="text-[14px] font-medium">€{
                            isNaN(parseInt(ShoePriceData?.LowestBuyNowPrice)) ? '-' : parseInt(ShoePriceData?.LowestBuyNowPrice).toFixed(2)
                        }
                        </span>
                    </div>


                </div>
                <div className=" relative">
                    <div className="flex flex-col gap-2 ">
                        <input type={'number'} onChange={(e) => {
                            OnBidChange(e)
                        }} className="p-2 text-sm  w-full rounded py-3  border" placeholder="Jouw bod (€)" />
                        <span className={`text-xs opacity-60 flex w-fit items-center ${Bid == 0 ? ' text-red-500  opacity-100' : null} `}>
                            {Bid != 0 ? " Je bent de hoogste Bod!" : "Vul een bod in *"}
                        </span>
                    </div>
                </div>

            </div>

            <div className=" p-3 flex-col flex border rounded w-full mt-5 md:mt-0 md:w-1/2 ">

                {/* {Cart?.items.map((item) => {
                    
                    return (
                        <CartItem id={item._id} Size={item.Size} ProductSKU={item.ProductSKU} ProductName={item.ProductName} />
                    )
                })} */}
                {/* {Cart.items?.map((CartItem) =>{
                
                return(
                    <CartItem />
                )
               })} */}


                <div class=" pb-0 flex-col  pt-0 py-5  w-full px-5 mx-auto mt-3 gap-6 flex items-center h-full ">

                    <span>
                        Overzicht
                    </span>
                    <div className="flex flex-col gap-4 w-full h-full">
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Bod
                            </span>
                            <span className="text-sm">
                                € {parseInt(Bid).toFixed(2)}
                            </span>
                        </div>
                        {Bid != NaN && Bid != 0 ?
                            <>
                                <div className="flex justify-between w-full">
                                    <span className="text-sm">
                                        Verwerkingskosten
                                    </span>
                                    <span className="text-sm">
                                        € {FeesAndTotalBid?.ProcessingFee.toFixed(2)}
                                    </span>
                                </div>
                                <div className="flex justify-between w-full">
                                    <span className="text-sm">
                                        Verzendkosten
                                    </span>
                                    <span className="text-sm">
                                        € {FeesAndTotalBid?.Shipping.toFixed(2)}
                                    </span>
                                </div>
                            </> : null}
                        <div className="flex  border-t pt-5 justify-between w-full pb-5">
                            <span className="text-sm font-medium">
                                Totaal
                            </span>
                            <span className="text-sm">
                                €{Bid > 0 ? FeesAndTotalBid?.FormattedPrice.toFixed(2) : parseInt(0).toFixed(2)}
                            </span>
                        </div>
                        <div className=" flex flex-col gap-2">
                            <span>
                                Adresgegevens
                            </span>
                            <AddressItem />
                        </div>
                        <div className=" flex flex-col gap-2">
                            <span>
                                Betaalgegevens
                            </span>
                            <PaymentItem />

                        </div>

                    </div>

                    <button onClick={async (e) => {
                        //set loadig
                        SetPlacingBid(true)
                        const BidPlaced = await HandleOnPlaceBid(e)
                        
                        if(BidPlaced){
                            navigate(`/account/bid/${BidPlaced._id}`)
                        }   
                        else{
                            //popup with error
                        }
                        //handle success or failure
                        SetPlacingBid(false)
                        

                    }} disabled={!BidValid} className={` ${!BidValid ? 'bg-[#777777] border-[#777777]  cursor-not-allowed' : null} relative leading-4 text-sm bg-[#0052ff]  font-medium  m-0 rounded text-white px-3 py-2.5 border border-[#0052ff] w-full `}>
                        {PlacingBid ? <ReactLoading type="spin" className="mx-auto" height={16} width={16} /> : "Plaats bod"}

                    </button>
                </div>

            </div>


        </div>
    )
}


export default ProductBids