import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { RiArrowRightUpLine, RiArrowLeftDownLine } from 'react-icons/ri'
import { BsArrowDownLeft, BsTrash } from 'react-icons/bs'
// import './index.css'
import ReactLoading from 'react-loading';

import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
    FilterFns,
    Pagination,
} from '@tanstack/react-table'

import { useVirtual } from '@tanstack/react-virtual';

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { fetchData } from './makedata'
import DeleteListingByID from '../../services/DeleteListingByID'
import { Link } from 'react-router-dom'






function ListingsTable() {


    const [CurrentSelectedID, SetCurrentSelectedID] = useState(null)

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {

            // 
            return num >= item.value;
        });

        // let PRICE = new Intl.NumberFormat('en-US', {style: 'decimal'}).format(String(parseFloat(num / item.value)).replace(rx, "$1"));
        // 
        if (item) {
            var value = parseFloat(num).toLocaleString(
                undefined,

                { minimumFractionDigits: 2 }
            );

        }



        return item ? value : String(parseFloat(num).toFixed(2));
    }

    function BigNFormatter(num, digits, Currency) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });



        return item ? `${Currency ? "€" : ''}${(num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol}` : "0";
    }

    function changeFormatter(num) {
        return `${parseFloat(num * 100).toFixed(2)}`

    }

    const BigIntFormatter = (IntFromTableData, Currency) => {


        // var FloatNumber = parseFloat(IntFromTableData)

        // let formatter = Intl.NumberFormat('en', { notation: 'compact'});
        // // // example 1
        // // let million = formatter.format(412831004581.63);
        // // // example 2
        // let int = formatter.format(IntFromTableData);
        // // print
        // 
        // return int
        // let fomrattedint = nFormatter(int, 2)
        // 
        return BigNFormatter(IntFromTableData, 1, Currency)
    }
    const rerender = React.useReducer(() => ({}), {})[1]

    const [sorting, setSorting] = React.useState([])

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'ShoeData',
                // header: 'Name',
                // cell: info =>{
                //     
                //     return `€${info.getValue()}`
                // },
                cell: ({ row }) => {

                    // 
                    return (<div className='flex items-center space-x-3'>
                        <img className=' h-[48px]' src={`http://localhost:7584/image/product/${row.original.ShoeData?.sku}`} />
                        <div className='flex flex-col'>
                            <Link to={`/product/${row.original.ShoeData?.sku}`} className='hover:underline text-sm'>
                                {row.original.ShoeData?.name}
                            </Link>
                            <div className='flex gap-3 mt-2'>
                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                    EU {row.original.Size}
                                </span>
                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                    {row.original.ShoeData?.sku}
                                </span>
                            </div>
                        </div>

                    </div>)
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Item</div>),
                size: 550

            },
            {
                accessorKey: 'Price',
                cell: ({ row }) => {
                    return (
                        <div className='flex flex-col'>
                            <span className={` ${row.original.LowestPrice ? 'text-green-600' : 'text-red-600'} text-sm`}>
                                {`€${nFormatter(row.original.Price, 3)}`}
                            </span>
                            {/* <span className=' text-xs mt-2'>
                            Je bent niet de goedkoopste
                        </span> */}
                        </div>
                    )
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Prijs</div>),
                size: 130

            },
            {
                accessorKey: 'Price',
                cell: ({ row }) => {
                    return (
                        <div className='flex flex-col'>
                            <span className={` ${row.original.LowestPrice ? 'text-green-600' : 'text-red-600'} text-sm`}>
                                {`€${nFormatter(row.original.LowestPriceInt, 3)}`}
                            </span>
                            {/* <span className=' text-xs mt-2'>
                            Je bent niet de goedkoopste
                        </span> */}
                        </div>
                    )
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Laagste Prijs</div>),
                size: 130

            },
            {
                accessorKey: 'Change24H',
                cell: info => {
                    return (
                        <div className=''>
                            <span className='text-sm'>
                                Verloopt op 22/04/2022
                            </span>
                        </div>
                    )
                },
                header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Status</div>),
                size: 160
            },

            {
                header: () => (
                    <div
                        style={{

                            textAlign: "left"
                        }}
                    >Status</div>),
                size: 150,
                enableSorting: false,
                accessorKey: 'createdAt',
                header: '',
                cell: ({ row }) => {
                    return (
                        <div className=' flex gap-3 justify-end mr-5 '>
                            <Link to={`/account/listing/created/${row.original._id}`} onClick={() => {

                            }} className='px-3 py-2 border rounded text-sm'>
                                Bewerk
                            </Link>
                            <button onClick={() => {

                                SetCurrentSelectedID(row.original)
                            }} className='px-3 py-2 border rounded text-sm cursor-pointer'>
                                <BsTrash />
                            </button>

                        </div>
                    )
                },

            },
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] =
        useState({
            pageIndex: 0,
            pageSize: 15,
        })

    const fetchDataOptions = {
        pageIndex,
        pageSize,

    }
    // const [globalFilter, setGlobalFilter] = React.useState('')

    const dataQuery = useQuery(
        ['data', fetchDataOptions],
        () => fetchData(fetchDataOptions),
        { keepPreviousData: true }
    )
    const defaultData = useMemo(() => [], [])

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,

        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        // data,
        columns,
        pageCount: dataQuery.data?.pageCount ?? -1,
        data: dataQuery.data?.rows ?? defaultData,

        state: {
            sorting,
            pagination,

        },
        onPaginationChange: setPagination,
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // onColumnFiltersChange: setColumnFilters,

        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    const tableContainerRef = React.useRef(null)

    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: 10,
    })
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0


    const [IsLoading, SetIsLoading] = useState(true)

    useEffect(() => {
        if (dataQuery.data?.rows) {
            setTimeout(() => {
                SetIsLoading(false)
            }, 200);
        }
    }, [dataQuery])

    return (
        <div className="p-2 h-full w-full">
            {IsLoading ?
                <div className='flex  h-full'>
                    <ReactLoading type="spin" color='#0052ff' className=" mx-auto flex my-auto z-[1000000]" height={55} width={55} />


                </div>
                : <>
                    <input onChange={(e) => {
                        // setPagination({
                        //     ...Pagination, SearchQuery: e.target.value
                        // })
                    }} className='p-2 text-sm  w-full rounded py-3 mb-5 border' placeholder='Zoeken in Biedingen' />

                    <div className="h-2" />
                    <div className='!w-full'>
                        <div ref={tableContainerRef} className=" !w-full">
                            {dataQuery.data?.rows.length > 0 ?
                                <>
                                    <div className=' flex-col !w-full xl:hidden md:hidden lg:hidden flex gap-3 mt-1'>
                                        {dataQuery.data?.rows.map((data, i) => {

                                            return (
                                                <div className='flex flex-col border p-4 gap-3 cursor-pointer'>
                                                    <div className='flex row '>
                                                        <div className='flex items-center space-x-3'>
                                                            <img className=' h-[48px]' src={`http://localhost:7584/image/product/${data.ShoeData?.sku}`} />
                                                            <div className='flex flex-col'>
                                                                <div className=' text-sm w-4/5'>
                                                                    {data.ShoeData?.name}
                                                                </div>
                                                                {/* <div className='flex gap-3 mt-2'>
                                                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                                                    EU  {data.Size}
                                                                </span>
                                                                <span className='text-black rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                                                    {data.ShoeData?.sku}
                                                                </span>
                                                            </div> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='flex  justify-center items-center  border'>
                                                        <div className=' w-full flex-col flex  p-3 py-2 text-center'>
                                                            <span className='opacity-60  text-sm font-light'>
                                                                Maat
                                                            </span>
                                                            <span className='text-[14px] font-medium'>
                                                                EU 38

                                                            </span>
                                                        </div>

                                                        <div className='w-[3px] opacity-40 rounded-3xl bg-gray-400 h-8' />

                                                        <div className=' w-full flex-col flex  p-3 py-2 text-center'>
                                                            <span className='opacity-60  text-sm font-light'>
                                                                Prijs
                                                            </span>
                                                            <span className={` ${data.LowestPrice ? 'text-green-600' : 'text-red-600'} text-[14px] font-medium`}>
                                                                {`€${nFormatter(data.Price, 3)}`}
                                                            </span>


                                                        </div>
                                                        <div className='w-[3px] opacity-40 rounded-3xl bg-gray-400 h-8' />

                                                        <div className=' w-full flex-col flex  p-3 py-2 text-center'>
                                                            <span className='opacity-60  text-sm font-light'>
                                                                Laagste Prijs
                                                            </span>
                                                            <span className={` ${data.LowestPrice ? 'text-green-600' : 'text-red-600'} text-[14px] font-medium`}>
                                                                {`€${nFormatter(data.LowestPriceInt, 3)}`}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>

                                            )
                                        })}

                                    </div>

                                    <table className='xl:table md:table lg:table hidden !w-full !h-full'>
                                        <thead className=''>
                                            {table.getHeaderGroups().map(headerGroup => (
                                                <tr key={headerGroup.id}>
                                                    {headerGroup.headers.map(header => {
                                                        return (
                                                            <th
                                                                key={header.id}
                                                                colSpan={header.colSpan}
                                                                style={{ width: header.getSize() }}
                                                                className="text-xs pb-2"
                                                            >
                                                                {header.isPlaceholder ? null : (
                                                                    <div

                                                                        {...{
                                                                            className: header.column.getCanSort()
                                                                                ? 'cursor-pointer select-none flex space-x-3'
                                                                                : '',
                                                                            onClick: header.column.getToggleSortingHandler(),
                                                                        }}
                                                                    >
                                                                        {flexRender(
                                                                            header.column.columnDef.header,
                                                                            header.getContext()
                                                                        )}
                                                                        {{
                                                                            asc: <span>
                                                                                🔼
                                                                            </span>,
                                                                            desc: <span>
                                                                                🔽
                                                                            </span>,
                                                                        }[header.column.getIsSorted()] ?? null}
                                                                    </div>
                                                                )}
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody className='!w-full '>
                                            {paddingTop > 0 && (
                                                <tr>
                                                    <td style={{ height: `${paddingTop}px` }} />
                                                </tr>
                                            )}
                                            {virtualRows.map(virtualRow => {
                                                const row = rows[virtualRow.index]
                                                return (
                                                    <tr className='hover:bg-[#fafafa] border-t border-b !w-full !min-h-[200px] h-[100px] cursor-pointer' onClick={() => {

                                                    }} key={row.id}>
                                                        {row.getVisibleCells().map(cell => {
                                                            return (
                                                                <td className='' key={cell.id}>
                                                                    {flexRender(
                                                                        cell.column.columnDef.cell,
                                                                        cell.getContext()
                                                                    )}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                            {paddingBottom > 0 && (
                                                <tr>
                                                    <td style={{ height: `${paddingBottom}px` }} />
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                                :
                                <div className="flex  flex-col justify-center mx-auto w-full h-full    text-center flex-1 gap-3">
                                    <span className='text-xl w-1/3 mx-auto'>
                                        Je hebt nog geen actieve advertenties!
                                    </span>
                                    <Link to="/account/sell" className=' mx-auto w-1/4 px-3 py-2 border rounded text-sm cursor-pointer'>
                                        Advertentie maken
                                    </Link>
                                </div>

                            }

                            <div className="h-2" />
                            <div className="flex items-center gap-2 pb-5 mt-5">

                                {Array.from(Array(table.getPageCount()).keys()).map((item) => {
                                    console.log(item + 1)
                                    const PageVisual = item + 1
                                    const PageIndex = item
                                    const CurrentIndex = table.getState().pagination.pageIndex
                                    return (
                                        <button className={`${CurrentIndex == PageIndex ? 'bg-[#0052ff] text-white cursor-default ' : 'h    :border'} text-base font-normal w-8 h-8 rounded-full`} onClick={() => {
                                            table.setPageIndex(PageIndex)

                                        }}>
                                            {PageVisual}
                                        </button>
                                    )
                                })}


                            </div>
                            {/* <div>{table.getRowModel().rows.length} Rows</div>
                            <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}



                        </div>
                    </div>


                </>}

            {/* <div className="flex items-center gap-2">
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<<'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </button>
                
                <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    {'>>'}
                </button>
                <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </strong>
                </span>
                <span className="flex items-center gap-1">
                    | Go to page:
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                        className="border p-1 rounded w-16"
                    />
                </span>
                {/* <select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select> */}
            {/* {dataQuery.isFetching ? 'Loading...' : null}
            </div> */}
            {CurrentSelectedID != null ? <div id='parent' onClick={(e) => {
                if (e.target.id == 'parent') {
                    SetCurrentSelectedID(null)
                }
            }} className='fixed w-full flex h-full top-0 bg-[#00000071] left-0'>
                <div className='flex flex-col m-auto h-fit w-fit p-4 bg-white rounded shadow-xl '>
                    <span className='text-center'>
                        Advertentie verwijderen
                    </span>
                    <div className='flex grow-0  mb-5 mt-5 w-full items-center justify-center gap-10'>
                        <div className=''>
                            <img className='h-[50px]' src={CurrentSelectedID.ShoeData.image} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='text-black h-fit rounded-sm bg-[#f4f3f1] p-1  text-xs'>
                                {CurrentSelectedID.ShoeData.sku}
                            </span>
                            <span className='text-black h-fit  w-fit rounded-sm bg-[#f4f3f1] p-1 text-xs'>
                                EU {CurrentSelectedID.Size}
                            </span>
                        </div>
                    </div>

                    <div className='flex gap-4'>

                        <button onClick={() => {
                            DeleteListingByID(CurrentSelectedID._id).then(resp => {
                                if (resp.deleted) {
                                    dataQuery.refetch()
                                    // rerender()
                                }
                            })
                            SetCurrentSelectedID(null)
                        }} className='px-3 py-2 border rounded text-sm'>
                            Verwijder Advertentie
                        </button>
                        <button onClick={() => {
                            SetCurrentSelectedID(null)

                        }} className='px-3 py-2 border rounded text-sm'>
                            Annuleren
                        </button>
                    </div>
                </div>
            </div> : null}
        </div>
    )
}




export default ListingsTable
