

const API_MAIN_URL = "http://localhost:8080/api"

async function ProductPricing(SKU){
    return new Promise((resolve , reject) =>{
        // 
        fetch(`${API_MAIN_URL}/pricing/${SKU}` , {
            method:"POST"
        }).then(resp => resp.json()).then((Pricing) =>{
            resolve(Pricing)
        }).catch((err) =>[
            resolve({
                error:true
            })
        ])
    })

}

export default ProductPricing