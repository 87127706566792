

import { GoSearch } from 'react-icons/go'
import { FaHamburger, FaRegUser } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { useGlobalState } from '../state/state'
import { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineCloseSquare, AiOutlineMenu } from 'react-icons/ai'


function NavBar() {

    // const [CartCreated, SetCartCreated] = useGlobalState('CartCreated');
    // 

    let location = useLocation();


    const [CurrentPath, SetCurrentPath] = useState('/')

    useEffect(() => {

        SetCurrentPath(location.pathname)
    }, [location])

    const [MobileMenu, SetMobileMenu] = useState(false)
    const [Width, SetWidth] = useState(0)

    return (
        <header className='sticky top-0 z-[1000000]  bg-white  w-full min-h-[67px] box-border overflow-hidden'>
            <div className="border-b border-[#ebeff1] top-0 w-full   flex flex-col" >
                <div className=' relative'>
                    <div className='max-w-[1180px] mx-auto flex flex-col px-5 box-content min-h-[66px] items-stretch  '>

                        <div className='flex items-center min-h-[66px]  '>
                            <div className='flex items-center  pb-[3px] flex-grow-2 flex-shrink basis-[0%]'>
                                <div className='md:hidden flex flex-col transition-all delay-300 ease-linear' >
                                    {MobileMenu ? <AiOutlineClose onClick={() => {
                                        SetMobileMenu(!MobileMenu)
                                    }} className='cursor-pointer' size={22} /> : <AiOutlineMenu onClick={() => {
                                        SetMobileMenu(!MobileMenu)
                                    }} className='cursor-pointer' size={22} />}
                                    <div className={` ${MobileMenu ? 'fixed ' : 'hidden '} h-[100%] p-4   w-[100%] delay-300 ease-linear  z-[2000000] bg-white top-[67px] left-0 `}>
                                        <div className='flex flex-col justify-center mt-5 gap-10'>
                                            <div className='flex mx-auto'>
                                                <Link onClick={() => {
                                                    SetMobileMenu(!MobileMenu)
                                                }} to={'/products/all'} className=' leading-4 inline-flex'>
                                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer  hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/all' ? 'border-[#0052ff] border-b-2' : null}`}>
                                                        <span className=' text-xl font-medium text-center leading-4'>
                                                            Alle Sneakers
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='flex mx-auto'>
                                                <Link onClick={() => {
                                                    SetMobileMenu(!MobileMenu)
                                                }} to={'/products/nike'} className=' leading-4 inline-flex'>
                                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/nike' ? 'border-[#0052ff] border-b-2' : null}  `}>

                                                        <span className=' text-xl font-medium text-center leading-4 '>
                                                            Nike
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='flex mx-auto'>

                                            </div>
                                            <div className='flex mx-auto'>

                                            </div>
                                            <div className='flex mx-auto'>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className=' w-full fixed h-screen bg-black left-0 top-0 z-[50000000] transition-all delay-300'>
                                    
                               </div> */}
                                </div>
                                <Link className='md:ml-0 ml-auto' to='/'>
                                    <img src="https://cdn.couponcodes.nl/img/merchants/121234/360-logo/v1/sneakers-nl-coupon-codes.png" height="" width="112" class="max-h-[40px] relative" />
                                </Link>
                            </div>
                            <nav className=' grow-6 flex-shrink basis-[0%] justify-center hidden md:flex'>
                                <Link to={'/products/all'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer  hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/all' ? 'border-[#0052ff] border-b-2' : null}`}>
                                        <span className=' text-sm font-medium text-center leading-4'>
                                            Alle Sneakers
                                        </span>
                                    </div>
                                </Link>
                                <Link to={'/products/nike'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/nike' ? 'border-[#0052ff] border-b-2' : null}  `}>

                                        <span className=' text-sm font-medium text-center leading-4 '>
                                            Nike
                                        </span>
                                    </div>
                                </Link>
                                <Link to={'/products/yeezy'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/yeezy' ? 'border-[#0052ff] border-b-2' : null}`}>

                                        <span className=' text-sm font-medium text-center leading-4 '>
                                            Yeezy
                                        </span>
                                    </div>
                                </Link>
                                <Link to={'/products/new-balance'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/products/new-balance' ? 'border-[#0052ff] border-b-2' : null}`}>

                                        <span className=' text-sm font-medium text-center leading-4 '>
                                            New Balance
                                        </span>
                                    </div>
                                </Link>
                                <Link to={'/faq'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff] ${CurrentPath == '/faq' ? 'border-[#0052ff] border-b-2' : null}`}>

                                        <span className=' text-sm font-medium text-center leading-4 '>
                                            FAQ
                                        </span>
                                    </div>
                                </Link>
                                <Link to={'/faq'} className=' leading-4 inline-flex'>
                                    <div className={` transition-all delay-200 ease-linear mx-4 inline-flex items-center relative cursor-pointer hover:border-b-2 hover:border-[#0052ff]`}>

                                        <span className=' text-sm font-medium text-center leading-4 '>
                                            Klantenservice
                                        </span>
                                    </div>
                                </Link>

                            </nav>
                            <div className='flex py-4 flex-grow-2 flex-shrink basis-[0%] justify-end items-center'>
                                <Link to={'/account'}>
                                    <span className='leading-4  mr-5 '>

                                        <span className='text-black leading-4 hover:text-[#0052ff] text-sm font-medium text-center '>
                                            Account
                                        </span>
                                    </span>
                                </Link>


                                <span className=' cursor-pointer text-[#0052ff]'>
                                    <Link to={'/sell'} className=' relative leading-4 text-sm bg-[#0052ff]  font-medium w-auto m-0 rounded text-white px-3 py-2.5 border border-[#0052ff] '>
                                        Verkopen
                                    </Link>
                                </span>

                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <div className='md:hidden bg-[#f5f5f5] w-full p-2 px-10'>
                <input onChange={(e) => { }} className="border p-2 rounded-md text-sm w-full" placeholder="Zoeken..." />

            </div>
        </header>

    )
}


export default NavBar