import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { GoPackage } from 'react-icons/go'
import { AiOutlineDollar, AiOutlineIdcard, AiOutlinePoweroff, AiOutlineUser } from 'react-icons/ai'
import { BsCreditCard2Front } from 'react-icons/bs'
import { GrUnorderedList } from 'react-icons/gr'
import { BiDollarCircle, BiPowerOff } from "react-icons/bi";
import ListingsTable from "../components/Listings/ListingsTable";
import SalesComponent from "../components/Sales/SalesComponent";
import PurchasesComponent from "../components/Purchases/PurchasesComponent";
import ProfleComponent from "../components/Profile/Profile";
import BidsTable from "../components/Bids/BidsComponent";

function AccountPage() {
  const navigate = useNavigate();
  const { subpath } = useParams()  
  const [cookies, setCookie, removeCookie] = useCookies([]);
  // const { user: currentUser } = usesel((state) => state.auth);

  const [UserLoggedIn, SetUserLoggedIn] = useState({})
  const [AccountPath, SetAccountPath] = useState('listings')


  const MenuItems = [
    {
      path: "listings",
      name: "Mijn Advertenties",
      description: 'Bekijk of bewerk actieve advertenties'
    },
    {
      path: "bids",
      name: "Mijn Biedingen",
      description: 'Bekijk of bewerk actieve biedingen'
    },


    {
      path: "sales",
      name: "Mijn Verkopen",
      description: 'Bekijk verkochte advertenties of Download verzendlabels'
    },

    {
      path: "purchases",
      name: "Mijn Aankopen",
      description: 'Bekijk en track uw bestellingen'
    },

    {
      path: "profile",
      name: "Profiel",
      description: 'Bekijk en Bewerk uw profiel'
    },

    {
      path: "logout",
      name: "Uitloggen",
    },

  ]

  
  useEffect(() => {
    // 
    if (subpath == undefined) {
      navigate('listings')
    }

  }, [subpath])

  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/login");
      } else {
        const { data } = await axios.post(
          "http://localhost:8080/api/",
          {},
          {
            withCredentials: true,
          }
        );
        if (!data.status) {
          removeCookie("jwt");
          navigate("/login");
        } else {
          SetUserLoggedIn(data)

        }

      }
    };
    verifyUser();
  }, []);

  const logOut = () => {
    removeCookie("jwt");
    navigate("/login");
  };

  const MenuItem = ({ path, name, description }) => {
    let Icon;

    if (name == 'Mijn Advertenties') {
      Icon = <GoPackage size={25} />
    }

    if (name == 'Mijn Biedingen') {
      Icon = <GrUnorderedList size={25} />
    }


    if (name == 'Mijn Verkopen') {
      Icon = <AiOutlineDollar size={25} />
    }



    if (name == 'Mijn Aankopen') {
      Icon = <BsCreditCard2Front size={25} />
    }

    if (name == 'Profiel') {
      Icon = <AiOutlineIdcard size={25} />
    }

    if (name == 'Uitloggen') {
      Icon = <AiOutlinePoweroff size={25} />
    }

    
    return (
      <div onClick={() => {
        // SetAccountPath(path)
        if (path == 'logout') {
          alert('logout')

        }
        else {
          navigate(`/account/${path}`)
        }
      }} className={` ${subpath == path ? 'bg-white' : 'bg-transparent'} flex md:px-2 px-4 py-4 gap-4 min-w-fit items-center  hover:bg-[#cfcfcf]  md:min-h-[70px] cursor-pointer`}>
        <div className="md:ml-2 ml-0">
          {Icon}
        </div>
        <div className="flex flex-col">
          <span className="font-medium text-xs md:text-sm">
            {name}
          </span>
          <span className=" md:flex hidden text-xs opacity-60">
            {description}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className=" mt-1 flex md:flex-row flex-col grow-1 w-full" style={{ 'height': "calc(100vh - 71px)" }}>
      <div className="flex flex-row    overflow-auto md:flex-col md:h-full md:w-fit md:justify-start justify-between   py-0 bg-[#fafafa]">
        {MenuItems.map((item) => <MenuItem name={item.name} path={item.path} description={item.description} />)}
      </div>

      <div className="h-full overflow-auto p-4 w-full flex flex-col">
        {(() => {
          if (subpath == 'listings') {
            return (
              <ListingsTable />
            )
          }
          else if (subpath == 'bids') {
            return (
              <BidsTable />
            )
          }
          else if (subpath == 'sales') {
            return (
              <SalesComponent />
            )
          }
          else if (subpath == 'purchases') {
            return (
              <PurchasesComponent />
            )
          }
          else if (subpath == 'profile') {
            return (
              <ProfleComponent />
            )
          }
        })()}

      </div>
      
    </div>


  );
}

export default AccountPage