export const API_URL = "http://localhost:8080/api/";

export const FetchPricing = async (price) => {
    return new Promise((resolve, reject) => {

        fetch(`${API_URL}/pricing`, {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                price:parseInt(price)
            })
        }).then(resp => resp.json()).then((response) => {
            // 
            resolve(response)
        })
    })


};