import { useEffect, useState } from "react"
import { AiFillAlert } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"
import { DeleteItemFromCart, RetrieveCart } from "../services/CartService"
const API_IMAGE_URL = 'http://localhost:7584'
function CartPage() {
    const navigate = useNavigate()
    const [Cart, SetCart] = useState(null)

    useEffect(() => {
        RetrieveCart().then(response => {
            
            if (response.cart[0].items.length > 0) {

                SetCart(response.cart[0])
            }
            else {
                navigate('/')
            }
            //expected:
            // {
            //     "_id": "62ef1018aed200c4ca3ea7a7",
            //     "userId": "62ec52c027e061ea535a36cf",
            //     "items": [
            //         {
            //            s
            //     ],
            //     "createdAt": "2022-08-07T01:06:32.694Z",
            //     "updatedAt": "2022-08-07T01:12:59.047Z",
            //     "__v": 5
            // }

        })
    }, [])

    const CartItem = ({ Size, ProductSKU, ProductName, id }) => {
        return (
            <div className="">
                <div class=" border-t border-b  py-5   w-fit px-5 mx-auto mt-5 gap-6 flex items-center ">
                    <div class="">
                        <div class="flex items-center space-x-3">
                            <img class=" h-[48px]" src={`${API_IMAGE_URL}/image/product/${ProductSKU}`} />
                            <div class="flex flex-col">
                                <span class="">{ProductName}</span>
                                <div class="flex gap-3 mt-2">
                                    <span class="text-black rounded-sm bg-[#f4f3f1] p-1 text-xs">EU {Size}</span>
                                    <span class="text-black rounded-sm bg-[#f4f3f1] p-1 text-xs">{ProductSKU}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="">
                    <div class="flex flex-col">
                        <span class=" text-sm">€322.00</span>
                    </div>
                </div> */}

                    <div class="">
                        <div class=" flex gap-3 ">
                            <button onClick={() => {
                                HandleRemoveItem(id)
                            }} class="px-3 py-2 border rounded text-sm ">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z">
                                    </path>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const HandleRemoveItem = (ItemID) => {
        DeleteItemFromCart(ItemID).then(response => {
            
            if (response == 204) {
                RetrieveCart().then(response => {
                    
                    if (response.cart[0].items.length > 0) {

                        SetCart(response.cart[0])
                    }
                    else {
                        navigate('/')
                    }
                    //expected:
                    // {
                    //     "_id": "62ef1018aed200c4ca3ea7a7",
                    //     "userId": "62ec52c027e061ea535a36cf",
                    //     "items": [
                    //         {
                    //            s
                    //     ],
                    //     "createdAt": "2022-08-07T01:06:32.694Z",
                    //     "updatedAt": "2022-08-07T01:12:59.047Z",
                    //     "__v": 5
                    // }

                })
            }
        })
    }

    return (
        <div className="flex flex-col w-full flex-shrink-0 mt-1 mb-5 p-4 px-10 text-center justify-center">
            <div className="flex-col flex">
                <span className="text-xl">
                    Winkelwagen
                </span>
                {Cart?.items.map((item) => {
                    
                    return (
                        <CartItem id={item._id} Size={item.Size} ProductSKU={item.ProductSKU} ProductName={item.ProductName} />
                    )
                })}
                {/* {Cart.items?.map((CartItem) =>{
                
                return(
                    <CartItem />
                )
               })} */}

                <div class=" border-b flex-col   py-5   min-w-[450px] px-5 mx-auto mt-3 gap-6 flex items-center ">

                    <span>
                        Wij Accepteren
                    </span>
                    <div className="flex gap-3">
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="//images.ctfassets.net/k4kk06v59kf0/6177FYImNGeYa2uIkWcO0q/dafb890147196202a418bf6cc5f8e3b6/maestro.svg" />
                        </div>
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="https://images.ctfassets.net/k4kk06v59kf0/6cp6aBUGIgqCsASkQeMsuc/571c8b783b3828aca365df95d2a7dbf2/klarna.svg" />
                        </div>
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="https://images.ctfassets.net/k4kk06v59kf0/4uX1chxJ6woaCYsyOoCISk/b59a2ff4a7cfb28202590baabe678770/paypalNarrow.svg" />
                        </div>
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="//images.ctfassets.net/k4kk06v59kf0/1lHhWhbEAgY6sKa4Km8wqI/4ef2dca4c2dd6dc594b4eb8a504dab76/visaNarrow.svg" />
                        </div>
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="//images.ctfassets.net/k4kk06v59kf0/1OaMv75Tv01kqKxgCOFfUx/d3426e8cf0c8053d53207062df1cb684/iDealLogo_new_icon.svg" />
                        </div>
                        <div className="border px-3 rounded-lg py-1">
                            <img className="h-8" src="//images.ctfassets.net/k4kk06v59kf0/3a3Arh48r6W0oUYsyQE2IC/f9b579bec1306f6e533f677677778b46/bancontactNarrow.svg" />
                        </div>


                    </div>
                </div>
                <div class=" border-b flex-col   py-5  min-w-[450px] px-5 mx-auto mt-3 gap-6 flex items-center ">

                    <span>
                        Overzicht
                    </span>
                    <div className="flex flex-col gap-4 w-full">
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Subtotaal
                            </span>
                            <span className="text-sm">
                                €{Cart?.items[0].StorePrice}
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Verwerkingskosten
                            </span>
                            <span className="text-sm">
                                €10
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-sm">
                                Verzendkosten
                            </span>
                            <span className="text-sm">
                                €7.50
                            </span>
                        </div>
                        <div className="flex  border-t pt-5 justify-between w-full">
                            <span className="text-sm font-medium">
                                Totaal
                            </span>
                            <span className="text-sm">
                                €{parseFloat(parseFloat(Cart?.items[0].StorePrice) + 17.5).toFixed(2)}
                            </span>
                        </div>


                    </div>

                </div>
                <div class=" flex-col   py-5  min-w-[450px]  mx-auto mt-5 gap-6 flex items-center ">

                    <Link to={'/account/checkout'} className=" relative bg-[#0052ff] leading-4 text-sm  font-medium  m-0 rounded text-white px-3 py-2.5 border w-full">
                        Naar bestellen
                    </Link>
                </div>
            </div>
        </div>

    )
}


export default CartPage