

const API_MAIN_URL = "http://localhost:8080/api"

async function FetchListing(query){
    return new Promise((resolve , reject) =>{
        
        fetch(`${API_MAIN_URL}/listing?search=${query}`).then(resp => resp.json()).then((FoundListing) =>{
            // 
            resolve(FoundListing)
        })
    })

}

export default FetchListing