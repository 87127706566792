import { useEffect, useState } from "react";



function SellerInfo() {
    const [Valid, SetValid] = useState(false)
    const [Iban, SetIban] = useState('')
    const [SellerFormValid, SetSellerFormValid] = useState(false)
    const [SellerForm, SetSellerForm] = useState({
        DateOfBirth: "",
        FullName: "",
        Iban: ""
    })

    const HandleOnChange = (e) => {
        const name = e.target.getAttribute('name')
        
        SetSellerForm({ ...SellerForm, [name]: e.target.value })

    }



    useEffect(() => {
        //fetch SellerInfo



    }, [])


    useEffect(() => {
        
        if (SellerForm.DateOfBirth != '' && SellerForm.FullName != '' && SellerForm.Iban != '') {
            SetSellerFormValid(true)
        }
        else {
            SetSellerFormValid(false)
        }

    }, [SellerForm])

    // useEffect(() =>{
    //     alert(SellerFormValid)
    // } , [SellerFormValid])

    return (
        <div className=" p-0  flex flex-col gap-6 pb-0">
            <span className='text-sm'>
                Verkoopgegevens
            </span>
            <div className=" gap-3 flex w-full">
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input type={'text'} name="Iban"
                        onChange={(e) => {
                            SetIban(e.target.value)



                            const Regex = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/gm;
                            const Regex2 = /^NL\d{2}[A-Z]{4}\d{10}$/

                            if (Regex2.test(e.target.value)) {
                                SetValid(true)
                                HandleOnChange(e)
                            }
                            else {
                                SetValid(false)
                                SetSellerFormValid(false)
                            }



                        }} className={`w-full ${Iban != '' && Valid == false ? 'border-red-500' : ""} p-3 text-xs  py-3 input-field border rounded-md`} placeholder="" required />
                    <span className=" input-label absolute pl-3  transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        IBAN

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input type={'text'} name="FullName"
                        onChange={(e) => {
                            HandleOnChange(e)
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" input-label absolute pl-3 transition-all delay-150   text-xs font-light pointer-events-none text-[#949494]">
                        Volledige Naam

                    </span>

                </div>
                <div className="flex h-[50px] w-1/4 rounded-lg flex-col relative justify-center ">
                    <input type={'date'} name="DateOfBirth"
                        onChange={(e) => {
                            
                            HandleOnChange(e)
                        }} className="w-full p-3 text-xs py-3 input-field border rounded-md" placeholder="" required />
                    <span className=" translate-y-[-190%] pl-[4px] absolute  transition-all delay-150   text-[13px] font-light pointer-events-none text-[#949494]">
                        Geboortedatum

                    </span>

                </div>

            </div>
            <button disabled={!SellerFormValid} className={`py-2 ${SellerFormValid != true ? 'opacity-70 cursor-not-allowed' : null} border rounded text-sm w-fit px-5 mb-0`}>
                Opslaan
            </button>
        </div>
    )
}

export default SellerInfo